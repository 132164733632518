import { Space, TableColumnsType, TablePaginationConfig, notification } from "antd";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";
import { saveAs } from "file-saver";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonConfirmModal from "../../components/Common/ConfirmModal";
import CommonTable from "../../components/Common/Table";
import CommonTag from "../../components/Common/Tag";
import vendorServices from "../../services/vendorManagement.service";
import {
  COMMON_PAGE_STATUS,
  DATE_TIME_FORMAT_SECOND,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  ROLE_LIST,
  SEV_CODE,
} from "../../utils/constans";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import { useQuery } from "../../utils/customHooks";
import { ICommonResponsePaging, ICommonSelectDataType, IUseQueryResponse } from "../../utils/types";
import { IVendorManagementDetailData } from "../../utils/types/vendorManagement";
import { buildQueryString, isHaveAnyPermission, isHavePermission } from "../../utils/utilFunctions";
import SearchBox from "./SearchBox";
import { useSelector } from "react-redux";

function VendorManagement() {
  const componentPath = SIDEBAR_ITEM_HREF.vendor_management;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const {
    page: pageQuery,
    pageSize: pageSizeQuery,
    sortBy: sortByQuery,
    sortType: sortTypeQuery,
    search: searchQuery,
  } = params;
  const page = pageQuery ? parseFloat(pageQuery) : DEFAULT_PAGE_NUMBER;
  const pageSize = pageSizeQuery ? parseFloat(pageSizeQuery) : DEFAULT_PAGE_SIZE;
  const [data, setData] = useState<ICommonResponsePaging<IVendorManagementDetailData>>();
  const [dataSelected, setDataSelected] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanCreate = isHavePermission(ROLE_LIST.MANAGER_VENDOR_ADD, profile);
  const isCanExport = isHavePermission(ROLE_LIST.MANAGER_VENDOR_EXCEL, profile);
  const isCanDelete = isHavePermission(ROLE_LIST.MANAGER_VENDOR_DELETE, profile);
  const isCanViewDetail = isHaveAnyPermission(
    [
      ROLE_LIST.MANAGER_VENDOR_VIEW_DETAIL_INFO,
      ROLE_LIST.MANAGER_VENDOR_VIEW_DETAIL_INFO_STAFF,
      ROLE_LIST.MANAGER_VENDOR_VIEW_DETAIL_LOCK_MANAGER,
      ROLE_LIST.MANAGER_VENDOR_EDIT,
    ],
    profile,
  );

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getData = async (reload?: boolean) => {
    setIsLoading(true);
    const paramsSearch = {
      page: reload ? 0 : page - 1,
      size: pageSize,
      sortBy: sortByQuery,
      sortType: sortTypeQuery,
      search: searchQuery,
    };
    const resp = await vendorServices.getListVendorManagement(paramsSearch);
    const data = resp?.data;
    if (resp?.status === 200) {
      setData(data?.data);
    } else {
      setData(undefined);
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  };

  const onPageChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
    extra: TableCurrentDataSource<IVendorManagementDetailData>,
  ) => {
    let queryString = buildQueryString({
      ...params,
      page: pageSize === pagination?.pageSize ? pagination?.current : DEFAULT_PAGE_NUMBER,
      pageSize: pagination?.pageSize,
      sortBy: sorter?.order ? sorter?.field : "",
      sortType: sorter?.order ? (sorter?.order === "ascend" ? "asc" : "desc") : "",
    });
    navigate(`${componentPath}${queryString || ""}`);
  };

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: number[], selectedRows: IVendorManagementDetailData[]) => {
      setDataSelected(selectedRowKeys);
    },
    getCheckboxProps: (record: IVendorManagementDetailData) => ({
      disabled: record?.vendorCode === SEV_CODE, // Vô hiệu hóa checkbox cho các dòng có hideCheckbox là true
    }),
  };

  const columns: TableColumnsType<IVendorManagementDetailData> = [
    {
      title: t("vendorManagementPage.columns.STT"),
      dataIndex: "index",
      key: "index",
      width: "5%",
      align: "center",
      render: (value: any, record: IVendorManagementDetailData, index: number) =>
        (page - 1) * pageSize + index + 1,
    },
    {
      title: t("vendorManagementPage.columns.taxCode"),
      dataIndex: "taxCode",
      key: "taxCode",
      sorter: true,
      render: (value: any, record: IVendorManagementDetailData, index: number) => {
        return isCanViewDetail ? (
          <Link to={`${componentPath}/detail/${record?.id}`}>
            <div className="link">{value || "--"}</div>
          </Link>
        ) : (
          value || "--"
        );
      },
    },
    {
      title: t("vendorManagementPage.columns.companyName"),
      dataIndex: "companyName",
      key: "companyName",
      sorter: true,
      render: (value: any, record: IVendorManagementDetailData, index: number) => {
        return value || "--";
      },
    },
    {
      title: t("vendorManagementPage.columns.productType"),
      dataIndex: "productType",
      key: "productType",
      sorter: true,
      render: (value: any, record: IVendorManagementDetailData, index: number) => {
        return value || "--";
      },
    },
    {
      title: t("vendorManagementPage.columns.address"),
      dataIndex: "address",
      key: "address",
      sorter: true,
      render: (value: any, record: IVendorManagementDetailData, index: number) => {
        return value || "--";
      },
    },
    {
      title: t("vendorManagementPage.columns.modifiedDate"),
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      sorter: true,
      render: (value: any, record: IVendorManagementDetailData, index: number) => {
        return value ? moment(value).format(DATE_TIME_FORMAT_SECOND) : "--";
      },
    },
    {
      title: t("vendorManagementPage.columns.modifiedBy"),
      dataIndex: "modifiedBy",
      key: "modifiedBy",
      sorter: true,
      render: (value: any, record: IVendorManagementDetailData, index: number) => {
        return value || "--";
      },
    },
    {
      title: t("vendorManagementPage.columns.status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (value: any, record: IVendorManagementDetailData, index: number) => {
        const curStatus = COMMON_PAGE_STATUS.find((x: ICommonSelectDataType) => x.value === value);
        return curStatus ? (
          <CommonTag tagType={curStatus?.type}>{t(curStatus?.label)}</CommonTag>
        ) : (
          "--"
        );
      },
    },
  ];

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    const resp = await vendorServices.deletesVendorManagement(dataSelected || []);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("vendorManagementPage.message.deleteSuccess"),
      });
      setDataSelected([]);
      setVisible(false);
      let queryString = buildQueryString({
        ...params,
        page: DEFAULT_PAGE_NUMBER,
        search: JSON.stringify({}),
      });
      if (queryString !== search) {
        navigate(`${queryString || ""}`);
      } else {
        getData(true);
      }
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoadingDelete(false);
  };

  const handleExport = async () => {
    if (!isLoadingExport) {
      setIsLoadingExport(true);
      const paramsSearch = {
        page: 0,
        size: 10000,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: searchQuery || JSON.stringify({}),
      };
      const resp = await vendorServices.exportVendorManagement(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        const fileName = `VendorManagement_Data_Export_${moment().format(
          "YYYYMMDD",
        )}_${moment().unix()}.xlsx`;
        saveAs(data, fileName);
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoadingExport(false);
    }
  };

  return (
    <div className="content-wrapper">
      <SearchBox getData={getData} componentPath={componentPath} />

      <div className="avic-table-top">
        <div className="avic-table-top-title">{t("vendorManagementPage.title")}</div>
        <Space className="avic-table-top-right">
          {dataSelected?.length > 0 && isCanDelete && (
            <CommonButton btnType="danger" size={"small"} onClick={() => setVisible(true)}>
              {t("common.button.deleteVendor")}
            </CommonButton>
          )}
          {isCanCreate && (
            <Link to={`${componentPath}/add`}>
              <CommonButton btnType="primary" size={"small"}>
                {t("common.button.addVendor")}
              </CommonButton>
            </Link>
          )}
          {isCanExport && (
            <CommonButton
              btnType="success"
              size={"small"}
              onClick={handleExport}
              loading={isLoadingExport}
            >
              {t("common.button.exportExcel")}
            </CommonButton>
          )}
        </Space>
      </div>

      <CommonTable
        isLoading={isLoading}
        rowKey={"id"}
        dataSource={data?.content || []}
        columns={columns}
        data={data}
        onChange={onPageChange}
        rowSelection={isCanDelete ? { ...rowSelection, selectedRowKeys: dataSelected } : null}
        defaultSorter={{
          order: sortTypeQuery,
          field: sortByQuery,
        }}
      />
      {visible ? (
        <CommonConfirmModal
          onCancel={() => setVisible(false)}
          content={
            <>
              <div style={{ textAlign: "center", marginBottom: "0.5rem" }}>
                {t("vendorManagementPage.message.delete")}
              </div>
              <div style={{ color: "#d83939" }}>
                <div>{t("vendorManagementPage.message.warning")}</div>
                <div>{t("vendorManagementPage.message.warningContent")}</div>
              </div>
            </>
          }
          visible={visible}
          onOk={handleDelete}
          loadingBtnOk={isLoadingDelete}
          title={t("vendorManagementPage.deleteTitle") as string}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default VendorManagement;
