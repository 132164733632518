import { Col, Form, Row, Space, TableColumnsType, TablePaginationConfig, notification } from "antd";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";
import { saveAs } from "file-saver";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonConfirmModal from "../../components/Common/ConfirmModal";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonInput from "../../components/Common/Input";
import CommonTable from "../../components/Common/Table";
import CommonTag from "../../components/Common/Tag";
import { ReactComponent as SearchIcon } from "../../resources/images/search-1.svg";
import vehicleTypeServices from "../../services/vehicleTypeCategory.service";
import {
  COMMON_PAGE_STATUS,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  ROLE_LIST,
} from "../../utils/constans";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import { useQuery } from "../../utils/customHooks";
import { ICommonResponsePaging, ICommonSelectDataType, IUseQueryResponse } from "../../utils/types";
import { IVehicleTypeDetailData } from "../../utils/types/vehicleType";
import { buildQueryString, isHavePermission } from "../../utils/utilFunctions";
import VehicleTypeAddModal from "./VehicleTypeAddModal";

function VehicleCategory() {
  const componentPath = SIDEBAR_ITEM_HREF.vehicle_category;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const {
    page: pageQuery,
    pageSize: pageSizeQuery,
    sortBy: sortByQuery,
    sortType: sortTypeQuery,
    search: searchQuery,
  } = params;
  const page = pageQuery ? parseFloat(pageQuery) : DEFAULT_PAGE_NUMBER;
  const pageSize = pageSizeQuery ? parseFloat(pageSizeQuery) : DEFAULT_PAGE_SIZE;
  const [data, setData] = useState<ICommonResponsePaging<IVehicleTypeDetailData>>();
  const [currentVehicleType, setCurrentVehicleType] = useState<IVehicleTypeDetailData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};
  const [form] = Form.useForm();
  const [dataSelected, setDataSelected] = useState<number[]>([]);

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanCreate = isHavePermission(ROLE_LIST.MANAGER_CAR_CREATE, profile);
  const isCanViewDetail = isHavePermission(ROLE_LIST.MANAGER_CAR_VIEW_DETAIL, profile);
  const isCanEdit = isHavePermission(ROLE_LIST.MANAGER_CAR_EDIT, profile);
  const isCanDelete = isHavePermission(ROLE_LIST.MANAGER_CAR_DELETE, profile);
  const isCanExport = isHavePermission(ROLE_LIST.MANAGER_CAR_EXPORT, profile);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getData = async (reload?: boolean) => {
    setIsLoading(true);
    const paramsSearch = {
      page: reload ? 0 : page - 1,
      size: pageSize,
      sortBy: sortByQuery,
      sortType: sortTypeQuery,
      search: searchQuery,
    };

    const resp = await vehicleTypeServices.getListVehicleType(paramsSearch);
    const data = resp?.data;
    if (resp?.status === 200) {
      setData(data?.data);
    } else {
      setData(undefined);
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  };

  const onPageChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
    extra: TableCurrentDataSource<IVehicleTypeDetailData>,
  ) => {
    let queryString = buildQueryString({
      ...params,
      page: pageSize === pagination?.pageSize ? pagination?.current : DEFAULT_PAGE_NUMBER,
      pageSize: pagination?.pageSize,
      sortBy: sorter?.order ? sorter?.field : "",
      sortType: sorter?.order ? (sorter?.order === "ascend" ? "asc" : "desc") : "",
    });
    navigate(`${componentPath}${queryString || ""}`);
  };

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: number[], selectedRows: IVehicleTypeDetailData[]) => {
      setDataSelected(selectedRowKeys);
    },
    getCheckboxProps: (record: IVehicleTypeDetailData) => ({
      disabled: record?.type === 2, // Vô hiệu hóa checkbox cho các dòng có hideCheckbox là true
    }),
  };

  const columns: TableColumnsType<IVehicleTypeDetailData> = [
    {
      title: t("vehicleCategoryPage.columns.STT"),
      dataIndex: "index",
      key: "index",
      width: "5%",
      align: "center",
      render: (value: any, record: IVehicleTypeDetailData, index: number) =>
        (page - 1) * pageSize + index + 1,
    },
    {
      title: t("vehicleCategoryPage.columns.vehicleType"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (value: any, record: IVehicleTypeDetailData, index: number) => {
        return isCanViewDetail || isCanEdit ? (
          <div
            className="link"
            onClick={() => {
              setCurrentVehicleType(record);
              setVisible(true);
            }}
          >
            {value || "--"}
          </div>
        ) : (
          <>{value || "--"}</>
        );
      },
    },
    {
      title: t("vehicleCategoryPage.columns.timeMoveInSEv"),
      dataIndex: "timeForAllow",
      key: "timeForAllow",
      width: "25%",
      render: (value: any, record: IVehicleTypeDetailData, index: number) => {
        return <>{value ? `${value} ${t("common.minute")}` : t("common.unlimited")}</>;
      },
      sorter: true,
    },
    {
      title: t("vehicleCategoryPage.columns.status"),
      dataIndex: "status",
      width: "25%",
      key: "status",
      sorter: true,
      render: (value: any, record: IVehicleTypeDetailData, index: number) => {
        const curStatus = COMMON_PAGE_STATUS.find((x: ICommonSelectDataType) => x.value === value);
        return curStatus ? (
          <CommonTag tagType={curStatus?.type}>{t(curStatus?.label)}</CommonTag>
        ) : (
          "--"
        );
      },
    },
  ];

  const onValuesChange = _.debounce((values: any) => {
    onSearch(values);
  }, 200);

  const onSearch = (data: any = {}) => {
    const dataSearch = { ...data };
    let queryString = buildQueryString({
      page: DEFAULT_PAGE_NUMBER,
      search: JSON.stringify(dataSearch),
    });
    setDataSelected([]);
    // detect if query not change => call api
    if (queryString !== search) {
      navigate(`${componentPath}${queryString}`);
    } else {
      getData();
    }
  };

  const handleOk = () => {
    handleCancel();
    onClear();
    let queryString = buildQueryString({
      ...params,
      page: DEFAULT_PAGE_NUMBER,
      search: JSON.stringify({}),
    });
    if (queryString !== search) {
      navigate(`${queryString || ""}`);
    } else {
      getData(true);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setVisibleDelete(false);
    setCurrentVehicleType(undefined);
    setDataSelected([]);
  };

  const onClear = () => {
    setTimeout(() => {
      form.resetFields();
    }, 100);
  };

  const handleDelete = async () => {
    setLoadingConfirm(true);
    const resp = await vehicleTypeServices.deleteVehicleType(dataSelected || []);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("vehicleCategoryPage.message.deleteSuccess"),
      });
      handleOk();
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setLoadingConfirm(false);
  };

  const handleExport = async () => {
    if (!isLoadingExcel) {
      setIsLoadingExcel(true);
      const paramsSearch = {
        page: 0,
        size: 10000,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: searchQuery || JSON.stringify({}),
      };
      const resp = await vehicleTypeServices.exportVehicleType(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        const fileName = `VehicleTypeCategory_Data_Export_${moment().format(
          "YYYYMMDD",
        )}_${moment().unix()}.xlsx`;
        saveAs(data, fileName);
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoadingExcel(false);
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="avic-table-top">
          <div className="avic-table-top-title">{t("vehicleCategoryPage.title")}</div>
        </div>
        <div className="avic-table-top">
          <Row className="pdr-20" style={{ flex: 1 }}>
            <Col span={24}>
              <CommonForm
                form={form}
                onValuesChange={onValuesChange}
                layout="horizontal"
                initialValues={{
                  name: searchQueryData?.name,
                }}
              >
                <CommonFormItem name="name" style={{ marginBottom: 0 }}>
                  <CommonInput
                    placeholder={t("vehicleCategoryPage.placeholder.advancedSearch") as string}
                    prefix={<SearchIcon className="prefix-icon" />}
                    allowClear
                  />
                </CommonFormItem>
              </CommonForm>
            </Col>
          </Row>
          <Space className="avic-table-top-right">
            {dataSelected?.length > 0 && isCanDelete && (
              <CommonButton
                btnType="danger"
                size={"small"}
                onClick={() => {
                  setVisibleDelete(true);
                }}
              >
                {t("common.button.remove")}
              </CommonButton>
            )}
            {isCanCreate && (
              <CommonButton btnType="primary" size={"small"} onClick={() => setVisible(true)}>
                {t("common.button.addNew")}
              </CommonButton>
            )}
            {isCanExport && (
              <CommonButton
                loading={isLoadingExcel}
                btnType="success"
                onClick={handleExport}
                size={"small"}
              >
                {t("common.button.exportExcel")}
              </CommonButton>
            )}
          </Space>
        </div>

        <CommonTable
          isLoading={isLoading}
          rowKey={"id"}
          dataSource={data?.content || []}
          columns={columns}
          data={data}
          onChange={onPageChange}
          rowSelection={isCanDelete ? { ...rowSelection, selectedRowKeys: dataSelected } : null}
          defaultSorter={{
            order: sortTypeQuery,
            field: sortByQuery,
          }}
        />
      </div>
      {visible ? (
        <VehicleTypeAddModal
          dataDetail={currentVehicleType}
          visible={visible}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      ) : (
        <></>
      )}

      {visibleDelete ? (
        <CommonConfirmModal
          onCancel={() => {
            setVisibleDelete(false);
          }}
          content={t("vehicleCategoryPage.message.delete")}
          visible={visibleDelete}
          onOk={handleDelete}
          loadingBtnOk={loadingConfirm}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default VehicleCategory;
