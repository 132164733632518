// table drop row
import { MenuOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
// table drop row

import { Col, notification, Row } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CommonButton from "../../../../components/Common/Button";
import CommonConfirmModal from "../../../../components/Common/ConfirmModal";
import CommonFormItem from "../../../../components/Common/FormItem";
import CommonSelect from "../../../../components/Common/Select";
import CommonTable from "../../../../components/Common/Table";
import { ReactComponent as DeleteIcon } from "../../../../resources/images/delete-red.svg";
import { ReactComponent as AddIcon } from "../../../../resources/images/plus-blue.svg";
import journeyServices from "../../../../services/journeyManagement.service";
import positionServices from "../../../../services/locationCategory.service";
import { DATE_TIME_FORMAT_SECOND, ROLE_LIST } from "../../../../utils/constans";
import { JOURNEY_STATUS_VALUES } from "../../../../utils/constans/journeyManagement";
import { LOCATION_VALUES } from "../../../../utils/constans/locationCategory";
import {
  ICompanyDeliverySites,
  ICompanyDeliverySitesAddData,
  IJourneyTabProps,
} from "../../../../utils/types/journeyManagement";
import { ILocationDetailData } from "../../../../utils/types/locationCategory";
import { isHavePermission } from "../../../../utils/utilFunctions";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

function InfoTab({ detailData, form, isEditing }: IJourneyTabProps) {
  const { t } = useTranslation();
  const [checkDuplicate, setCheckDuplicate] = useState<string[]>([]);
  const [positionSelectData, setPositionSelectData] = useState<ILocationDetailData[]>([]);
  const [itemDeleteId, setItemDeleteId] = useState<number | undefined>(undefined);
  const [typeVio, setTypeVio] = useState<number | undefined>(undefined);
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleUnlockConfirm, setVisibleUnlockConfirm] = useState<boolean>(false);
  const [loadingConfirmUnlock, setLoadingConfirmUnlock] = useState<boolean>(false);
  const [positionIdUnlock, setPositionIdUnlock] = useState<number | undefined>(undefined);
  const [lockIdUnlock, setLockIdUnlock] = useState<number | undefined>(undefined);
  const [dataSource, setDataSource] = useState<ICompanyDeliverySitesAddData[]>([]);
  const [rowsDisableSort, setRowsDisableSort] = useState<any[]>([]);
  const [isLoadingUnlock, setIsLoadingUnlock] = useState<boolean>(false);

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanEditDeli = isHavePermission(ROLE_LIST.QLHT_EDIT_POSITION, profile);

  const getData = async () => {
    const paramsSearch = {
      page: 0,
      size: 1000,
      search: JSON.stringify({}),
    };

    //select vị trí
    const respPosition = await positionServices.getPosition(paramsSearch);
    const dataPosition = respPosition?.data;
    if (respPosition?.status === 200) {
      setPositionSelectData(dataPosition?.data?.content);
    } else {
      setPositionSelectData([]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (detailData?.companyDeliverySites) {
      const newArr: any = [...detailData?.companyDeliverySites];
      const gateIn: number | undefined = newArr?.[0]?.categoryPosition?.id;
      const gateOut: number | undefined = newArr?.[newArr?.length - 1]?.categoryPosition?.id;
      newArr.pop(); //xóa cổng vào
      newArr.shift(); // xóa cổng ra
      // list những vị trí đã giao => không thể sort
      const rowsDisable: any = newArr?.filter((item: any) => item?.unlockTime || item?.unlockUser);
      setRowsDisableSort(rowsDisable);
      form.setFieldsValue({
        gateIn,
        gateOut,
        rowsDisableSort: rowsDisable,
      });
      setDataSource(
        newArr?.map((item: any) => ({
          key: (item?.orderNumber).toString(),
          index: item?.orderNumber,
          name: item?.categoryPosition?.id,
          unlockTime: item?.unlockTime,
          lockTime: item?.lockTime,
          disabled:
            item?.unlockTime ||
            item?.unlockUser ||
            item?.orderNumber <= rowsDisable?.slice(-1)?.[0]?.orderNumber
              ? true
              : false,
        })),
      );
    } else {
      setDataSource([]);
    }
  }, [detailData, form]);

  useEffect(() => {
    form.setFieldValue("companyDeliverySites", dataSource);
  }, [dataSource, form]);

  const RowTable = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: props["data-row-key"] });
    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === "sort") {
            return React.cloneElement(child as React.ReactElement, {
              // Dòng bị disable không hiện icon sort
              children:
                (child as any)?.props?.record?.disabled ||
                !isCanEditDeli ||
                !isEditing ||
                ![
                  JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
                  JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
                ].includes(detailData?.status) ? (
                  <></>
                ) : (
                  <MenuOutlined
                    ref={setActivatorNodeRef}
                    style={{ touchAction: "none", cursor: "move" }}
                    {...listeners}
                  />
                ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  // Thêm dòng
  const handleAddNewRow = () => {
    const newData: ICompanyDeliverySitesAddData = {
      key: (dataSource?.length + 1).toString(),
      index: dataSource?.length + 1,
      name: "",
      disabled: false,
    };
    setDataSource([...dataSource, newData]);
  };

  //Sort row
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      if (parseInt(over?.id as string) > (rowsDisableSort?.slice(-1)?.[0]?.orderNumber || 0)) {
        setDataSource((previous) => {
          const activeIndex = previous.findIndex((i) => i.key === active.id);
          const overIndex = previous.findIndex((i) => i.key === over?.id);
          return arrayMove(previous, activeIndex, overIndex);
        });
      } else {
        notification.error({
          message: t("journeyManagementPage.validate.enableSort"),
        });
      }
    }
  };

  const columns: ColumnsType<ICompanyDeliverySitesAddData> = [
    {
      key: "sort",
      width: "5%",
    },
    {
      title: t("journeyManagementPage.columns.ordernumber"),
      key: "index",
      align: "center",
      width: "10%",
      render: (value: any, record: ICompanyDeliverySitesAddData, index: number) => index + 1,
    },
    {
      title: t("journeyManagementPage.columns.name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (value: any, record: ICompanyDeliverySitesAddData, index: number) => {
        return (
          <div
            className={`row-table ${
              !value || checkDuplicate?.includes(record.key) ? "dangerous" : ""
            }`}
          >
            <CommonSelect
              style={{ width: "90%", textAlign: "left" }}
              disabled={
                record?.disabled ||
                !isCanEditDeli ||
                !isEditing ||
                ![
                  JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
                  JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
                ].includes(detailData?.status)
              }
              options={positionSelectData
                ?.filter((item: ILocationDetailData) => item.type === LOCATION_VALUES.WAREHOUSE)
                ?.map((item: ILocationDetailData) => ({
                  value: item.id,
                  label: item.name + " - " + item.address,
                }))}
              placeholder={t("journeyManagementPage.placeholder.name")}
              onChange={(values: any) => {
                setDataSource((prevState) => {
                  const newData = [...prevState];
                  newData[index].name = values;
                  return newData;
                });
              }}
              value={value || undefined}
              allowClear
              showSearch
            />
          </div>
        );
      },
    },
    {
      title: t("journeyManagementPage.columns.time"),
      key: "unlockTime",
      align: "center",
      render: (value: any, record: ICompanyDeliverySitesAddData, index: number) => {
        return (
          <div style={{ textAlign: "left" }}>
            <Row>
              <Col span={7}>
                <div style={{ fontSize: "0.833vw" }}>
                  {t("journeyManagementPage.columns.unlock")}:
                </div>
              </Col>
              <Col span={17}>
                <div style={{ fontSize: "0.833vw" }}>
                  {record?.unlockTime
                    ? moment(record?.unlockTime).format(DATE_TIME_FORMAT_SECOND)
                    : "--"}
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <div style={{ fontSize: "0.833vw" }}>
                  {t("journeyManagementPage.columns.closeLock")}:
                </div>
              </Col>
              <Col span={17}>
                <div style={{ fontSize: "0.833vw" }}>
                  {record?.lockTime
                    ? moment(record?.lockTime).format(DATE_TIME_FORMAT_SECOND)
                    : "--"}
                </div>
              </Col>
            </Row>
          </div>
        );
      },
    },
    {
      key: "action",
      align: "center",
      width: "10%",
      render: (cell: any, record: ICompanyDeliverySitesAddData, index: number) => {
        return record?.disabled ||
          !isCanEditDeli ||
          !isEditing ||
          ![
            JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
            JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
          ].includes(detailData?.status) ? (
          <></>
        ) : (
          <DeleteIcon
            className="delete-icon"
            onClick={() => {
              setVisible(true);
              setItemDeleteId(record.index);
            }}
          />
        );
      },
    },
  ];

  // check duplicate vị trí kho
  useEffect(() => {
    if (dataSource.length > 1) {
      const duplicateData = dataSource
        ?.filter((item: ICompanyDeliverySitesAddData, index: number) => {
          if (index === 0) {
            return item?.name && item?.name === dataSource[1]?.name;
          } else if (index === dataSource.length - 1) {
            return item?.name && item?.name === dataSource[dataSource.length - 2].name;
          } else {
            return (
              item?.name &&
              (item.name === dataSource[index + 1].name || item.name === dataSource[index - 1].name)
            );
          }
        })
        ?.map((item: ICompanyDeliverySitesAddData) => item.key);
      setCheckDuplicate(duplicateData);
      form.setFieldValue("checkDuplicate", duplicateData);
    } else {
      setCheckDuplicate([]);
      form.setFieldValue("checkDuplicate", []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, t]);

  const onDeleteRow = () => {
    setDataSource((prevData: ICompanyDeliverySitesAddData[]) => {
      const newDataSource = prevData.filter(
        (item: ICompanyDeliverySitesAddData) => item.index !== itemDeleteId,
      );
      return newDataSource;
    });
    setVisible(false);
  };

  // check để mở khóa
  const unlockBtnOnClick = async () => {
    setIsLoadingUnlock(true);
    if (!positionIdUnlock || !lockIdUnlock) {
      notification.error({
        message: t("journeyManagementPage.validate.unlockInfoRequired"),
      });
      setIsLoadingUnlock(false);
      return;
    }
    const arrCheck = detailData?.companyDeliverySites.slice(1, -1);
    const isNotInList = !arrCheck?.find(
      (item: Partial<ICompanyDeliverySites>) =>
        item?.categoryPosition?.id === positionIdUnlock && !item?.unlockTime && !item?.unlockUser,
    );
    const wrongOrder =
      arrCheck?.find(
        (item: Partial<ICompanyDeliverySites>) => !item?.unlockTime && !item?.unlockUser,
      )?.categoryPosition?.id !== positionIdUnlock;
    if (isNotInList) {
      setVisibleUnlockConfirm(true);
      setTypeVio(2);
    } else if (wrongOrder) {
      setVisibleUnlockConfirm(true);
      setTypeVio(1);
    } else {
      handleUnlock();
    }
    setIsLoadingUnlock(false);
  };

  const handleUnlock = async (isVio?: boolean) => {
    setLoadingConfirmUnlock(true);
    const dataSubmit: any = {
      deviceId: lockIdUnlock,
      positionId: positionIdUnlock,
      registerCarId: detailData?.id,
      source: detailData?.source,
    };
    if (isVio) {
      dataSubmit["isVio"] = isVio;
      dataSubmit["type"] = typeVio;
    }
    const resp = await journeyServices.vendorAttachLock(dataSubmit);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("journeyManagementPage.message.unlockSuccess"),
      });
      setPositionIdUnlock(undefined);
      setLockIdUnlock(undefined);
      setVisibleUnlockConfirm(false);
      setTypeVio(undefined);
      window.location.reload();
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoadingUnlock(false);
    setLoadingConfirmUnlock(false);
  };

  return (
    <div className=" journey-box">
      <div className="form-title">{t("journeyManagementPage.deliveryTitle")}</div>
      <Row gutter={20}>
        <Col span={12}>
          <CommonFormItem
            name="gateIn"
            label={t("journeyManagementPage.label.gateIn")}
            placeholder={t("journeyManagementPage.placeholder.gateIn") as string}
            options={positionSelectData
              ?.filter((item: any) => item.type === LOCATION_VALUES.GATE_IN)
              ?.map((item: any) => ({ value: item.id, label: item.name + " - " + item.address }))}
            type="select"
            disabled={
              detailData?.status !== JOURNEY_STATUS_VALUES.CHUA_VAO_SEV ||
              !isCanEditDeli ||
              !isEditing
            }
            showRequiredIcon={
              !(
                detailData?.status !== JOURNEY_STATUS_VALUES.CHUA_VAO_SEV ||
                !isCanEditDeli ||
                !isEditing
              )
            }
            rules={[
              {
                required: true,
                message: `${t("validate.select")} ${t("journeyManagementPage.label.gateIn")}.`,
              },
            ]}
          />
          <Row>
            <Col span={5}>
              <div style={{ fontSize: "0.833vw" }}>
                {t("journeyManagementPage.columns.unlock")}:
              </div>
            </Col>
            <Col span={19}>
              <div style={{ fontSize: "0.833vw" }}>
                {detailData?.companyDeliverySites?.[0]?.unlockTime
                  ? moment(detailData?.companyDeliverySites?.[0]?.unlockTime).format(
                      DATE_TIME_FORMAT_SECOND,
                    )
                  : "--"}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <div style={{ fontSize: "0.833vw" }}>
                {t("journeyManagementPage.columns.closeLock")}:
              </div>
            </Col>
            <Col span={19}>
              <div style={{ fontSize: "0.833vw" }}>
                {detailData?.companyDeliverySites?.[0]?.lockTime
                  ? moment(detailData?.companyDeliverySites?.[0]?.lockTime).format(
                      DATE_TIME_FORMAT_SECOND,
                    )
                  : "--"}
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <CommonFormItem
            name="gateOut"
            label={t("journeyManagementPage.label.gateOut")}
            placeholder={t("journeyManagementPage.placeholder.gateOut") as string}
            options={positionSelectData
              ?.filter((item: any) => item.type === LOCATION_VALUES.GATE_OUT)
              ?.map((item: any) => ({ value: item.id, label: item.name + " - " + item.address }))}
            type="select"
            showRequiredIcon={
              !(
                ![
                  JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
                  JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
                ].includes(detailData?.status) ||
                !isCanEditDeli ||
                !isEditing
              )
            }
            rules={[
              {
                required: true,
                message: `${t("validate.select")} ${t("journeyManagementPage.label.gateOut")}.`,
              },
            ]}
            disabled={
              ![
                JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
                JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
              ].includes(detailData?.status) ||
              !isCanEditDeli ||
              !isEditing
            }
          />
          <Row>
            <Col span={5}>
              <div style={{ fontSize: "0.833vw" }}>
                {t("journeyManagementPage.columns.unlock")}:
              </div>
            </Col>
            <Col span={19}>
              <div style={{ fontSize: "0.833vw" }}>
                {detailData?.companyDeliverySites?.[detailData?.companyDeliverySites?.length - 1]
                  ?.unlockTime
                  ? moment(
                      detailData?.companyDeliverySites?.[
                        detailData?.companyDeliverySites?.length - 1
                      ]?.unlockTime,
                    ).format(DATE_TIME_FORMAT_SECOND)
                  : "--"}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <div style={{ fontSize: "0.833vw" }}>
                {t("journeyManagementPage.columns.closeLock")}:
              </div>
            </Col>
            <Col span={19}>
              <div style={{ fontSize: "0.833vw" }}>
                {detailData?.companyDeliverySites?.[detailData?.companyDeliverySites?.length - 1]
                  ?.lockTime
                  ? moment(
                      detailData?.companyDeliverySites?.[
                        detailData?.companyDeliverySites?.length - 1
                      ]?.lockTime,
                    ).format(DATE_TIME_FORMAT_SECOND)
                  : "--"}
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="mgt-10">
          <DndContext onDragEnd={onDragEnd}>
            <SortableContext
              // rowKey array
              items={dataSource.map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <CommonTable
                components={{
                  body: {
                    row: RowTable,
                  },
                }}
                scroll={{ y: "20.833vw" }}
                pagination={false}
                rowKey="key"
                columns={columns}
                dataSource={dataSource}
              />
            </SortableContext>
          </DndContext>

          {[
            JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
            JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
          ].includes(detailData?.status) &&
          isCanEditDeli &&
          isEditing ? (
            <AddIcon className="add-icon mgt-20" onClick={handleAddNewRow} />
          ) : (
            <></>
          )}
        </Col>
      </Row>

      {detailData?.status === JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO && isCanEditDeli ? (
        <Row gutter={20}>
          <Col span={24}>
            <div className="mgt-30 form-title">{t("journeyManagementPage.unlockTitle")}</div>
          </Col>
          <Col span={12}>
            <div className="dpl-flex align-items-center" style={{ height: "100%" }}>
              <div className="txt-base mgr-10">
                {t("journeyManagementPage.label.positionUnlock")}:
              </div>
              <CommonSelect
                style={{ flex: 1 }}
                placeholder={t("journeyManagementPage.placeholder.positionUnlock") as string}
                options={positionSelectData
                  ?.filter((item: ILocationDetailData) => item?.type === LOCATION_VALUES.WAREHOUSE)
                  ?.map((item: ILocationDetailData) => ({
                    value: item.id,
                    label: item.name + " - " + item.address,
                  }))}
                onChange={(value) => setPositionIdUnlock(value)}
                value={positionIdUnlock}
                showSearch
                allowClear
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="dpl-flex align-items-center" style={{ height: "100%" }}>
              <div className="txt-base mgr-10">{t("journeyManagementPage.label.IMEIUnlock")}:</div>
              <CommonSelect
                style={{ flex: 1 }}
                placeholder={t("journeyManagementPage.placeholder.IMEIUnlock") as string}
                options={detailData?.registerCarLocks?.map((item: any) => ({
                  value: item?.categoryLock?.id,
                  label: item?.categoryLock?.uniqueid,
                }))}
                onChange={(value) => setLockIdUnlock(value)}
                value={lockIdUnlock}
                showSearch
                allowClear
              />
            </div>
          </Col>
          <Col span={24} className="mgt-30 dpl-flex align-items-center justify-content-center">
            <CommonButton
              size={"small"}
              btnType="warning"
              onClick={unlockBtnOnClick}
              loading={isLoadingUnlock}
            >
              {t("common.button.unlock")}
            </CommonButton>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      {visible ? (
        <CommonConfirmModal
          onCancel={() => {
            setVisible(false);
          }}
          content={t("journeyManagementPage.message.deleteDelivery")}
          visible={visible}
          onOk={onDeleteRow}
        />
      ) : (
        <></>
      )}

      {visibleUnlockConfirm ? (
        <CommonConfirmModal
          onCancel={() => {
            setVisibleUnlockConfirm(false);
            setTypeVio(undefined);
          }}
          content={
            <>
              <div style={{ textAlign: "left", marginBottom: "0.5rem" }}>
                <div className="mgt-10">
                  <span className="mgr-20">{t("journeyManagementPage.label.positionUnlock")}:</span>
                  <span style={{ fontWeight: 500 }}>
                    {`${
                      positionSelectData?.find((item) => item?.id === positionIdUnlock)?.name
                    } - ${
                      positionSelectData?.find((item) => item?.id === positionIdUnlock)?.address
                    }` || "--"}
                  </span>
                </div>
                <div className="mgt-10">
                  <span className="mgr-20">{t("journeyManagementPage.label.IMEIUnlock")}:</span>
                  <span style={{ fontWeight: 500 }}>
                    {detailData?.registerCarLocks?.find(
                      (item: any) => item?.categoryLock?.id === lockIdUnlock,
                    )?.categoryLock?.uniqueid || "--"}
                  </span>
                </div>
              </div>
              <div className="mgt-10 txt-base" style={{ color: "#d83939", textAlign: "center" }}>
                {t("journeyManagementPage.message.warningUnlockViolation")}
              </div>
            </>
          }
          title={t("journeyManagementPage.confirmUnlockViolation") as string}
          visible={visibleUnlockConfirm}
          onOk={() => {
            handleUnlock(true);
          }}
          loadingBtnOk={loadingConfirmUnlock}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default InfoTab;
