import { Col, Form, Row, Space, TablePaginationConfig, notification } from "antd";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CommonButton from "../../../../components/Common/Button";
import CommonForm from "../../../../components/Common/Form";
import CommonFormItem from "../../../../components/Common/FormItem";
import CommonTable from "../../../../components/Common/Table";
import lockServices from "../../../../services/lockManagement.service";
import {
  DATE_TIME_FORMAT_SECOND,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  ROLE_LIST,
  SEV_CODE,
} from "../../../../utils/constans";
import { SIDEBAR_ITEM_HREF } from "../../../../utils/constans/sidebar";
import { useQuery } from "../../../../utils/customHooks";
import { ICommonResponsePaging, IUseQueryResponse } from "../../../../utils/types";
import { buildQueryString, isHavePermission } from "../../../../utils/utilFunctions";
import { saveAs } from "file-saver";
import { IHistoryLockDetailData } from "../../../../utils/types/lockManagement";

function HistoryTab() {
  const componentPath = SIDEBAR_ITEM_HREF.lock_management;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const lockId = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [listData, setListData] = useState<ICommonResponsePaging<IHistoryLockDetailData>>();
  const navigate = useNavigate();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const {
    tabKey: tabKeyQuery,
    page: pageQuery,
    pageSize: pageSizeQuery,
    sortBy: sortByQuery,
    sortType: sortTypeQuery,
    search: searchQuery,
  } = params;
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};
  const page = pageQuery ? parseFloat(pageQuery) : DEFAULT_PAGE_NUMBER;
  const pageSize = pageSizeQuery ? parseFloat(pageSizeQuery) : DEFAULT_PAGE_SIZE;

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanExport = isHavePermission(ROLE_LIST.MANAGER_LOCK_EXPORT_HISTORY, profile);

  useEffect(() => {
    if (tabKeyQuery !== "history-tab") {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKeyQuery]);

  const getData = useCallback(
    async (reload?: boolean) => {
      setIsLoading(true);
      const paramsSearch = {
        page: reload ? 0 : page - 1,
        size: pageSize,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: JSON.stringify({ ...searchQueryData, lockId: lockId?.id, source: profile?.source }),
      };
      const resp = await lockServices.getHistoryLock(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        setListData(data?.data);
      } else {
        setListData(undefined);
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize, sortByQuery, sortTypeQuery, lockId?.id, profile?.source, t, search],
  );

  useEffect(() => {
    if (tabKeyQuery === "history-tab" && lockId?.id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  const onFinish = (values: any) => {
    onSearch(values);
  };

  const onSearch = (data: any = {}) => {
    const dataSearch = { ...data };
    if (data?.useTime?.length) {
      dataSearch["useTimeFromLong"] = moment(data?.useTime?.[0]).startOf("day").valueOf();
      dataSearch["useTimeToLong"] = moment(data?.useTime?.[1]).endOf("day").valueOf();
    }
    delete dataSearch?.useTime;
    let queryString = buildQueryString({
      tabKey: tabKeyQuery,
      page: DEFAULT_PAGE_NUMBER,
      search: JSON.stringify(dataSearch),
    });

    // detect if query not change => call api
    if (queryString !== search) {
      navigate(`${componentPath}/detail/${lockId?.id}${queryString}`);
    } else {
      getData();
    }
  };

  const onClear = (clearParams = {}) => {
    onSearch({ ...clearParams });
    setTimeout(() => {
      form.resetFields();
    }, 100);
  };

  const onPageChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
    extra: TableCurrentDataSource<IHistoryLockDetailData>,
  ) => {
    let queryString = buildQueryString({
      ...params,
      page: pageSize === pagination?.pageSize ? pagination?.current : DEFAULT_PAGE_NUMBER,
      pageSize: pagination?.pageSize,
      sortBy: sorter?.order ? (sorter?.field === "sourceStr" ? "source" : sorter?.field) : "",
      sortType: sorter?.order ? (sorter?.order === "ascend" ? "asc" : "desc") : "",
    });
    navigate(`${componentPath}/detail/${lockId?.id}${queryString || ""}`);
  };

  const columns = [
    {
      title: t("STT"),
      dataIndex: "index",
      key: "index",
      width: "7%",
      align: "center",
      render: (value: any, record: IHistoryLockDetailData, index: number) =>
        (page - 1) * pageSize + index + 1,
    },
    {
      title: t("lockManagementPage.columns.registrationCarId"),
      dataIndex: "registrationCarId",
      key: "registrationCarId",
      render: (value: any, record: IHistoryLockDetailData) => {
        return value || "--";
      },
    },
    {
      title: t("lockManagementPage.columns.useTime"),
      dataIndex: "useTime",
      key: "useTime",
      render: (value: any, record: IHistoryLockDetailData) => {
        return value ? moment(value).format(DATE_TIME_FORMAT_SECOND) : "--";
      },
    },
    {
      title: t("lockManagementPage.columns.plateNumber"),
      dataIndex: "plateNumber",
      key: "plateNumber",
      render: (value: any, record: IHistoryLockDetailData) => {
        return value || "--";
      },
    },
    {
      title: t("lockManagementPage.columns.startPoint"),
      dataIndex: "startPoint",
      key: "startPoint",
      render: (value: any, record: IHistoryLockDetailData) => {
        return (
          <>
            <div>{value || "--"}</div>
            {record.vendorDeleted ? (
              <div style={{ color: "red" }}>({t("common.deleted")})</div>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      title: t("lockManagementPage.columns.endPoint"),
      dataIndex: "endPoint",
      key: "endPoint",
      render: (value: any, record: IHistoryLockDetailData) => {
        return value || SEV_CODE;
      },
    },
  ];

  // xuất excel
  const handleExport = async () => {
    if (!isLoadingExport) {
      setIsLoadingExport(true);
      const paramsSearch = {
        page: 0,
        size: 10000,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: JSON.stringify({ ...searchQueryData, lockId: lockId?.id, source: profile?.source }),
      };
      const resp = await lockServices.exportHistoryLock(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        const fileName = `HistoryUseLock_Data_Export_${moment().format(
          "YYYYMMDD",
        )}_${moment().unix()}.xlsx`;
        saveAs(data, fileName);
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoadingExport(false);
    }
  };

  return (
    <div className="lock-info-tab">
      <div className="info-left">
        <CommonForm form={form} onFinish={onFinish} layout="vertical" initialValues={{}}>
          <Row gutter={30}>
            <Col span={24}>
              <CommonFormItem
                name="registrationCarId"
                label={t("lockManagementPage.label.registrationCarId")}
                placeholder={t("lockManagementPage.placeholder.registrationCarId") as string}
              />
            </Col>
            <Col span={24}>
              <CommonFormItem
                name="plateNumber"
                label={t("lockManagementPage.label.plateNumber")}
                placeholder={t("lockManagementPage.placeholder.plateNumber") as string}
              />
            </Col>
            <Col span={24}>
              <CommonFormItem
                name="startPoint"
                label={t("lockManagementPage.label.startPoint")}
                placeholder={t("lockManagementPage.placeholder.startPoint") as string}
              />
            </Col>
            <Col span={24}>
              <CommonFormItem
                name="useTime"
                type="rangePicker"
                label={t("lockManagementPage.label.useTime")}
                placeholder={[t("common.fromDate"), t("common.toDate")]}
              />
            </Col>
          </Row>

          <Space className="form-btn-container">
            <CommonButton btnType="default" size={"small"} onClick={() => onClear()}>
              {t("common.button.deleteCondition")}
            </CommonButton>
            <CommonButton btnType="primary" size={"small"} htmlType="submit">
              {t("common.button.search")}
            </CommonButton>
          </Space>
        </CommonForm>
      </div>
      <div className="info-right">
        {isCanExport && (
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "0.5rem" }}>
            <CommonButton
              size={"small"}
              btnType="success"
              loading={isLoadingExport}
              onClick={handleExport}
              htmlType="button"
            >
              {t("common.button.exportExcel")}
            </CommonButton>
          </div>
        )}
        <CommonTable
          isLoading={isLoading}
          rowKey={"id"}
          onChange={onPageChange}
          dataSource={listData?.content || []}
          columns={columns}
          data={listData}
          pagination={false}
          scroll={{ y: "20.833vw" }}
        />
      </div>
    </div>
  );
}

export default HistoryTab;
