import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Col, Form, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonInput from "../../components/Common/Input";
import { ReactComponent as Search } from "../../resources/images/search-1.svg";
import vendorServices from "../../services/vendorManagement.service";
import { DEFAULT_PAGE_NUMBER, SEV_CODE } from "../../utils/constans";
import {
  BATTERY_LEVEL,
  LOCK_STATUS,
  SIGNAL_STRENGTH_LEVEL,
} from "../../utils/constans/lockManagement";
import { useQuery } from "../../utils/customHooks";
import { ICommonSelectDataType, ISearchBoxProps, IUseQueryResponse } from "../../utils/types";
import { IVendorManagementDetailData } from "../../utils/types/vendorManagement";
import { buildQueryString } from "../../utils/utilFunctions";

function SearchBox({ componentPath, getData }: ISearchBoxProps) {
  const navigate = useNavigate();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const { advance: advanceQuery, search: searchQuery } = params;
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [advance, setAdvance] = useState(advanceQuery === "true" || false);
  const [vendorSelectData, setVendorSelectData] = useState<ICommonSelectDataType[]>([]);
  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isSEV = profile?.source === SEV_CODE;

  useEffect(() => {
    setAdvance(advanceQuery === "true");
  }, [advanceQuery]);

  const getDataSelect = async () => {
    const paramsSearch = {
      page: 0,
      size: 1000,
      search: JSON.stringify({}),
    };

    //select công ty quản lý
    const respVendor = await vendorServices.getListVendorManagement(paramsSearch);
    const dataVendor = respVendor?.data;
    if (respVendor?.status === 200) {
      setVendorSelectData(
        dataVendor?.data?.content?.map((item: IVendorManagementDetailData) => ({
          value: item?.vendorCode,
          label: item?.companyName,
        })),
      );
    } else {
      setVendorSelectData([]);
    }
  };

  useEffect(() => {
    if (isSEV) {
      getDataSelect();
    }
  }, [isSEV]);

  const onFinish = (values: any) => {
    onSearch(values);
  };

  const onClear = (clearParams = {}) => {
    onSearch({ ...clearParams }, true);
    setTimeout(() => {
      form.resetFields();
    }, 100);
  };

  const onSearch = (data: any = {}, isClear?: boolean) => {
    const dataSearch = { ...data };
    delete dataSearch.advance;
    let query = {
      advance,
      page: DEFAULT_PAGE_NUMBER,
      search: JSON.stringify(dataSearch),
    };
    if (isClear) {
      query.advance = data.advance;
    }
    const queryString = buildQueryString(query);
    // detect if query not change => call api
    if (queryString !== search) {
      navigate(`${componentPath}${queryString}`);
    } else {
      if (getData) getData();
    }
  };

  return (
    <div className="avic-search-box">
      {!advance ? (
        <div className="normal-search-box">
          <CommonForm
            form={form}
            onFinish={onFinish}
            layout="horizontal"
            initialValues={{
              uniqueid: searchQueryData?.uniqueid,
            }}
          >
            <CommonFormItem name="uniqueid">
              <CommonInput
                placeholder={t("lockManagementPage.placeholder.advancedSearch") as string}
                prefix={
                  <div
                    onClick={() => {
                      onClear({ advance: true });
                    }}
                    className="open-advance-search-btn"
                  >
                    {t("lockManagementPage.label.advancedSearch")} <CaretDownOutlined />
                  </div>
                }
                addonAfter={
                  <div onClick={() => form.submit()} className="btn-normal-search">
                    <Search />
                  </div>
                }
              />
            </CommonFormItem>
          </CommonForm>
        </div>
      ) : (
        <div className="advance-search-box">
          <div
            className="close-advance-search-btn cursor-pointer"
            onClick={() => {
              onClear({ advance: false });
            }}
          >
            <div>{t("lockManagementPage.searchTitle")}</div>
            <CaretUpOutlined className="cursor-pointer" />
          </div>
          <CommonForm
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              uniqueid: searchQueryData?.uniqueid,
              batteryLevel: searchQueryData?.batteryLevel,
              status: searchQueryData?.status,
              SignalStrengthLevel: searchQueryData?.SignalStrengthLevel,
              source: searchQueryData?.source,
            }}
          >
            <Row gutter={30}>
              <Col span={12}>
                <CommonFormItem
                  name="uniqueid"
                  label={t("lockManagementPage.label.uniqueid")}
                  placeholder={t("lockManagementPage.placeholder.uniqueid") as string}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="batteryLevel"
                  label={t("lockManagementPage.label.batteryLevel")}
                  placeholder={t("lockManagementPage.placeholder.batteryLevel") as string}
                  options={BATTERY_LEVEL.map((x) => ({ ...x, label: t(x.label) }))}
                  type="select"
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="status"
                  label={t("lockManagementPage.label.status")}
                  placeholder={t("lockManagementPage.placeholder.status") as string}
                  options={LOCK_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
                  type="select"
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="signalStrengthLevel"
                  label={t("lockManagementPage.label.signalStrengthLevel")}
                  placeholder={t("lockManagementPage.placeholder.signalStrengthLevel") as string}
                  options={SIGNAL_STRENGTH_LEVEL.map((x) => ({ ...x, label: t(x.label) }))}
                  type="select"
                />
              </Col>
              {isSEV ? (
                <Col span={12}>
                  <CommonFormItem
                    name="source"
                    label={t("lockManagementPage.label.source")}
                    placeholder={t("lockManagementPage.placeholder.source") as string}
                    options={[
                      { value: -1, label: t("common.options.noCompany") },
                      ...vendorSelectData,
                    ]}
                    type="select"
                  />
                </Col>
              ) : (
                <></>
              )}
            </Row>

            <Space className="form-btn-container">
              <CommonButton
                btnType="default"
                size={"small"}
                onClick={() => onClear({ advance: true })}
              >
                {t("common.button.deleteCondition")}
              </CommonButton>
              <CommonButton btnType="primary" size={"small"} htmlType="submit">
                {t("common.button.search")}
              </CommonButton>
            </Space>
          </CommonForm>
        </div>
      )}
    </div>
  );
}

export default SearchBox;
