// api common
import APIService from "../utils/apiServices";

class CommonServices extends APIService {
  //lấy danh sách tỉnh/thành, Quận/huyện, phường/xã
  async getPalces(data: { parentId?: number; type: string }) {
    let url = `/api/v1/common/getPlaces?type=${data.type}`;
    if (data?.parentId) {
      url += `&parentId=${data?.parentId}`;
    }
    return await this.request("GET", url);
  }

  //upload file
  async upload(files: any, body?: any) {
    const bodyFormData = new FormData();
    for (let file of files) {
      bodyFormData.append("file ", file);
    }
    if (body) {
      for (let p in body) {
        if (body[p]) {
          bodyFormData.append(p, body[p]);
        }
      }
    }
    return await this.request("POST", `uploadFile`, bodyFormData, {
      headers: {
        "Content-Disposition": "attachment",
      },
    });
  }
}
const service = new CommonServices();
export default service;
