import { Space, TableColumnsType, TablePaginationConfig, notification } from "antd";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";
import { saveAs } from "file-saver";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonConfirmModal from "../../components/Common/ConfirmModal";
import CommonTable from "../../components/Common/Table";
import CommonTag from "../../components/Common/Tag";
import accountServices from "../../services/accountManagement.service";
import {
  COMMON_PAGE_STATUS,
  DATE_TIME_FORMAT_SECOND,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  ROLE_LIST,
  SEV_CODE,
} from "../../utils/constans";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import { useQuery } from "../../utils/customHooks";
import { ICommonResponsePaging, ICommonSelectDataType, IUseQueryResponse } from "../../utils/types";
import { IAccountDetailData } from "../../utils/types/accountManagement";
import { IGroupDetailData } from "../../utils/types/groupManagement";
import { buildQueryString, isHavePermission } from "../../utils/utilFunctions";
import SearchBox from "./SearchBox";

function AccountManagement() {
  const componentPath = SIDEBAR_ITEM_HREF.account_management;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const {
    page: pageQuery,
    pageSize: pageSizeQuery,
    sortBy: sortByQuery,
    sortType: sortTypeQuery,
    search: searchQuery,
  } = params;
  const page = pageQuery ? parseFloat(pageQuery) : DEFAULT_PAGE_NUMBER;
  const pageSize = pageSizeQuery ? parseFloat(pageSizeQuery) : DEFAULT_PAGE_SIZE;
  const [data, setData] = useState<ICommonResponsePaging<IAccountDetailData>>();
  const [dataSelected, setDataSelected] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isSEV = profile?.source === SEV_CODE;
  const isCanCreate = isHavePermission(ROLE_LIST.MANAGER_ACCOUNT_CREATE, profile);
  const isCanEdit = isHavePermission(ROLE_LIST.MANAGER_ACCOUNT_EDIT, profile);
  const isCanViewDetail = isHavePermission(ROLE_LIST.MANAGER_ACCOUNT_VIEW_DETAIL, profile);
  const isCanDelete = isHavePermission(ROLE_LIST.MANAGER_ACCOUNT_DELETE, profile);
  const isCanExport = isHavePermission(ROLE_LIST.MANAGER_ACCOUNT_EXPORT, profile);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getData = async (reload?: boolean) => {
    setIsLoading(true);
    const paramsSearch = {
      page: reload ? 0 : page - 1,
      size: pageSize,
      sortBy: sortByQuery,
      sortType: sortTypeQuery,
      search: isSEV ? searchQuery : JSON.stringify({ ...searchQueryData, source: profile?.source }),
    };
    const resp = await accountServices.getAccount(paramsSearch);
    const data = resp?.data;
    if (resp?.status === 200) {
      setData(data?.data);
    } else {
      setData(undefined);
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  };

  const onPageChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
    extra: TableCurrentDataSource<IAccountDetailData>,
  ) => {
    let queryString = buildQueryString({
      ...params,
      page: pageSize === pagination?.pageSize ? pagination?.current : DEFAULT_PAGE_NUMBER,
      pageSize: pagination?.pageSize,
      sortBy: sorter?.order ? (sorter?.field === "sourceStr" ? "source" : sorter?.field) : "",
      sortType: sorter?.order ? (sorter?.order === "ascend" ? "asc" : "desc") : "",
    });
    navigate(`${componentPath}${queryString || ""}`);
  };

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: number[], selectedRows: IAccountDetailData[]) => {
      setDataSelected(selectedRowKeys);
    },
  };

  const columns: TableColumnsType<IAccountDetailData> = [
    {
      title: t("accountManagementPage.columns.STT"),
      dataIndex: "index",
      key: "index",
      width: "5%",
      align: "center",
      render: (value: any, record: IAccountDetailData, index: number) =>
        (page - 1) * pageSize + index + 1,
    },
    {
      title: t("accountManagementPage.columns.username"),
      dataIndex: "username",
      key: "username",
      sorter: true,
      render: (value: any, record: IAccountDetailData, index: number) => {
        return isCanEdit || isCanViewDetail ? (
          <Link to={`${componentPath}/detail/${record?.id}`}>{value || "--"}</Link>
        ) : (
          value || "--"
        );
      },
    },
    {
      title: t("accountManagementPage.columns.name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (value: any, record: IAccountDetailData, index: number) => {
        return value || "--";
      },
    },
    {
      title: t("accountManagementPage.columns.groups"),
      dataIndex: "groups",
      key: "groups",
      render: (value: any, record: IAccountDetailData, index: number) => {
        return value?.length
          ? value?.map((item: IGroupDetailData) => item?.groupName).join(",  ")
          : "--";
      },
    },
    {
      title: t("accountManagementPage.columns.source"),
      dataIndex: "sourceStr",
      key: "sourceStr",
      sorter: true,
      hidden: !isSEV,
      render: (value: any, record: IAccountDetailData, index: number) => {
        return value || "--";
      },
    },
    {
      title: t("accountManagementPage.columns.modifiedDate"),
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      sorter: true,
      render: (value: any, record: IAccountDetailData, index: number) => {
        return value ? moment(value).format(DATE_TIME_FORMAT_SECOND) : "--";
      },
    },
    {
      title: t("accountManagementPage.columns.modifiedBy"),
      dataIndex: "modifiedBy",
      key: "modifiedBy",
      render: (value: any, record: IAccountDetailData, index: number) => {
        return value || "--";
      },
      sorter: true,
    },
    {
      title: t("accountManagementPage.columns.lastOnline"),
      dataIndex: "lastOnline",
      key: "lastOnline",
      sorter: true,
      render: (value: any, record: IAccountDetailData, index: number) => {
        return value ? moment(value).format(DATE_TIME_FORMAT_SECOND) : "--";
      },
    },
    {
      title: t("accountManagementPage.columns.status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (value: any, record: IAccountDetailData, index: number) => {
        const curStatus = COMMON_PAGE_STATUS.find((x: ICommonSelectDataType) => x.value === value);
        return curStatus ? (
          <CommonTag tagType={curStatus?.type}>{t(curStatus?.label)}</CommonTag>
        ) : (
          "--"
        );
      },
    },
  ];

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    const resp = await accountServices.deleteAccount(dataSelected || []);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("accountManagementPage.message.deleteSuccess"),
      });
      setDataSelected([]);
      setVisible(false);
      let queryString = buildQueryString({
        ...params,
        page: DEFAULT_PAGE_NUMBER,
        search: JSON.stringify({}),
      });
      if (queryString !== search) {
        navigate(`${queryString || ""}`);
      } else {
        getData(true);
      }
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoadingDelete(false);
  };

  // xuất excel
  const exportData = async () => {
    if (!isLoadingExcel) {
      setIsLoadingExcel(true);
      const paramsSearch = {
        page: 0,
        size: 10000,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: isSEV
          ? JSON.stringify({ ...searchQueryData, exportType: 1 })
          : JSON.stringify({ ...searchQueryData, exportType: 1, source: profile?.source }),
      };
      const resp = await accountServices.exportAccount(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        const fileName = `AccountManagement_Data_Export_${moment().format(
          "YYYYMMDD",
        )}_${moment().unix()}.xlsx`;
        saveAs(data, fileName);
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoadingExcel(false);
    }
  };

  return (
    <div className="content-wrapper">
      <SearchBox getData={getData} componentPath={componentPath} />

      <div className="avic-table-top">
        <div className="avic-table-top-title">{t("accountManagementPage.title")}</div>
        <Space className="avic-table-top-right">
          {isSEV ? (
            <>
              {dataSelected?.length > 0 && isCanDelete && (
                <CommonButton btnType="danger" size={"small"} onClick={() => setVisible(true)}>
                  {t("common.button.remove")}
                </CommonButton>
              )}
              {isCanCreate && (
                <Link to={`${componentPath}/add`}>
                  <CommonButton btnType="primary" size={"small"}>
                    {t("common.button.createAccount")}
                  </CommonButton>
                </Link>
              )}
            </>
          ) : (
            <></>
          )}
          {isCanExport && (
            <CommonButton
              loading={isLoadingExcel}
              btnType="success"
              onClick={exportData}
              size={"small"}
            >
              {t("common.button.exportExcel")}
            </CommonButton>
          )}
        </Space>
      </div>

      <CommonTable
        isLoading={isLoading}
        rowKey={"id"}
        dataSource={data?.content || []}
        columns={columns}
        data={data}
        onChange={onPageChange}
        rowSelection={
          isSEV && isCanDelete ? { ...rowSelection, selectedRowKeys: dataSelected } : null
        }
        defaultSorter={{
          order: sortTypeQuery,
          field: sortByQuery === "source" ? "sourceStr" : sortByQuery,
        }}
      />

      {visible ? (
        <CommonConfirmModal
          onCancel={() => setVisible(false)}
          content={t("accountManagementPage.message.delete")}
          visible={visible}
          onOk={handleDelete}
          loadingBtnOk={isLoadingDelete}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default AccountManagement;
