import { Tabs, TabsProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROLE_LIST } from "../../../../utils/constans";
import { SIDEBAR_ITEM_HREF } from "../../../../utils/constans/sidebar";
import { useQuery } from "../../../../utils/customHooks";
import { IUseQueryResponse } from "../../../../utils/types";
import { IVendorTabsProps } from "../../../../utils/types/vendorManagement";
import { buildQueryString, isHavePermission } from "../../../../utils/utilFunctions";
import LockTab from "./LockTab";
import StaffTab from "./StaffTab";

function VendorTabs({ detailVendor }: IVendorTabsProps) {
  const componentPath = SIDEBAR_ITEM_HREF.vendor_management;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {} } = queryObj;
  const { tabKey: tabKeyQuery } = params;
  const [activeKey, setActiveKey] = useState<string>("staff-tab");

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanViewStaffTab = isHavePermission(
    ROLE_LIST.MANAGER_VENDOR_VIEW_DETAIL_INFO_STAFF,
    profile,
  );
  const isCanViewLockTab = isHavePermission(
    ROLE_LIST.MANAGER_VENDOR_VIEW_DETAIL_LOCK_MANAGER,
    profile,
  );

  useEffect(() => {
    const newTabKey = tabKeyQuery ? tabKeyQuery : isCanViewStaffTab ? "staff-tab" : "lock-tab";
    setActiveKey(newTabKey);
    if (newTabKey !== tabKeyQuery && detailVendor?.id) {
      navigate(
        `${componentPath}/detail/${detailVendor?.id}${
          buildQueryString({ tabKey: newTabKey }) || ""
        }`,
      );
    }
  }, [componentPath, detailVendor?.id, isCanViewStaffTab, navigate, tabKeyQuery]);

  const items: TabsProps["items"] = useMemo(() => {
    let newItems = [];
    if (isCanViewStaffTab) {
      newItems.push({
        key: "staff-tab",
        label: t("vendorManagementPage.staffTab"),
        children: <StaffTab detailVendor={detailVendor} />,
      });
    }
    if (isCanViewLockTab) {
      newItems.push({
        key: "lock-tab",
        label: t("vendorManagementPage.lockTab"),
        children: <LockTab detailVendor={detailVendor} />,
      });
    }
    return newItems;
  }, [detailVendor, isCanViewLockTab, isCanViewStaffTab, t]);
  const onChange = (key: string) => {
    navigate(
      `${componentPath}/detail/${detailVendor?.id}${buildQueryString({ tabKey: key }) || ""}`,
    );
  };
  return (
    <>
      {items?.length > 0 && (
        <div className="avic-search-box">
          <div className="advance-search-box">
            <Tabs activeKey={activeKey} items={items} onChange={onChange} />
          </div>
        </div>
      )}
    </>
  );
}

export default VendorTabs;
