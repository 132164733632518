import { Col, Form, Row, notification } from "antd";
import { useWatch } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonModal from "../../components/Common/Modal";
import vehicleTypeServices from "../../services/vehicleTypeCategory.service";
import { COMMON_PAGE_STATUS, REGEX_NUMBER_GREATER_ZERO, ROLE_LIST } from "../../utils/constans";
import { IVehicleTypeAddModalProps } from "../../utils/types/vehicleType";
import { isHavePermission } from "../../utils/utilFunctions";

const VehicleTypeAddModal = ({
  dataDetail,
  visible,
  handleOk,
  handleCancel,
}: IVehicleTypeAddModalProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const isInternalCarValue = useWatch("isInternalCar", form);

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanEdit = isHavePermission(ROLE_LIST.MANAGER_CAR_EDIT, profile);

  useEffect(() => {
    if (isInternalCarValue) {
      form.setFieldValue("timeForAllow", "0");
    }
  }, [isInternalCarValue, form]);

  const onFinish = async (values: any) => {
    setIsLoading(true);
    const dataUpdate = { ...values, isInternalCar: values?.isInternalCar ? 99 : 0 }; // 99 là xe nội bộ
    const resp = dataDetail?.id
      ? await vehicleTypeServices.updateVehicleType(dataDetail?.id, dataUpdate)
      : await vehicleTypeServices.createVehicleType(dataUpdate);
    if (resp?.status === 200) {
      notification.success({
        message: dataDetail?.id
          ? t("vehicleCategoryPage.message.editSuccess")
          : t("vehicleCategoryPage.message.createSuccess"),
      });
      setIsEditing(false);
      handleOk();
    } else {
      notification.error({
        message: t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  };

  return (
    <CommonModal
      className="lock-modal-create"
      title={
        dataDetail?.id ? t("vehicleCategoryPage.editTitle") : t("vehicleCategoryPage.addTitle")
      }
      open={visible}
      maskClosable={false}
      onCancel={handleCancel}
      footer={[
        isEditing && dataDetail?.id ? (
          <CommonButton onClick={() => setIsEditing(false)} size={"small"} btnType="default">
            {t("common.button.cancel")}
          </CommonButton>
        ) : (
          <CommonButton onClick={handleCancel} size={"small"} btnType="default">
            {t("common.button.exit")}
          </CommonButton>
        ),
        isCanEdit && dataDetail?.id ? (
          isEditing ? (
            <CommonButton
              form="myForm"
              onClick={() => form.submit()}
              btnType="primary"
              size={"small"}
              loading={isLoading}
            >
              {t("common.button.update")}
            </CommonButton>
          ) : (
            <CommonButton onClick={() => setIsEditing(true)} btnType="primary" size={"small"}>
              {t("common.button.edit")}
            </CommonButton>
          )
        ) : (
          <></>
        ),

        !dataDetail?.id ? (
          <CommonButton
            form="myForm"
            onClick={() => form.submit()}
            btnType="primary"
            size={"small"}
            loading={isLoading}
          >
            {t("common.button.addNew")}
          </CommonButton>
        ) : (
          <></>
        ),
      ]}
    >
      <CommonForm
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          name: dataDetail?.name,
          timeForAllow: dataDetail?.timeForAllow,
          isInternalCar: !!dataDetail?.isInternalCar,
          status: dataDetail?.status,
        }}
      >
        <Row gutter={30}>
          <Col span={24}>
            <CommonFormItem
              name="name"
              label={t("vehicleCategoryPage.label.name")}
              placeholder={t("vehicleCategoryPage.placeholder.name") as string}
              maxLength={128}
              showRequiredIcon={
                !(!!dataDetail?.id && (dataDetail?.type === 2 || !isCanEdit || !isEditing))
              }
              disabled={!!dataDetail?.id && (dataDetail?.type === 2 || !isCanEdit || !isEditing)}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: `${t("validate.input")} ${t("vehicleCategoryPage.label.name")}.`,
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <CommonFormItem
              name="timeForAllow"
              label={t("vehicleCategoryPage.label.timeForAllow")}
              placeholder={t("vehicleCategoryPage.placeholder.timeForAllow") as string}
              showRequiredIcon={
                !(isInternalCarValue || (!!dataDetail?.id && (!isCanEdit || !isEditing)))
              }
              disabled={isInternalCarValue || (!!dataDetail?.id && (!isCanEdit || !isEditing))}
              rules={
                !isInternalCarValue && (isCanEdit || !dataDetail?.id)
                  ? [
                      {
                        required: true,
                        message: `${t("validate.input")} ${t(
                          "vehicleCategoryPage.label.timeForAllow",
                        )}.`,
                      },
                      {
                        pattern: REGEX_NUMBER_GREATER_ZERO,
                        message: `${t("vehicleCategoryPage.label.timeForAllow")} ${t(
                          "validate.greaterThanZore",
                        )}.`,
                      },
                    ]
                  : []
              }
            />
          </Col>
          <Col span={24}>
            <CommonFormItem
              style={{ display: "flex" }}
              name="isInternalCar"
              labelCheckbox={t("common.unlimited") as string}
              type="checkbox"
              valuePropName="checked"
              disabled={!!dataDetail?.id && (dataDetail?.type === 2 || !isCanEdit || !isEditing)}
            />
          </Col>
          <Col span={24}>
            <CommonFormItem
              name="status"
              label={t("vehicleCategoryPage.label.status")}
              placeholder={t("vehicleCategoryPage.placeholder.status") as string}
              options={COMMON_PAGE_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
              showRequiredIcon={
                !(!!dataDetail?.id && (dataDetail?.type === 2 || !isCanEdit || !isEditing))
              }
              disabled={!!dataDetail?.id && (dataDetail?.type === 2 || !isCanEdit || !isEditing)}
              type="select"
              rules={[
                {
                  required: true,
                  message: `${t("validate.select")} ${t("vehicleCategoryPage.label.status")}.`,
                },
              ]}
            />
          </Col>
        </Row>
      </CommonForm>
    </CommonModal>
  );
};

export default VehicleTypeAddModal;
