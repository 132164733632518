import { SearchOutlined } from "@ant-design/icons";
import { GetRef, Input, Space, TableColumnType, TableColumnsType, notification } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { Key, useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../components/Common/Button";
import CommonModal from "../../../components/Common/Modal";
import CommonTable from "../../../components/Common/Table";
import CommonTag from "../../../components/Common/Tag";
import accountServices from "../../../services/accountManagement.service";
import lockServices from "../../../services/lockManagement.service";
import { COMMON_PAGE_STATUS } from "../../../utils/constans";
import { ICommonResponsePaging, ICommonSelectDataType } from "../../../utils/types";
import { IAccountDetailData } from "../../../utils/types/accountManagement";
import { IGroupDetailData } from "../../../utils/types/groupManagement";
import {
  IDecentralizationAccountUseLock,
  IDecentralizeKeyUsageModalProps,
} from "../../../utils/types/lockManagement";

type InputRef = GetRef<typeof Input>;
type DataIndex = keyof IAccountDetailData;

const DecentralizeKeyUsageModal = ({
  visable,
  handleOk,
  handleCancel,
  detailLock,
}: IDecentralizeKeyUsageModalProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const [listData, setListData] = useState<ICommonResponsePaging<IAccountDetailData>>();
  const [dataSelected, setDataSelected] = useState<Key[]>([]);

  // table search column
  const [searchText, setSearchText] = useState<string>("");
  const [searchedColumn, setSearchedColumn] = useState<string>("");
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    setIsLoading(true);
    const paramsSearch = {
      page: 0,
      size: 1000,
    };
    const resp = await accountServices.getAccount({
      ...paramsSearch,
      search: JSON.stringify({ source: detailLock?.vendor?.vendorCode }),
    });
    const data = resp?.data;
    if (resp?.status === 200) {
      setListData(data?.data);
    } else {
      setListData(undefined);
    }

    const respChecked = await accountServices.getAccount({
      ...paramsSearch,
      search: JSON.stringify({ lockId: detailLock?.id }),
    });
    const dataChecked = respChecked?.data;
    if (respChecked?.status === 200) {
      setDataSelected(dataChecked?.data?.content?.map((item: IAccountDetailData) => item?.id));
    } else {
      setDataSelected([]);
    }
    setIsLoading(false);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<IAccountDetailData> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={t("common.searchPlaceholder") as string}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <CommonButton
            btnType="default"
            size="small"
            onClick={() => {
              close();
            }}
          >
            {t("common.button.close")}
          </CommonButton>
          <CommonButton
            btnType="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            {t("common.button.search")}
          </CommonButton>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: IAccountDetailData[]) => {
      setDataSelected(selectedRowKeys);
    },
  };

  const columns: TableColumnsType<IAccountDetailData> = [
    {
      title: t("accountManagementPage.columns.STT"),
      dataIndex: "index",
      key: "index",
      width: "7%",
      align: "center",
      render: (value: any, record: IAccountDetailData, index: number) => index + 1,
    },
    {
      title: t("accountManagementPage.columns.username"),
      dataIndex: "username",
      key: "username",
      render: (value: any, record: IAccountDetailData, index: number) => {
        return value || "--";
      },
      sorter: true,
      ...getColumnSearchProps("username"),
    },
    {
      title: t("accountManagementPage.columns.name"),
      dataIndex: "name",
      key: "name",
      render: (value: any, record: IAccountDetailData, index: number) => {
        return value || "--";
      },
      sorter: true,
      ...getColumnSearchProps("name"),
    },
    {
      title: t("accountManagementPage.columns.groups"),
      dataIndex: "groups",
      key: "groups",
      render: (value: any, record: IAccountDetailData, index: number) => {
        return value?.length
          ? value?.map((item: IGroupDetailData) => item?.groupName).join(",  ")
          : "--";
      },
    },
    {
      title: t("accountManagementPage.columns.source"),
      dataIndex: "sourceStr",
      key: "sourceStr",
      render: (value: any, record: IAccountDetailData, index: number) => {
        return value || "--";
      },
      sorter: true,
    },
    {
      title: t("accountManagementPage.columns.status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (value: any, record: IAccountDetailData, index: number) => {
        const curStatus = COMMON_PAGE_STATUS.find((x: ICommonSelectDataType) => x.value === value);
        return curStatus ? (
          <CommonTag tagType={curStatus?.type}>{t(curStatus?.label)}</CommonTag>
        ) : (
          "--"
        );
      },
    },
  ];

  const onOk = async () => {
    setIsLoadingConfirm(true);
    const dataSubmit: IDecentralizationAccountUseLock = {
      lockId: detailLock?.id as number,
      userIds: dataSelected as number[],
    };
    const resp = await lockServices.decentralizationAccountUseLock(dataSubmit);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("lockManagementPage.message.grantPermissionsSuccess"),
      });
      handleOk();
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoadingConfirm(false);
  };

  return (
    <CommonModal
      className="decentralize-key-usage-modal"
      title={t("lockManagementPage.grantPermissionsUse")}
      open={visable}
      maskClosable={false}
      onCancel={handleCancel}
      footer={[
        <CommonButton key="cancel" onClick={handleCancel} size={"small"} btnType="default">
          {t("common.button.cancel")}
        </CommonButton>,
        <CommonButton
          form="myForm"
          key="submit"
          onClick={onOk}
          btnType="primary"
          size={"small"}
          loading={isLoadingConfirm}
        >
          {t("common.button.saveChange")}
        </CommonButton>,
      ]}
    >
      <CommonTable
        isLoading={isLoading}
        rowKey={"id"}
        dataSource={listData?.content || []}
        columns={columns}
        data={listData}
        pagination={false}
        scroll={{ y: "50vh" }}
        rowSelection={{ ...rowSelection, selectedRowKeys: dataSelected }}
        style={{ minHeight: "50vh" }}
      />
    </CommonModal>
  );
};

export default DecentralizeKeyUsageModal;
