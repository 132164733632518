// Api danh mục loại xe
import APIService from "../utils/apiServices";
import { ICommonSearchParams } from "../utils/types";
import {
  IDismantlingLock,
  IJourneyAddData,
  IJourneyDetailData,
  IOpenLockData,
  IReportBrokenLock,
} from "../utils/types/journeyManagement";

class JourneyManagementServices extends APIService {
  // Lấy danh sách hành trình
  async getListJourney(params: ICommonSearchParams) {
    let apiParams = {
      page: params.page || 0,
      size: params.size || 10,
      sortType: params.sortType || "DESC",
      sortBy: params.sortBy || "modifiedDate",
      search: params.search || JSON.stringify({}),
    };
    return await this.request(
      "GET",
      `/api/company-delivery/list-registration-car`,
      {},
      { params: apiParams },
    );
  }

  // Đăng ký chuyến/ thêm hành trình
  async createJourney(data: Partial<IJourneyAddData>) {
    return await this.request("POST", `/api/company-delivery/registration-car`, data);
  }

  //update hành trình
  async updateJourney(id: number, data: Partial<IJourneyDetailData>) {
    return await this.request("PUT", `/api/company-delivery/update-registration-car/${id}`, data);
  }

  // xóa nhiều hành trình
  async deleteJourneies(data: number[]) {
    return await this.request("DELETE", `/api/company-delivery/delete-registration-car`, data);
  }

  // lấy chi tiết hành trình
  async getDetailJourney(id: string | number) {
    return await this.request("GET", `/api/company-delivery/registration-car/${id}`);
  }

  // gắn khóa, mở khóa
  async vendorAttachLock(data: Partial<IOpenLockData>) {
    return await this.request("POST", `/api/command/unlock-by-admin-v2`, data);
  }

  // check-in
  async checkInJourney(data: number[]) {
    return await this.request("POST", `/api/company-delivery/checkin-sev`, data);
  }

  // check-out
  async checkOutJourney(data: number[]) {
    return await this.request("POST", `/api/company-delivery/checkout-sev`, data);
  }
  // check-out
  async getGPS(data: number[]) {
    return await this.request("POST", `/api/company-delivery/load-gps-by-admin`, data);
  }

  //Xác nhận khởi hành
  async confirmStart(id: string | number) {
    return await this.request("PUT", `/api/company-delivery/confirm-start-delivery/${id}`);
  }

  // Hủy chuyến
  async cancelJourney(data: number[]) {
    return await this.request("POST", `/api/company-delivery/cancel-registration-car`, data);
  }

  // Hoàn thành chuyến
  async completeJourney(id: number | string) {
    return await this.request("PUT", `/api/company-delivery/complete-delivery/${id}`);
  }

  // Lấy danh sách vi phạm
  async getListViolationOfJourney(id: number | string) {
    return await this.request(
      "GET",
      `/api/company-delivery/get-violation-by-registration-car-id/${id}`,
    );
  }

  // Báo cáo khóa hỏng
  async reportBrokenLock(data: IReportBrokenLock) {
    return await this.request("POST", `/api/company-delivery/report-broken-lock`, data);
  }
  // Tháo khóa bởi admin vendor
  async dismantlingLock(data: IDismantlingLock) {
    return await this.request("POST", `/api/command/unlock-vendor`, data);
  }

  // Lấy danh sách lịch sử
  async getListHistoryJourney(params: ICommonSearchParams) {
    let apiParams = {
      page: params.page || 0,
      size: params.size || 10,
      sortType: params.sortType || "DESC",
      sortBy: params.sortBy || "modifiedDate",
      search: params.search || JSON.stringify({}),
    };
    return await this.request("GET", `/api/v1/system/action/getPage`, {}, { params: apiParams });
  }

  // lấy danh sách các vị trí khi xem playback
  async getListAllLocation(id: string | number) {
    return await this.request(
      "GET",
      `/api/company-delivery/load-all-position-registration-car/${id}`,
    );
  }

  // Xuất excel danh sách hành trình
  async exportJourney(params: ICommonSearchParams) {
    return await this.request(
      "GET",
      `/api/company-delivery/exportExcel`,
      {},
      {
        responseType: "blob",
        params: params,
      },
    );
  }

  // Xuất excel danh sách lịch sử thao tác và vi phạm
  async exportHistoryVio(params: ICommonSearchParams) {
    return await this.request(
      "GET",
      "/api/v1/system/action/exportExcel",
      {},
      {
        responseType: "blob",
        params: params,
      },
    );
  }
}
const service = new JourneyManagementServices();
export default service;
