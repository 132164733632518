import { IBreadcrumbItemType } from "../types/layout";

// breadcrum + sidebar
export const SIDEBAR_ITEM_KEY: { [x: string]: string } = {
  home: "home",
  operate: "operate",
  journey_management: "journey-management",
  lock_management: "lock-management",
  vendor_management: "vendor-management",
  account_management: "account-management",
  group_management: "group-management",
  location_category: "location-category",
  vehicle_category: "vehicle-category",
  profile: "profile",
};
export const SIDEBAR_ITEM_HREF: { [x: string]: string } = {
  home: "/",
  operate: "/operate",
  journey_management: "/journey-management",
  lock_management: "/lock-management",
  vendor_management: "/vendor-management",
  account_management: "/account-management",
  group_management: "/group-management",
  location_category: "/location-category",
  vehicle_category: "/vehicle-category",
  profile: "/profile",
  apk: "/apk",
};
export const BREADCRUMB_ITEM: { [x: string]: IBreadcrumbItemType } = {
  [SIDEBAR_ITEM_KEY.home]: {
    title: "layout.sidebar.home",
    href: SIDEBAR_ITEM_HREF.home,
  },
  [SIDEBAR_ITEM_KEY.operate]: {
    title: "layout.sidebar.operate",
    href: SIDEBAR_ITEM_HREF.operate,
  },
  [SIDEBAR_ITEM_KEY.journey_management]: {
    title: "layout.sidebar.journeyManagement",
    href: SIDEBAR_ITEM_HREF.journey_management,
    add: { title: "layout.sidebar.journeyManagementAdd" },
    detail: { title: "layout.sidebar.journeyManagementDetail" },
  },
  [SIDEBAR_ITEM_KEY.lock_management]: {
    title: "layout.sidebar.lockManagement",
    href: SIDEBAR_ITEM_HREF.lock_management,
    detail: { title: "layout.sidebar.lockManagementDetail" },
  },
  [SIDEBAR_ITEM_KEY.vendor_management]: {
    title: "layout.sidebar.vendorManagement",
    href: SIDEBAR_ITEM_HREF.vendor_management,
    add: { title: "layout.sidebar.vendorManagementAdd" },
    detail: { title: "layout.sidebar.vendorManagementDetail" },
  },
  [SIDEBAR_ITEM_KEY.account_management]: {
    title: "layout.sidebar.accountManagement",
    href: SIDEBAR_ITEM_HREF.account_management,
    add: { title: "layout.sidebar.accountManagementAdd" },
    detail: { title: "layout.sidebar.accountManagementDetail" },
  },
  [SIDEBAR_ITEM_KEY.group_management]: {
    title: "layout.sidebar.groupManagement",
    href: SIDEBAR_ITEM_HREF.group_management,
    add: { title: "layout.sidebar.groupManagementAdd" },
    detail: { title: "layout.sidebar.groupManagementDetail" },
  },
  [SIDEBAR_ITEM_KEY.location_category]: {
    title: "layout.sidebar.locationCategory",
    href: SIDEBAR_ITEM_HREF.location_category,
    history: {
      title: "layout.sidebar.locationCategoryHistory",
    },
  },
  [SIDEBAR_ITEM_KEY.vehicle_category]: {
    title: "layout.sidebar.vehicleCategory",
    href: SIDEBAR_ITEM_HREF.vehicle_category,
  },
  [SIDEBAR_ITEM_KEY.profile]: {
    title: "layout.sidebar.profile",
    href: SIDEBAR_ITEM_HREF.profile,
  },
};
