import { Col, Form, Row, Space, TableColumnsType, TablePaginationConfig, notification } from "antd";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";
import { saveAs } from "file-saver";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CommonButton from "../../../../components/Common/Button";
import CommonForm from "../../../../components/Common/Form";
import CommonFormItem from "../../../../components/Common/FormItem";
import CommonTable from "../../../../components/Common/Table";
import CommonTag from "../../../../components/Common/Tag";
import lockServices from "../../../../services/lockManagement.service";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, ROLE_LIST } from "../../../../utils/constans";
import {
  BATTERY_LEVEL,
  LOCK_STATUS,
  SIGNAL_STRENGTH_LEVEL,
} from "../../../../utils/constans/lockManagement";
import { SIDEBAR_ITEM_HREF } from "../../../../utils/constans/sidebar";
import { useQuery } from "../../../../utils/customHooks";
import {
  ICommonResponsePaging,
  ICommonSelectDataType,
  IUseQueryResponse,
} from "../../../../utils/types";
import { ILockDetailData } from "../../../../utils/types/lockManagement";
import { IVendorTabsProps } from "../../../../utils/types/vendorManagement";
import { buildQueryString, isHavePermission } from "../../../../utils/utilFunctions";

function LockTab({ detailVendor }: IVendorTabsProps) {
  const componentPath = SIDEBAR_ITEM_HREF.vendor_management;
  const vendorId = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listData, setListData] = useState<ICommonResponsePaging<ILockDetailData>>();
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const navigate = useNavigate();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const {
    tabKey: tabKeyQuery,
    page: pageQuery,
    pageSize: pageSizeQuery,
    sortBy: sortByQuery,
    sortType: sortTypeQuery,
    search: searchQuery,
  } = params;
  const page = pageQuery ? parseFloat(pageQuery) : DEFAULT_PAGE_NUMBER;
  const pageSize = pageSizeQuery ? parseFloat(pageSizeQuery) : DEFAULT_PAGE_SIZE;
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanExport = isHavePermission(ROLE_LIST.MANAGER_VENDOR_EXCEL_LOCK, profile);

  const columns: TableColumnsType<ILockDetailData> = [
    {
      title: t("lockManagementPage.columns.STT"),
      dataIndex: "index",
      key: "index",
      width: "6%",
      align: "center",
      render: (value: any, record: ILockDetailData, index: number) =>
        (page - 1) * pageSize + index + 1,
    },
    {
      title: t("lockManagementPage.columns.uniqueid"),
      dataIndex: "uniqueid",
      key: "uniqueid",
      sorter: true,
      render: (value: any, record: ILockDetailData, index: number) => {
        return value || "--";
      },
    },
    {
      title: t("lockManagementPage.columns.countUse"),
      dataIndex: "countUse",
      key: "countUse",
      sorter: true,
      render: (value: any, record: ILockDetailData, index: number) =>
        typeof value === "number" ? value : "--",
    },
    {
      title: t("lockManagementPage.columns.battery"),
      dataIndex: "battery",
      key: "battery",
      sorter: true,
      render: (value: any, record: ILockDetailData, index: number) =>
        typeof value === "number" ? (value === 255 ? "charging" : value + "%") : "--",
    },
    {
      title: t("lockManagementPage.columns.signalStrengthLevel"),
      dataIndex: "signalStrengthLevel",
      key: "signalStrengthLevel",
      sorter: true,
      render: (value: any, record: ILockDetailData, index: number) => {
        const curStatus = SIGNAL_STRENGTH_LEVEL.find(
          (x: ICommonSelectDataType) => x.value === value,
        );
        return t(curStatus?.label as string) || "--";
      },
    },
    {
      title: t("lockManagementPage.columns.status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (value: any, record: ILockDetailData, index: number) => {
        const curStatus = LOCK_STATUS.find((x: ICommonSelectDataType) => x.label === value);
        return (
          <CommonTag tagType={curStatus ? curStatus?.type : "default"}>
            {t(curStatus ? curStatus?.label : "offline")}
          </CommonTag>
        );
      },
    },
  ];

  const getData = useCallback(
    async (reload?: boolean) => {
      if (detailVendor?.vendorCode) {
        setIsLoading(true);
        const paramsSearch = {
          page: reload ? 0 : page - 1,
          size: pageSize,
          sortBy: sortByQuery,
          sortType: sortTypeQuery,
          search: JSON.stringify({ ...searchQueryData, source: detailVendor?.vendorCode }),
        };
        const resp = await lockServices.getListLock(paramsSearch);
        const data = resp?.data;
        if (resp?.status === 200) {
          setListData(data?.data);
        } else {
          setListData(undefined);
          notification.error({
            message: data?.message || t("commonError.oopsSystem"),
          });
        }
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailVendor?.vendorCode, page, pageSize, sortByQuery, sortTypeQuery, t, search],
  );
  useEffect(() => {
    if (tabKeyQuery === "lock-tab") {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);
  useEffect(() => {
    if (tabKeyQuery !== "lock-tab") {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKeyQuery]);

  const onFinish = (values: any) => {
    onSearch(values);
  };

  const onClear = (clearParams = {}) => {
    onSearch({ ...clearParams });
    setTimeout(() => {
      form.resetFields();
    }, 100);
  };

  const onSearch = (data: any = {}) => {
    const dataSearch = { ...data };
    let queryString = buildQueryString({
      tabKey: tabKeyQuery,
      page: DEFAULT_PAGE_NUMBER,
      search: JSON.stringify(dataSearch),
    });
    // detect if query not change => call api
    if (queryString !== search) {
      navigate(`${componentPath}/detail/${vendorId?.id}${queryString}`);
    } else {
      getData();
    }
  };

  const onPageChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
    extra: TableCurrentDataSource<ILockDetailData>,
  ) => {
    let queryString = buildQueryString({
      ...params,
      page: pageSize === pagination?.pageSize ? pagination?.current : DEFAULT_PAGE_NUMBER,
      pageSize: pagination?.pageSize,
      sortBy: sorter?.order
        ? sorter?.field === "signalStrengthLevel"
          ? "signalStrength"
          : sorter?.field
        : "",
      sortType: sorter?.order ? (sorter?.order === "ascend" ? "asc" : "desc") : "",
    });
    navigate(`${componentPath}/detail/${parseInt(vendorId?.id as string)}${queryString || ""}`);
  };

  // xuất excel
  const handleExport = async () => {
    if (!isLoadingExport) {
      setIsLoadingExport(true);
      const paramsSearch = {
        page: 0,
        size: 10000,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: JSON.stringify({
          ...searchQueryData,
          source: detailVendor?.vendorCode,
          exportType: 2,
        }),
      };
      const resp = await lockServices.exportLock(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        const fileName = `ListLockManagement_Data_Export_${moment().format(
          "YYYYMMDD",
        )}_${moment().unix()}.xlsx`;
        saveAs(data, fileName);
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoadingExport(false);
    }
  };

  return (
    <div className="vendor-info-tab">
      <div className="info-left">
        <CommonForm
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            username: searchQueryData?.username,
            name: searchQueryData?.name,
            group: searchQueryData?.group,
            status: searchQueryData?.status,
          }}
        >
          <Row gutter={30}>
            <Col span={24}>
              <CommonFormItem
                name="username"
                label={t("lockManagementPage.label.uniqueid")}
                placeholder={t("lockManagementPage.placeholder.uniqueid") as string}
              />
            </Col>
            <Col span={24}>
              <CommonFormItem
                name="batteryLevel"
                label={t("lockManagementPage.label.batteryLevel")}
                placeholder={t("lockManagementPage.placeholder.batteryLevel") as string}
                options={BATTERY_LEVEL.map((x) => ({ ...x, label: t(x.label) }))}
                type="select"
              />
            </Col>
            <Col span={24}>
              <CommonFormItem
                name="signalStrengthLevel"
                label={t("lockManagementPage.label.signalStrengthLevel")}
                placeholder={t("lockManagementPage.placeholder.signalStrengthLevel") as string}
                options={SIGNAL_STRENGTH_LEVEL.map((x) => ({ ...x, label: t(x.label) }))}
                type="select"
              />
            </Col>
            <Col span={24}>
              <CommonFormItem
                name="status"
                label={t("lockManagementPage.label.status")}
                placeholder={t("lockManagementPage.placeholder.status") as string}
                options={LOCK_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
                type="select"
              />
            </Col>
          </Row>

          <Space className="form-btn-container">
            <CommonButton btnType="default" size={"small"} onClick={() => onClear({})}>
              {t("common.button.deleteCondition")}
            </CommonButton>
            <CommonButton btnType="primary" size={"small"} htmlType="submit">
              {t("common.button.search")}
            </CommonButton>
          </Space>
        </CommonForm>
      </div>
      <div className="info-right">
        {isCanExport && (
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "0.5rem" }}>
            <CommonButton
              size={"small"}
              btnType="success"
              onClick={handleExport}
              loading={isLoadingExport}
              htmlType="button"
            >
              {t("common.button.exportExcel")}
            </CommonButton>
          </div>
        )}
        <CommonTable
          isLoading={isLoading}
          rowKey={"id"}
          onChange={onPageChange}
          dataSource={listData?.content || []}
          columns={columns}
          data={listData}
          scroll={{ y: "20.833vw" }}
          defaultSorter={{
            order: sortTypeQuery,
            field: sortByQuery === "signalStrength" ? "signalStrengthLevel" : sortByQuery,
          }}
        />
      </div>
    </div>
  );
}

export default LockTab;
