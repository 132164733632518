import { Col, Form, PaginationProps, Row, Space, notification } from "antd";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CommonEmpty from "../../components/Common/Empty";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonInput from "../../components/Common/Input";
import CommonPagination from "../../components/Common/Pagination";
import MapComponent from "../../components/Map";
import { ReactComponent as SearchIcon } from "../../resources/images/search-1.svg";
import journeyServices from "../../services/journeyManagement.service";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, ROLE_LIST, SEV_CODE } from "../../utils/constans";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import { useQuery } from "../../utils/customHooks";
import { ICommonResponsePaging, IUseQueryResponse } from "../../utils/types";
import { IJourneyDetailData } from "../../utils/types/journeyManagement";
import { buildQueryString, isHaveAnyPermission } from "../../utils/utilFunctions";
import CommonButton from "./../../components/Common/Button";
import CommonSpin from "./../../components/Common/Spin";
import OperateControl from "./OperateControl";

function Operate() {
  const componentPath = SIDEBAR_ITEM_HREF.operate;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const isFirstLoading = useRef<boolean>(true);
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const { page: pageQuery, pageSize: pageSizeQuery, search: searchQuery } = params;
  const page = pageQuery ? parseFloat(pageQuery) : DEFAULT_PAGE_NUMBER;
  const pageSize = pageSizeQuery ? parseFloat(pageSizeQuery) : DEFAULT_PAGE_SIZE;
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};
  const [listData, setListData] = useState<ICommonResponsePaging<IJourneyDetailData>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentJourney, setCurrentJourney] = useState<IJourneyDetailData>();

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isSEV = profile?.source === SEV_CODE;
  const isCanViewDetail = isHaveAnyPermission(
    [
      ROLE_LIST.QLHT_EDIT,
      ROLE_LIST.QLHT_EDIT_POSITION,
      ROLE_LIST.QLHT_VIEW_DETAIL_CAR,
      ROLE_LIST.QLHT_VIEW_DETAIL_INFO_DELI,
      ROLE_LIST.QLHT_VIEW_DETAIL_ACTION_AND_VIO,
    ],
    profile,
  );

  const showTotal: PaginationProps["showTotal"] = (total) =>
    t("common.paginationWithTotal", { total });

  useEffect(() => {
    isFirstLoading.current = true;
    getData();
    const interVal = setInterval(getData, 22000);
    return () => {
      clearInterval(interVal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getData = async (reload?: boolean) => {
    setIsLoading(true);
    const paramsSearch = {
      page: reload ? 0 : page - 1,
      size: pageSize,
      search: isSEV
        ? JSON.stringify({ ...searchQueryData, statusStr: "DA_KHOI_HANH", getLockInfo: 1 })
        : JSON.stringify({
            ...searchQueryData,
            statusStr: "DA_KHOI_HANH",
            getLockInfo: 1,
            source: profile?.source,
          }),
    };
    const resp = await journeyServices.getListJourney(paramsSearch);
    const data = resp?.data;
    if (resp?.status === 200) {
      setListData(data?.data);
    } else {
      setListData(undefined);
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
    isFirstLoading.current = false;
  };

  const onValuesChange = _.debounce((values: any) => {
    setCurrentJourney(undefined);
    onSearch(values);
  }, 200);

  const onSearch = (data: any = {}) => {
    const dataSearch = { ...data };
    let queryString = buildQueryString({
      page: DEFAULT_PAGE_NUMBER,
      search: JSON.stringify(dataSearch),
    });
    // detect if query not change => call api
    if (queryString !== search) {
      navigate(`${componentPath}${queryString}`);
    } else {
      getData();
    }
  };

  const onChangePage = (page: number, pageSize: number) => {
    let queryString = buildQueryString({
      ...params,
      page: page,
      pageSize: pageSize,
    });
    navigate(`${queryString || ""}`);
  };

  return (
    <div className="operate-wrapper content-box">
      <div className="list-operate">
        <Row>
          <Col span={24}>
            <CommonForm
              form={form}
              onValuesChange={onValuesChange}
              layout="horizontal"
              initialValues={{
                keyword: searchQueryData?.keyword,
              }}
            >
              <CommonFormItem name="keyword">
                <CommonInput
                  placeholder={t("journeyManagementPage.placeholder.advancedSearch") as string}
                  prefix={<SearchIcon className="prefix-icon" />}
                  allowClear
                />
              </CommonFormItem>
            </CommonForm>
          </Col>
        </Row>
        {isLoading && isFirstLoading?.current ? (
          <CommonSpin isLoading={isLoading}></CommonSpin>
        ) : listData?.totalElements ? (
          <>
            <Row className="list-item">
              {listData?.content?.map((item: IJourneyDetailData, index: number) => {
                const dongLapLung =
                  item?.gpsInfo?.donglaplung === 1
                    ? t("lockManagementPage.lockStatus.closeBackCover")
                    : item?.gpsInfo?.donglaplung === 0
                    ? t("lockManagementPage.lockStatus.openBackCover")
                    : "";
                const dongdaycap =
                  item?.gpsInfo?.dongdaycap === 1
                    ? t("lockManagementPage.lockStatus.closeZipper")
                    : item?.gpsInfo?.dongdaycap === 0
                    ? t("lockManagementPage.lockStatus.openZipper")
                    : "";
                const cutcable = item?.gpsInfo?.cutcable
                  ? t("lockManagementPage.lockStatus.brokenLockWire")
                  : "";
                const curStatusLock = [dongLapLung, dongdaycap, cutcable]
                  ?.filter((item) => item)
                  ?.join(", ");
                return (
                  <Col span={12} key={item?.id}>
                    <div className="operate-item">
                      <div className="item-index">{index + 1}</div>
                      <div className="item-content">
                        <Row>
                          <Col span={12} className="label">
                            {t("journeyManagementPage.label.plateNumber")}:
                          </Col>
                          <Col span={12}>{item?.plateNumber || "--"}</Col>
                        </Row>
                        <Row>
                          <Col span={12} className="label">
                            {t("journeyManagementPage.label.lockCount")}:
                          </Col>
                          <Col span={12}>{item?.registerCarLocks?.length || "0"}</Col>
                        </Row>
                        <Row>
                          <Col span={12} className="label">
                            {t("journeyManagementPage.label.driverName")}:
                          </Col>
                          <Col span={12}>{item?.driverName || "--"}</Col>
                        </Row>
                        <Row>
                          <Col span={12} className="label">
                            {t("journeyManagementPage.label.driverIdentity")}:
                          </Col>
                          <Col span={12}>{item?.driverIdentity || "--"}</Col>
                        </Row>
                        <Row>
                          <Col span={12} className="label">
                            {t("journeyManagementPage.label.statusLock")}:
                          </Col>
                          <Col span={12}>{curStatusLock || "--"}</Col>
                        </Row>
                      </div>
                      <Space>
                        {isCanViewDetail && (
                          <Link to={`${SIDEBAR_ITEM_HREF.journey_management}/detail/${item?.id}`}>
                            <CommonButton btnType="primary" size={"small"}>
                              {t("common.button.detail")}
                            </CommonButton>
                          </Link>
                        )}
                        <CommonButton
                          btnType="default"
                          size={"small"}
                          onClick={() => setCurrentJourney(item)}
                        >
                          {t("common.button.viewLocation")}
                        </CommonButton>
                      </Space>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <div className="operate-pagination">
              <CommonPagination
                defaultCurrent={1}
                total={listData?.totalElements}
                current={page}
                pageSize={pageSize}
                showTotal={showTotal}
                showSizeChanger={true}
                onChange={onChangePage}
              />
            </div>
          </>
        ) : (
          <CommonEmpty />
        )}
      </div>
      <MapComponent isShowCurrentBtn={false}>
        <OperateControl
          listJourney={listData?.content || []}
          currentJourney={currentJourney}
          setCurrentJourney={setCurrentJourney}
        />
      </MapComponent>
    </div>
  );
}

export default Operate;
