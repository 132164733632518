import { Space, TableColumnsType, TablePaginationConfig, notification } from "antd";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";
import { saveAs } from "file-saver";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonConfirmModal from "../../components/Common/ConfirmModal";
import CommonTable from "../../components/Common/Table";
import CommonTag from "../../components/Common/Tag";
import lockServices from "../../services/lockManagement.service";
import {
  DATE_TIME_FORMAT_SECOND,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  ROLE_LIST,
  SEV_CODE,
} from "../../utils/constans";
import { LOCK_STATUS, SIGNAL_STRENGTH_LEVEL } from "../../utils/constans/lockManagement";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import { useQuery } from "../../utils/customHooks";
import { ICommonResponsePaging, ICommonSelectDataType, IUseQueryResponse } from "../../utils/types";
import { ILockDetailData } from "../../utils/types/lockManagement";
import { buildQueryString, isHaveAnyPermission, isHavePermission } from "../../utils/utilFunctions";
import LockCreateModal from "./LockCreateModal";
import SearchBox from "./SearchBox";

function LockManagement() {
  const componentPath = SIDEBAR_ITEM_HREF.lock_management;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const {
    page: pageQuery,
    pageSize: pageSizeQuery,
    sortBy: sortByQuery,
    sortType: sortTypeQuery,
    search: searchQuery,
  } = params;
  const page = pageQuery ? parseFloat(pageQuery) : DEFAULT_PAGE_NUMBER;
  const pageSize = pageSizeQuery ? parseFloat(pageSizeQuery) : DEFAULT_PAGE_SIZE;
  const [data, setData] = useState<ICommonResponsePaging<ILockDetailData>>();
  const [dataSelected, setDataSelected] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [visibleAdd, setVisibleAdd] = useState<boolean>(false);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isSEV = profile?.source === SEV_CODE;
  const isCanCreate = isHavePermission(ROLE_LIST.MANAGER_LOCK_CREATE, profile);
  const isCanExport = isHavePermission(ROLE_LIST.MANAGER_LOCK_EXPORT, profile);
  const isCanDelete = isHavePermission(ROLE_LIST.MANAGER_LOCK_DELETE, profile);
  const isCanViewDetail = isHaveAnyPermission(
    [
      ROLE_LIST.MANAGER_LOCK_DETAIL_INFO,
      ROLE_LIST.MANAGER_LOCK_DETAIL_HISTORY_USE,
      ROLE_LIST.MANAGER_LOCK_DETAIL_ACC_VENDOR_USE,
    ],
    profile,
  );

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getData = async (reload?: boolean) => {
    setIsLoading(true);
    const paramsSearch = {
      page: reload ? 0 : page - 1,
      size: pageSize,
      sortBy: sortByQuery,
      sortType: sortTypeQuery,
      search: isSEV ? searchQuery : JSON.stringify({ ...searchQueryData, source: profile?.source }),
    };
    const resp = await lockServices.getListLock(paramsSearch);
    const data = resp?.data;
    if (resp?.status === 200) {
      setData(data?.data);
    } else {
      setData(undefined);
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  };

  const onPageChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
    extra: TableCurrentDataSource<ILockDetailData>,
  ) => {
    let queryString = buildQueryString({
      ...params,
      page: pageSize === pagination?.pageSize ? pagination?.current : DEFAULT_PAGE_NUMBER,
      pageSize: pagination?.pageSize,
      sortBy: sorter?.order
        ? sorter?.field === "sourceStr"
          ? "source"
          : sorter?.field === "signalStrengthLevel"
          ? "signalStrength"
          : sorter?.field
        : "",
      sortType: sorter?.order ? (sorter?.order === "ascend" ? "asc" : "desc") : "",
    });
    navigate(`${componentPath}${queryString || ""}`);
  };

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: number[], selectedRows: ILockDetailData[]) => {
      setDataSelected(selectedRowKeys);
    },
  };

  const columns: TableColumnsType<ILockDetailData> = [
    {
      title: t("lockManagementPage.columns.STT"),
      dataIndex: "index",
      key: "index",
      width: "6%",
      align: "center",
      render: (value: any, record: ILockDetailData, index: number) =>
        (page - 1) * pageSize + index + 1,
    },
    {
      title: t("lockManagementPage.columns.uniqueid"),
      dataIndex: "uniqueid",
      key: "uniqueid",
      sorter: true,
      render: (value: any, record: ILockDetailData, index: number) => {
        return isCanViewDetail ? (
          <Link to={`${componentPath}/detail/${record?.id}`}>
            <div className="link">{value || "--"}</div>
          </Link>
        ) : (
          value || "--"
        );
      },
    },
    {
      title: t("lockManagementPage.columns.source"),
      dataIndex: "sourceStr",
      key: "sourceStr",
      sorter: true,
      hidden: !isSEV,
      render: (value: any, record: ILockDetailData, index: number) => value || "--",
    },
    {
      title: t("lockManagementPage.columns.countUse"),
      dataIndex: "countUse",
      key: "countUse",
      sorter: true,
      render: (value: any, record: ILockDetailData, index: number) =>
        typeof value === "number" ? value : "--",
    },
    {
      title: t("lockManagementPage.columns.battery"),
      dataIndex: "battery",
      key: "battery",
      sorter: true,
      render: (value: any, record: ILockDetailData, index: number) =>
        typeof value === "number" ? (value === 255 ? "charging" : value + "%") : "--",
    },
    {
      title: t("lockManagementPage.columns.signalStrengthLevel"),
      dataIndex: "signalStrengthLevel",
      key: "signalStrengthLevel",
      sorter: true,
      render: (value: any, record: ILockDetailData, index: number) => {
        const curStatus = SIGNAL_STRENGTH_LEVEL.find(
          (x: ICommonSelectDataType) => x.value === value,
        );
        return t(curStatus?.label as string) || "--";
      },
    },
    {
      title: t("lockManagementPage.columns.status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (value: any, record: ILockDetailData, index: number) => {
        const curStatus = LOCK_STATUS.find((x: ICommonSelectDataType) => x.label === value);
        return (
          <CommonTag tagType={curStatus ? curStatus?.type : "default"}>
            {t(curStatus ? curStatus?.label : "offline")}
          </CommonTag>
        );
      },
    },
    {
      title: t("lockManagementPage.columns.lastOnline"),
      dataIndex: "lastOnline",
      key: "lastOnline",
      sorter: true,
      render: (value: any, record: ILockDetailData, index: number) => {
        return value ? moment(value).format(DATE_TIME_FORMAT_SECOND) : "--";
      },
    },
  ];

  const handleOk = () => {
    onClear();
    let queryString = buildQueryString({
      ...params,
      page: DEFAULT_PAGE_NUMBER,
      search: JSON.stringify({}),
    });
    if (queryString !== search) {
      navigate(`${queryString || ""}`);
    } else {
      getData(true);
    }
  };

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    const resp = await lockServices.deleteLocks(dataSelected || []);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("lockManagementPage.message.deleteSuccess"),
      });
      handleOk();
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoadingDelete(false);
  };

  const onClear = () => {
    setVisibleAdd(false);
    setDataSelected([]);
    setVisibleDelete(false);
  };

  // xuất excel
  const handleExport = async () => {
    if (!isLoadingExport) {
      setIsLoadingExport(true);
      const paramsSearch = {
        page: 0,
        size: 10000,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: isSEV
          ? JSON.stringify({ ...searchQueryData, exportType: 1 })
          : JSON.stringify({ ...searchQueryData, source: profile?.source, exportType: 1 }),
      };
      const resp = await lockServices.exportLock(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        const fileName = `LockManagement_Data_Export_${moment().format(
          "YYYYMMDD",
        )}_${moment().unix()}.xlsx`;
        saveAs(data, fileName);
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoadingExport(false);
    }
  };

  return (
    <div className="content-wrapper">
      <SearchBox getData={getData} componentPath={componentPath} />

      <div className="avic-table-top">
        <div className="avic-table-top-title">{t("lockManagementPage.title")}</div>
        <Space className="avic-table-top-right">
          {isSEV ? (
            <>
              {dataSelected?.length > 0 && isCanDelete && (
                <CommonButton
                  btnType="danger"
                  size={"small"}
                  onClick={() => setVisibleDelete(true)}
                >
                  {t("common.button.deleteLock")}
                </CommonButton>
              )}
              {isCanCreate && (
                <CommonButton btnType="primary" size={"small"} onClick={() => setVisibleAdd(true)}>
                  {t("common.button.addLock")}
                </CommonButton>
              )}
            </>
          ) : (
            <></>
          )}
          {isCanExport && (
            <CommonButton
              btnType="success"
              size={"small"}
              loading={isLoadingExport}
              onClick={handleExport}
            >
              {t("common.button.exportExcel")}
            </CommonButton>
          )}
        </Space>
      </div>

      <CommonTable
        isLoading={isLoading}
        rowKey={"id"}
        dataSource={data?.content || []}
        columns={columns}
        data={data}
        onChange={onPageChange}
        rowSelection={
          isSEV && isCanDelete ? { ...rowSelection, selectedRowKeys: dataSelected } : null
        }
        defaultSorter={{
          order: sortTypeQuery,
          field:
            sortByQuery === "source"
              ? "sourceStr"
              : sortByQuery === "signalStrength"
              ? "signalStrengthLevel"
              : sortByQuery,
        }}
      />

      {visibleAdd ? (
        <LockCreateModal
          visible={visibleAdd}
          handleOk={handleOk}
          handleCancel={() => {
            setVisibleAdd(false);
          }}
        />
      ) : (
        <></>
      )}
      {visibleDelete ? (
        <CommonConfirmModal
          onCancel={() => {
            setVisibleDelete(false);
          }}
          content={
            <>
              <div style={{ textAlign: "center", marginBottom: "0.5rem" }}>
                {/* <div>IMEI khóa: 12312411723</div>
                <div>Vendor quản lý khóa: Công gty ABC</div> */}
                {t("lockManagementPage.message.delete")}
              </div>
              <div style={{ color: "#d83939" }}>
                <div>{t("lockManagementPage.message.warningTitle")}</div>
                <div>{t("lockManagementPage.message.warningContent")}</div>
              </div>
            </>
          }
          visible={visibleDelete}
          onOk={handleDelete}
          loadingBtnOk={isLoadingDelete}
          title={t("lockManagementPage.deleteTitle") as string}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default LockManagement;
