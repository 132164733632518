// table drop row
import { MenuOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type { ColumnsType } from "antd/es/table";
// table drop row

import { Col, Form, notification, Row, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonConfirmModal from "../../components/Common/ConfirmModal";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonSelect from "../../components/Common/Select";
import CommonTable from "../../components/Common/Table";
import { ReactComponent as DeleteIcon } from "../../resources/images/delete-red.svg";
import { ReactComponent as AddIcon } from "../../resources/images/plus-blue.svg";
import journeyServices from "../../services/journeyManagement.service";
import positionServices from "../../services/locationCategory.service";
import vehicleTypeServices from "../../services/vehicleTypeCategory.service";
import {
  REGEX_CODE,
  REGEX_NUMBER_GREATER_ZERO,
  REGEX_ONLY_NUMBER,
  SEV_CODE,
} from "../../utils/constans";
import { OPTION_DELIVERY } from "../../utils/constans/journeyManagement";
import { LOCATION_VALUES } from "../../utils/constans/locationCategory";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import { ICompanyDeliverySitesAddData } from "../../utils/types/journeyManagement";
import { ILocationDetailData } from "../../utils/types/locationCategory";
import { IVehicleTypeDetailData } from "../../utils/types/vehicleType";

// table drop row

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

function JourneyAddForm() {
  const componentPath = SIDEBAR_ITEM_HREF.journey_management;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const naviagte = useNavigate();
  const [dataSource, setDataSource] = useState<ICompanyDeliverySitesAddData[]>([]);
  const [itemDeleteId, setItemDeleteId] = useState<number | undefined>(undefined);
  const [visible, setVisible] = useState<boolean>(false);
  const [positionSelectData, setPositionSelectData] = useState<ILocationDetailData[]>([]);
  const [vehicleTypeSelectData, setVehicleTypeSelectData] = useState<IVehicleTypeDetailData[]>([]);
  const [checkDuplicate, setCheckDuplicate] = useState<any[]>([]);
  const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isSEV = profile?.source === SEV_CODE;

  useEffect(() => {
    if (!isSEV) {
      form.setFieldValue(
        "categoryCar",
        vehicleTypeSelectData?.find((item) => item?.type === 2)?.id,
      );
    }
  }, [form, isSEV, vehicleTypeSelectData]);

  const RowTable = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: props["data-row-key"] });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === "sort") {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: "none", cursor: "move" }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const getSelectData = useCallback(async () => {
    const paramsSearch = {
      page: 0,
      size: 1000,
      search: JSON.stringify({ status: 0 }),
    };
    if (isSEV) {
      //select vị trí
      const respPosition = await positionServices.getPosition(paramsSearch);
      const dataPosition = respPosition?.data;
      if (respPosition?.status === 200) {
        setPositionSelectData(dataPosition?.data?.content);
      } else {
        setPositionSelectData([]);
      }
    }

    //select Loại xe
    const respVehicleType = await vehicleTypeServices.getListVehicleType(paramsSearch);
    const dataVehicleType = respVehicleType?.data;
    if (respVehicleType?.status === 200) {
      setVehicleTypeSelectData(dataVehicleType?.data?.content);
    } else {
      setVehicleTypeSelectData([]);
    }
  }, [isSEV]);

  useEffect(() => {
    getSelectData();
  }, [getSelectData]);

  // check duplicate vị trí kho
  useEffect(() => {
    if (dataSource.length > 1) {
      const duplicateData = dataSource?.filter((item: any, index: number) => {
        if (index === 0) {
          return item?.name && item?.name === dataSource[1]?.name;
        } else if (index === dataSource.length - 1) {
          return item?.name && item?.name === dataSource[dataSource.length - 2].name;
        } else {
          return (
            item?.name &&
            (item.name === dataSource[index + 1].name || item.name === dataSource[index - 1].name)
          );
        }
      });
      setCheckDuplicate(duplicateData?.map((item: any) => item.key));
    } else {
      setCheckDuplicate([]);
    }
  }, [dataSource, t]);

  const onDeleteRow = () => {
    setLoadingConfirm(true);
    setDataSource((prevData: ICompanyDeliverySitesAddData[]) => {
      const newDataSource = prevData.filter(
        (item: ICompanyDeliverySitesAddData) => item.index !== itemDeleteId,
      );
      return newDataSource;
    });
    setVisible(false);
    setLoadingConfirm(false);
  };

  const onFinish = async (values: any) => {
    setIsLoadingCreate(true);
    let dataSubmit = {};
    if (isSEV) {
      if (dataSource?.length === 0) {
        notification.error({
          message: t("journeyManagementPage.validate.deliveryRequired"),
        });
        setIsLoadingCreate(false);
        return;
      }
      if (dataSource?.find((item: any) => item.name === "" || item.name === undefined)) {
        notification.error({
          message: t("journeyManagementPage.validate.deliveryWhiteSpace"),
        });
        setIsLoadingCreate(false);
        return;
      }
      if (checkDuplicate.length > 0) {
        notification.error({
          message: t("journeyManagementPage.validate.deliveryDuplicate"),
        });
        setIsLoadingCreate(false);
        return;
      }
      dataSubmit = {
        ...values,
        categoryCar: { id: values?.categoryCar },
        source: profile.source,
        companyDeliverySites: [
          { categoryPosition: { id: values?.gateIn }, orderNumber: 0 },
          ...dataSource?.map((item: any, index: number) => ({
            categoryPosition: { id: item.name },
            orderNumber: index + 1,
          })),
          { categoryPosition: { id: values?.gateOut }, orderNumber: 10000 },
        ],
      };
    } else {
      dataSubmit = {
        ...values,
        categoryCar: { id: vehicleTypeSelectData?.find((item) => item?.type === 2)?.id },
        source: profile.source,
      };
    }
    const resp = await journeyServices.createJourney(dataSubmit);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t(
          isSEV
            ? "journeyManagementPage.message.createJourneySuccess"
            : "journeyManagementPage.message.registerJourneySuccess",
        ),
      });
      naviagte(componentPath);
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoadingCreate(false);
  };

  // Thêm dòng
  const handleAddNewRow = () => {
    const newData: ICompanyDeliverySitesAddData = {
      key: (dataSource?.length + 1).toString(),
      index: dataSource?.length + 1,
      name: "",
      disabled: false,
    };
    setDataSource([...dataSource, newData]);
  };

  //Sort row
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const columns: ColumnsType<ICompanyDeliverySitesAddData> = [
    {
      key: "sort",
      width: "5%",
    },
    {
      title: t("journeyManagementPage.columns.ordernumber"),
      key: "index",
      align: "center",
      width: "15%",
      render: (value: any, record: ICompanyDeliverySitesAddData, index: number) => index + 1,
    },
    {
      title: t("journeyManagementPage.columns.name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (value: any, record: ICompanyDeliverySitesAddData, index: number) => {
        return (
          <div
            className={`row-table ${
              !value || checkDuplicate?.includes(record.key) ? "dangerous" : ""
            }`}
          >
            <CommonSelect
              style={{ width: "60%", textAlign: "left" }}
              disabled={record?.disabled}
              options={positionSelectData
                ?.filter((item: ILocationDetailData) => item?.type === LOCATION_VALUES.WAREHOUSE)
                ?.map((item: ILocationDetailData) => ({
                  value: item.id,
                  label: item.name + " - " + item.address,
                }))}
              placeholder={t("journeyManagementPage.placeholder.name")}
              onChange={(values) => {
                setDataSource((prevState) => {
                  const newData = [...prevState];
                  newData[index].name = values;
                  return newData;
                });
              }}
              value={value || undefined}
              allowClear
              showSearch
            />
          </div>
        );
      },
    },
    {
      key: "action",
      align: "center",
      width: "10%",
      render: (value: any, record: ICompanyDeliverySitesAddData, index: number) => {
        if (record?.disabled) {
          return <DeleteIcon className="delete-icon" style={{ cursor: "not-allowed" }} />;
        }
        return (
          <DeleteIcon
            className="delete-icon"
            onClick={() => {
              setVisible(true);
              setItemDeleteId(record.index);
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="box-wrapper">
      <CommonForm form={form} onFinish={onFinish} layout="vertical">
        <div className="avic-search-box">
          <div className="advance-search-box journey-box">
            <div className="form-title">{t("journeyManagementPage.registerTitle")}</div>
            <Row gutter={30}>
              {isSEV ? (
                <>
                  <Col span={12}>
                    <CommonFormItem
                      name="companyName"
                      label={t("journeyManagementPage.label.companyName")}
                      placeholder={t("journeyManagementPage.placeholder.companyName") as string}
                      showRequiredIcon
                      maxLength={128}
                      rules={[
                        {
                          required: true,
                          message: `${t("validate.input")} ${t(
                            "journeyManagementPage.label.companyName",
                          )}.`,
                        },
                      ]}
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="categoryCar"
                      label={t("journeyManagementPage.label.vehicleType")}
                      placeholder={t("journeyManagementPage.placeholder.vehicleType") as string}
                      options={vehicleTypeSelectData.map((item: IVehicleTypeDetailData) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      type="select"
                      showRequiredIcon={isSEV}
                      disabled={!isSEV}
                      rules={[
                        {
                          required: true,
                          message: `${t("validate.select")} ${t(
                            "journeyManagementPage.label.vehicleType",
                          )}.`,
                        },
                      ]}
                    />
                  </Col>
                </>
              ) : (
                <></>
              )}
              <Col span={12}>
                <CommonFormItem
                  name="plateNumber"
                  label={t("journeyManagementPage.label.plateNumber")}
                  placeholder={t("journeyManagementPage.placeholder.plateNumber") as string}
                  showRequiredIcon
                  rules={[
                    {
                      whiteSpace: true,
                      required: true,
                      message: `${t("validate.input")} ${t(
                        "journeyManagementPage.label.plateNumber",
                      )}.`,
                    },
                    {
                      pattern: REGEX_CODE,
                      message: t("journeyManagementPage.validate.plateNumber"),
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="optionDelivery"
                  label={t("journeyManagementPage.label.optionDelivery")}
                  placeholder={t("journeyManagementPage.placeholder.optionDelivery") as string}
                  showRequiredIcon
                  options={OPTION_DELIVERY?.map((x) => ({ ...x, label: t(x?.label) }))}
                  type="select"
                  rules={[
                    {
                      required: true,
                      message: `${t("validate.select")} ${t(
                        "journeyManagementPage.label.optionDelivery",
                      )}.`,
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="driverName"
                  label={t("journeyManagementPage.label.driverName")}
                  placeholder={t("journeyManagementPage.placeholder.driverName") as string}
                  showRequiredIcon
                  maxLength={128}
                  rules={[
                    {
                      whiteSpace: true,
                      required: true,
                      message: `${t("validate.input")} ${t(
                        "journeyManagementPage.label.driverName",
                      )}.`,
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="driverIdentity"
                  label={t("journeyManagementPage.label.driverIdentity")}
                  placeholder={t("journeyManagementPage.placeholder.driverIdentity") as string}
                  showRequiredIcon
                  maxLength={12}
                  rules={[
                    {
                      whiteSpace: true,
                      required: true,
                      message: `${t("validate.input")} ${t(
                        "journeyManagementPage.label.driverIdentity",
                      )}.`,
                    },
                    {
                      pattern: REGEX_ONLY_NUMBER,
                      message: `${t("journeyManagementPage.label.driverIdentity")} ${t(
                        "validate.onlyNumber",
                      )}.`,
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="totalDoor"
                  label={t("journeyManagementPage.label.totalDoor")}
                  placeholder={t("journeyManagementPage.placeholder.totalDoor") as string}
                  showRequiredIcon
                  maxLength={128}
                  rules={[
                    {
                      whiteSpace: true,
                      required: true,
                      message: `${t("validate.input")} ${t(
                        "journeyManagementPage.label.totalDoor",
                      )}.`,
                    },
                    {
                      pattern: REGEX_NUMBER_GREATER_ZERO,
                      message: `${t("journeyManagementPage.label.totalDoor")} ${t(
                        "validate.greaterThanZore",
                      )}.`,
                    },
                  ]}
                />
              </Col>
            </Row>
          </div>
        </div>

        {isSEV ? (
          <div className="avic-search-box">
            <div className="advance-search-box journey-box">
              <div className="form-title">{t("journeyManagementPage.deliveryTitle")}</div>
              <Row gutter={30}>
                <Col span={12}>
                  <CommonFormItem
                    name="gateIn"
                    label={t("journeyManagementPage.label.gateIn")}
                    placeholder={t("journeyManagementPage.placeholder.gateIn") as string}
                    options={positionSelectData
                      ?.filter(
                        (item: ILocationDetailData) => item?.type === LOCATION_VALUES.GATE_IN,
                      )
                      ?.map((item: ILocationDetailData) => ({
                        value: item.id,
                        label: item.name + " - " + item.address,
                      }))}
                    type="select"
                    showRequiredIcon
                    rules={[
                      {
                        required: true,
                        message: `${t("validate.select")} ${t(
                          "journeyManagementPage.label.gateIn",
                        )}.`,
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <CommonFormItem
                    name="gateOut"
                    label={t("journeyManagementPage.label.gateOut")}
                    placeholder={t("journeyManagementPage.placeholder.gateOut") as string}
                    options={positionSelectData
                      ?.filter((item: any) => item.type === LOCATION_VALUES.GATE_OUT)
                      ?.map((item: any) => ({
                        value: item.id,
                        label: item.name + " - " + item.address,
                      }))}
                    type="select"
                    showRequiredIcon
                    rules={[
                      {
                        required: true,
                        message: `${t("validate.select")} ${t(
                          "journeyManagementPage.label.gateOut",
                        )}.`,
                      },
                    ]}
                  />
                </Col>
                <Col span={24} className="mgt-10">
                  <DndContext onDragEnd={onDragEnd}>
                    <SortableContext
                      // rowKey array
                      items={dataSource.map((i) => i.key)}
                      strategy={verticalListSortingStrategy}
                    >
                      <CommonTable
                        components={{
                          body: {
                            row: RowTable,
                          },
                        }}
                        scroll={{ y: "20.833vw" }}
                        pagination={false}
                        rowKey="key"
                        columns={columns}
                        dataSource={dataSource}
                      />
                    </SortableContext>
                  </DndContext>
                </Col>
              </Row>
              <AddIcon className="add-icon" onClick={handleAddNewRow} />
            </div>
          </div>
        ) : (
          <></>
        )}
        <Space className="form-btn-container" style={{ justifyContent: "center" }}>
          <Link to={componentPath}>
            <CommonButton btnType="default" size={"large"}>
              {t("common.button.cancel")}
            </CommonButton>
          </Link>
          <CommonButton
            btnType="primary"
            size={"large"}
            htmlType="submit"
            loading={isLoadingCreate}
          >
            {t("common.button.register")}
          </CommonButton>
        </Space>
      </CommonForm>

      <CommonConfirmModal
        onCancel={() => {
          setVisible(false);
        }}
        content={t("journeyManagementPage.message.deleteDelivery")}
        visible={visible}
        onOk={onDeleteRow}
        loadingBtnOk={loadingConfirm}
      />
    </div>
  );
}

export default JourneyAddForm;
