import { Space, TablePaginationConfig, notification } from "antd";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";
import { saveAs } from "file-saver";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonConfirmModal from "../../components/Common/ConfirmModal";
import CommonTable from "../../components/Common/Table";
import CommonTag from "../../components/Common/Tag";
import journeyServices from "../../services/journeyManagement.service";
import {
  DATE_TIME_FORMAT_SECOND,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  ROLE_LIST,
  SEV_CODE,
} from "../../utils/constans";
import {
  JOURNEY_STATUS_OPTIONS_TABLE,
  JOURNEY_STATUS_VALUES,
  OPTION_DELIVERY,
} from "../../utils/constans/journeyManagement";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import { useQuery } from "../../utils/customHooks";
import { ICommonResponsePaging, IUseQueryResponse } from "../../utils/types";
import { IJourneyDetailData } from "../../utils/types/journeyManagement";
import { buildQueryString, isHaveAnyPermission, isHavePermission } from "../../utils/utilFunctions";
import SearchBox from "./SearchBox";

function JourneyManagement() {
  const componentPath = SIDEBAR_ITEM_HREF.journey_management;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const {
    page: pageQuery,
    pageSize: pageSizeQuery,
    sortBy: sortByQuery,
    sortType: sortTypeQuery,
    search: searchQuery,
  } = params;
  const page = pageQuery ? parseFloat(pageQuery) : DEFAULT_PAGE_NUMBER;
  const pageSize = pageSizeQuery ? parseFloat(pageSizeQuery) : DEFAULT_PAGE_SIZE;
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};
  const [listData, setListData] = useState<ICommonResponsePaging<IJourneyDetailData>>();
  const [dataSelected, setDataSelected] = useState<number[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isSEV = profile?.source === SEV_CODE;
  const isCanCreate = isHavePermission(ROLE_LIST.QLHT_CREATE, profile);
  const isCanExport = isHavePermission(ROLE_LIST.QLHT_EXPORT, profile);
  const isCanDelete = isHavePermission(ROLE_LIST.QLHT_DELETE, profile);
  const isCanViewDetail = isHaveAnyPermission(
    [
      ROLE_LIST.QLHT_EDIT,
      ROLE_LIST.QLHT_EDIT_POSITION,
      ROLE_LIST.QLHT_VIEW_DETAIL_CAR,
      ROLE_LIST.QLHT_VIEW_DETAIL_INFO_DELI,
      ROLE_LIST.QLHT_VIEW_DETAIL_ACTION_AND_VIO,
    ],
    profile,
  );

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getData = async (reload?: boolean) => {
    setIsLoading(true);
    const paramsSearch = {
      page: reload ? 0 : page - 1,
      size: pageSize,
      sortBy: sortByQuery,
      sortType: sortTypeQuery,
      search: isSEV ? searchQuery : JSON.stringify({ ...searchQueryData, source: profile?.source }),
    };
    const resp = await journeyServices.getListJourney(paramsSearch);
    const data = resp?.data;
    if (resp?.status === 200) {
      setListData(data?.data);
    } else {
      setListData(undefined);
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  };

  const onPageChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
    extra: TableCurrentDataSource<IJourneyDetailData>,
  ) => {
    let queryString = buildQueryString({
      ...params,
      page: pageSize === pagination?.pageSize ? pagination?.current : DEFAULT_PAGE_NUMBER,
      pageSize: pagination?.pageSize,
      sortBy: sorter?.order ? (sorter?.field === "statusStr" ? "status" : sorter?.field) : "",
      sortType: sorter?.order ? (sorter?.order === "ascend" ? "asc" : "desc") : "",
    });
    navigate(`${componentPath}${queryString || ""}`);
  };

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: number[], selectedRows: IJourneyDetailData[]) => {
      setDataSelected(selectedRowKeys);
    },
    getCheckboxProps: (record: IJourneyDetailData) => ({
      disabled: !(
        (record?.source === SEV_CODE && record.status === JOURNEY_STATUS_VALUES.CHUA_VAO_SEV) ||
        (record?.source !== SEV_CODE &&
          (record.status === JOURNEY_STATUS_VALUES.KHOI_TAO ||
            record.status === JOURNEY_STATUS_VALUES.DA_GAN_KHOA_CHUA_XAC_NHAN))
      ),
    }),
    fixed: "left",
  };

  const columns = [
    {
      title: t("journeyManagementPage.columns.STT"),
      dataIndex: "index",
      key: "index",
      width: "5%",
      fixed: "left",
      align: "center",
      render: (value: any, record: any, index: number) => (page - 1) * pageSize + index + 1,
    },
    {
      title: t("journeyManagementPage.columns.id"),
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: "6%",
      sorter: true,
      render: (value: any, record: IJourneyDetailData) => {
        return isCanViewDetail ? (
          <Link to={`${componentPath}/detail/${record?.id}`}>
            <div className="link">{value || "--"}</div>
          </Link>
        ) : (
          value || "--"
        );
      },
    },
    {
      title: t("journeyManagementPage.columns.plateNumber"),
      dataIndex: "plateNumber",
      key: "plateNumber",
      render: (value: any, record: IJourneyDetailData) => {
        return value || "--";
      },
      sorter: true,
    },
    {
      title: t("journeyManagementPage.columns.driverName"),
      dataIndex: "driverName",
      key: "driverName",
      sorter: true,
      render: (value: any, record: IJourneyDetailData) => {
        return value || "--";
      },
    },
    {
      title: t("journeyManagementPage.columns.driverIdentity"),
      dataIndex: "driverIdentity",
      key: "driverIdentity",
      sorter: true,
      render: (value: any, record: IJourneyDetailData) => {
        return value || "--";
      },
    },
    {
      title: t("journeyManagementPage.columns.companyName"),
      dataIndex: "companyName",
      key: "companyName",
      sorter: true,
      render: (value: any, record: IJourneyDetailData) => {
        return value || "--";
      },
      hidden: !isSEV,
    },
    {
      title: t("journeyManagementPage.columns.optionDelivery"),
      dataIndex: "optionDelivery",
      key: "optionDelivery",
      sorter: true,
      render: (value: any, record: IJourneyDetailData) => {
        const curOptionDelivery = OPTION_DELIVERY.find((x) => x.value === value);
        return curOptionDelivery?.label ? t(curOptionDelivery?.label) : "--";
      },
    },
    {
      title: t("journeyManagementPage.columns.totalDelivery"),
      dataIndex: "totalDelivery",
      key: "totalDelivery",
      sorter: true,
      render: (value: any, record: IJourneyDetailData) => {
        return value || "--";
      },
    },
    {
      title: t("journeyManagementPage.columns.dateStartDelivery"),
      dataIndex: "dateStartDelivery",
      key: "dateStartDelivery",
      render: (value: any, record: IJourneyDetailData) => {
        return record?.source === SEV_CODE
          ? record?.dateCheckin
            ? moment(record?.dateCheckin).format(DATE_TIME_FORMAT_SECOND)
            : "--"
          : value
          ? moment(value).format(DATE_TIME_FORMAT_SECOND)
          : "--";
      },
      sorter: true,
    },
    {
      title: t("journeyManagementPage.columns.dateCheckin"),
      dataIndex: "dateCheckin",
      key: "dateCheckin",
      render: (value: any, record: IJourneyDetailData) => {
        return value ? moment(value).format(DATE_TIME_FORMAT_SECOND) : "--";
      },
      sorter: true,
    },
    {
      title: t("journeyManagementPage.columns.dateCheckout"),
      dataIndex: "dateCheckout",
      key: "dateCheckout",
      render: (value: any, record: IJourneyDetailData) => {
        return value ? moment(value).format(DATE_TIME_FORMAT_SECOND) : "--";
      },
      sorter: true,
    },

    {
      title: t("journeyManagementPage.columns.status"),
      dataIndex: "statusStr",
      key: "statusStr",
      fixed: "right",
      sorter: true,
      render: (value: any, record: IJourneyDetailData) => {
        const curStatus = JOURNEY_STATUS_OPTIONS_TABLE.find((x) => {
          return (x?.value as string)?.split(",")?.includes(value?.toString());
        });
        return curStatus ? (
          <CommonTag tagType={curStatus?.type}>{t(curStatus?.label)}</CommonTag>
        ) : (
          "--"
        );
      },
    },
  ];

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    const resp = await journeyServices.deleteJourneies(dataSelected || []);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("journeyManagementPage.message.deleteJourneySuccess"),
      });
      setDataSelected([]);
      setVisible(false);
      let queryString = buildQueryString({
        ...params,
        page: DEFAULT_PAGE_NUMBER,
        search: JSON.stringify({}),
      });
      if (queryString !== search) {
        navigate(`${queryString || ""}`);
      } else {
        getData(true);
      }
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoadingDelete(false);
  };

  // xuất excel
  const handleExport = async () => {
    if (!isLoadingExport) {
      setIsLoadingExport(true);
      const paramsSearch = {
        page: 0,
        size: 10000,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: searchQuery,
      };
      const resp = await journeyServices.exportJourney(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        const fileName = `JourneyManagement_Data_Export_${moment().format(
          "YYYYMMDD",
        )}_${moment().unix()}.xlsx`;
        saveAs(data, fileName);
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoadingExport(false);
    }
  };

  return (
    <div className="content-wrapper">
      <SearchBox getData={getData} componentPath={componentPath} />

      <div className="avic-table-top">
        <div className="avic-table-top-title">{t("journeyManagementPage.title")}</div>
        <Space className="avic-table-top-right">
          {dataSelected?.length > 0 && isCanDelete && (
            <CommonButton btnType="danger" size={"small"} onClick={() => setVisible(true)}>
              {t("common.button.remove")}
            </CommonButton>
          )}
          {isCanCreate && (
            <Link to={`${componentPath}/add`}>
              <CommonButton btnType="primary" size={"small"}>
                {isSEV ? t("common.button.registerVehicleInfo") : t("common.button.createJourney")}
              </CommonButton>
            </Link>
          )}
          {isCanExport && (
            <CommonButton
              loading={isLoadingExport}
              btnType="success"
              onClick={handleExport}
              size={"small"}
            >
              {t("common.button.exportExcel")}
            </CommonButton>
          )}
        </Space>
      </div>

      <CommonTable
        isLoading={isLoading}
        rowKey={"id"}
        dataSource={listData?.content || []}
        columns={columns}
        data={listData}
        onChange={onPageChange}
        rowSelection={isCanDelete ? { ...rowSelection, selectedRowKeys: dataSelected } : null}
        defaultSorter={{
          order: sortTypeQuery,
          field: sortByQuery === "status" ? "statusStr" : sortByQuery,
        }}
        scroll={{ x: "max-content" }}
      />

      {visible ? (
        <CommonConfirmModal
          onCancel={() => setVisible(false)}
          content={t("journeyManagementPage.message.deleteJourney")}
          visible={visible}
          onOk={handleDelete}
          loadingBtnOk={isLoadingDelete}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default JourneyManagement;
