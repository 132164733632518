import { Tabs, TabsProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROLE_LIST, SEV_CODE } from "../../../../utils/constans";
import { JOURNEY_STATUS_VALUES } from "../../../../utils/constans/journeyManagement";
import { SIDEBAR_ITEM_HREF } from "../../../../utils/constans/sidebar";
import { useQuery } from "../../../../utils/customHooks";
import { IUseQueryResponse } from "../../../../utils/types";
import { IJourneyTabProps } from "../../../../utils/types/journeyManagement";
import {
  buildQueryString,
  isHaveAnyPermission,
  isHavePermission,
} from "../../../../utils/utilFunctions";
import HistoryTab from "./HistoryTab";
import InfoTab from "./InfoTab";

function JourneyTabs({ detailData, form, isEditing }: IJourneyTabProps) {
  const componentPath = SIDEBAR_ITEM_HREF.journey_management;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {} } = queryObj;
  const { tabKey: tabKeyQuery } = params;
  const [activeKey, setActiveKey] = useState<string>("info-tab");

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isSEV = profile?.source === SEV_CODE;
  const canViewInfoTab =
    isSEV &&
    (detailData?.source === SEV_CODE ||
      (detailData?.source !== SEV_CODE &&
        ![JOURNEY_STATUS_VALUES.KHOI_TAO, JOURNEY_STATUS_VALUES.DA_GAN_KHOA_CHUA_XAC_NHAN].includes(
          detailData?.status,
        ))) &&
    isHaveAnyPermission(
      [ROLE_LIST.QLHT_EDIT_POSITION, ROLE_LIST.QLHT_VIEW_DETAIL_INFO_DELI],
      profile,
    );
  const canViewHistoryTab =
    ((detailData?.source !== SEV_CODE &&
      detailData?.status !== JOURNEY_STATUS_VALUES.DA_GAN_KHOA_CHUA_XAC_NHAN &&
      detailData?.status !== JOURNEY_STATUS_VALUES.KHOI_TAO &&
      (isSEV || (!isSEV && detailData?.status !== JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO))) ||
      (detailData?.source === SEV_CODE &&
        detailData?.status !== JOURNEY_STATUS_VALUES.CHUA_VAO_SEV)) &&
    isHavePermission(ROLE_LIST.QLHT_VIEW_DETAIL_ACTION_AND_VIO, profile);

  useEffect(() => {
    const newTabKey = tabKeyQuery ? tabKeyQuery : canViewInfoTab ? "info-tab" : "history-tab";
    setActiveKey(newTabKey);
    if (newTabKey !== tabKeyQuery) {
      navigate(
        `${componentPath}/detail/${detailData?.id}${buildQueryString({ tabKey: newTabKey }) || ""}`,
      );
    }
  }, [tabKeyQuery, canViewInfoTab, navigate, componentPath, detailData?.id]);

  const items: TabsProps["items"] = useMemo(() => {
    let listItem = [];
    if (canViewInfoTab) {
      listItem.push({
        key: "info-tab",
        label: t("journeyManagementPage.infoTabTitle"),
        children: <InfoTab detailData={detailData} form={form} isEditing={isEditing} />,
      });
    }
    if (canViewHistoryTab) {
      listItem.push({
        key: "history-tab",
        label: t("journeyManagementPage.historyTabTitle"),
        children: <HistoryTab detailData={detailData} />,
      });
    }
    return listItem;
  }, [canViewHistoryTab, canViewInfoTab, detailData, form, t, isEditing]);

  const onChange = (key: string) => {
    navigate(`${componentPath}/detail/${detailData?.id}${buildQueryString({ tabKey: key }) || ""}`);
  };
  return (
    <>
      {items?.length > 0 && (
        <div className="avic-search-box">
          <div className="advance-search-box">
            <Tabs activeKey={activeKey} items={items} onChange={onChange} />
          </div>
        </div>
      )}
    </>
  );
}

export default JourneyTabs;
