import { Tabs, TabsProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROLE_LIST } from "../../../../utils/constans";
import { SIDEBAR_ITEM_HREF } from "../../../../utils/constans/sidebar";
import { useQuery } from "../../../../utils/customHooks";
import { IUseQueryResponse } from "../../../../utils/types";
import { ILockTabProps } from "../../../../utils/types/lockManagement";
import { buildQueryString, isHavePermission } from "../../../../utils/utilFunctions";
import HistoryTab from "./HistoryTab";
import InfoTab from "./InfoTab";

function LockTabs({ dataDetail }: ILockTabProps) {
  const componentPath = SIDEBAR_ITEM_HREF.lock_management;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {} } = queryObj;
  const { tabKey: tabKeyQuery } = params;
  const [activeKey, setActiveKey] = useState<string>("info-tab");

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanViewInfoTab = isHavePermission(ROLE_LIST.MANAGER_LOCK_DETAIL_ACC_VENDOR_USE, profile);
  const isCanViewHistoryTab = isHavePermission(ROLE_LIST.MANAGER_LOCK_DETAIL_HISTORY_USE, profile);

  useEffect(() => {
    const newTabKey = tabKeyQuery ? tabKeyQuery : isCanViewInfoTab ? "info-tab" : "history-tab";
    setActiveKey(newTabKey);
    if (newTabKey !== tabKeyQuery && dataDetail?.id) {
      navigate(
        `${componentPath}/detail/${dataDetail?.id}${buildQueryString({ tabKey: newTabKey }) || ""}`,
      );
    }
  }, [componentPath, dataDetail?.id, isCanViewInfoTab, navigate, tabKeyQuery]);

  const items: TabsProps["items"] = useMemo(() => {
    let newItems = [];
    if (isCanViewInfoTab) {
      newItems.push({
        key: "info-tab",
        label: t("lockManagementPage.infoTab"),
        children: <InfoTab dataDetail={dataDetail} />,
      });
    }
    if (isCanViewHistoryTab) {
      newItems.push({
        key: "history-tab",
        label: t("lockManagementPage.historyTab"),
        children: <HistoryTab />,
      });
    }
    return newItems;
  }, [dataDetail, isCanViewHistoryTab, isCanViewInfoTab, t]);

  const onChange = (key: string) => {
    navigate(`${componentPath}/detail/${dataDetail?.id}${buildQueryString({ tabKey: key }) || ""}`);
  };
  return (
    <>
      {items.length > 0 && (
        <div className="avic-search-box">
          <div className="advance-search-box">
            <Tabs activeKey={activeKey} items={items} onChange={onChange} />
          </div>
        </div>
      )}
    </>
  );
}

export default LockTabs;
