import { Col, Form, Row, Space, TourProps, notification } from "antd";
import { useWatch } from "antd/es/form/Form";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonTour from "../../components/Common/Tour";
import { ReactComponent as InfoIcon } from "../../resources/images/information-circle.svg";
import step1Image from "../../resources/images/tour_vendor/step1.png";
import step2Image from "../../resources/images/tour_vendor/step2.png";
import step3Image from "../../resources/images/tour_vendor/step3.png";
import commonServices from "../../services/common.service";
import vendorServices from "../../services/vendorManagement.service";
import { COMMON_PAGE_STATUS, REGEX_COORDINATES, REGEX_ONLY_NUMBER } from "../../utils/constans";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import { ICommonSelectDataType, IPlaceSelectType } from "../../utils/types";

function VendorCreate() {
  const componentPath = SIDEBAR_ITEM_HREF.vendor_management;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataTT, setDataTT] = useState<ICommonSelectDataType[]>();
  const [dataQH, setDataQH] = useState<ICommonSelectDataType[]>();
  const [dataPX, setDataPX] = useState<ICommonSelectDataType[]>();
  const [openTour, setOpenTour] = useState<boolean>(false);
  const refTour = useRef(null);

  const tourSteps: TourProps["steps"] = [
    {
      title: t("vendorManagementPage.tour.titleStep1"),
      description: t("vendorManagementPage.tour.descriptionStep1"),
      cover: <img alt="step1.png" src={step1Image} />,
      target: () => refTour.current,
    },
    {
      title: t("vendorManagementPage.tour.titleStep2"),
      description: t("vendorManagementPage.tour.descriptionStep2"),
      cover: <img alt="step2.png" src={step2Image} />,
      target: () => refTour.current,
    },
    {
      title: t("vendorManagementPage.tour.titleStep3"),
      description: t("vendorManagementPage.tour.descriptionStep3"),
      cover: <img alt="step3.png" src={step3Image} />,
      target: () => refTour.current,
    },
  ];

  const provinceValue = useWatch("provinceId", form);
  const districtValue = useWatch("districtId", form);
  const coordinatesValue = useWatch("coordinates", form);

  useEffect(() => {
    getDataTT();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (provinceValue) {
      getDataQH();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceValue]);

  useEffect(() => {
    if (districtValue) {
      getDataPX();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtValue]);

  const getDataTT = async (reload?: boolean) => {
    const resp = await commonServices.getPalces({ type: "TT" });
    const data = resp?.data;
    if (resp?.status === 200) {
      setDataTT(
        data?.data?.map((item: IPlaceSelectType) => ({ value: item?.id, label: item?.name })),
      );
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
  };
  const getDataQH = async (reload?: boolean) => {
    const resp = await commonServices.getPalces({ parentId: provinceValue, type: "QH" });
    const data = resp?.data;
    if (resp?.status === 200) {
      setDataQH(
        data?.data?.map((item: IPlaceSelectType) => ({ value: item?.id, label: item?.name })),
      );
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
  };
  const getDataPX = async (reload?: boolean) => {
    const resp = await commonServices.getPalces({ parentId: districtValue, type: "PX" });
    const data = resp?.data;
    if (resp?.status === 200) {
      setDataPX(
        data?.data?.map((item: IPlaceSelectType) => ({ value: item?.id, label: item?.name })),
      );
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);
    const dataSubmit = {
      ...values,
      lat: parseFloat(coordinatesValue?.split(", ")?.[0]),
      lon: parseFloat(coordinatesValue?.split(", ")?.[1]),
    };
    delete dataSubmit?.coordinates;
    const resp = await vendorServices.createVendorManagement(dataSubmit);
    if (resp?.status === 200) {
      notification.success({
        message: t("vendorManagementPage.message.createSuccess"),
      });
      navigate(componentPath);
    } else {
      notification.error({
        message: t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="box-wrapper">
      <CommonForm form={form} onFinish={onFinish} layout="vertical">
        <div className="avic-search-box">
          <div className="advance-search-box vendor-box">
            <div className="form-title">{t("vendorManagementPage.addTitle")}123123</div>
            <Row gutter={20}>
              <Col span={12}>
                <CommonFormItem
                  name="companyName"
                  label={t("vendorManagementPage.label.companyName")}
                  placeholder={t("vendorManagementPage.placeholder.companyName") as string}
                  showRequiredIcon
                  maxLength={128}
                  rules={[
                    {
                      whiteSpace: true,
                      required: true,
                      message: `${t("validate.input")} ${t(
                        "vendorManagementPage.label.companyName",
                      )}.`,
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="productType"
                  label={t("vendorManagementPage.label.productType")}
                  placeholder={t("vendorManagementPage.placeholder.productType") as string}
                  maxLength={128}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="taxCode"
                  label={t("vendorManagementPage.label.taxCode")}
                  placeholder={t("vendorManagementPage.placeholder.taxCode") as string}
                  showRequiredIcon
                  rules={[
                    {
                      whiteSpace: true,
                      required: true,
                      message: `${t("validate.input")} ${t("vendorManagementPage.label.taxCode")}.`,
                    },
                    {
                      pattern: REGEX_ONLY_NUMBER,
                      message: `${t("vendorManagementPage.label.taxCode")} ${t(
                        "validate.onlyNumber",
                      )}.`,
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="provinceId"
                  label={t("vendorManagementPage.label.province")}
                  placeholder={t("vendorManagementPage.placeholder.province") as string}
                  options={dataTT}
                  type="select"
                  onChange={() => {
                    form.setFieldsValue({
                      districtId: undefined,
                      communeId: undefined,
                    });
                  }}
                  showRequiredIcon
                  rules={[
                    {
                      required: true,
                      message: `${t("validate.select")} ${t(
                        "vendorManagementPage.label.province",
                      )}.`,
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="districtId"
                  label={t("vendorManagementPage.label.district")}
                  placeholder={t("vendorManagementPage.placeholder.district") as string}
                  options={dataQH}
                  type="select"
                  disabled={!provinceValue}
                  onChange={() => {
                    form.setFieldsValue({
                      communeId: undefined,
                    });
                  }}
                  showRequiredIcon
                  rules={[
                    {
                      required: true,
                      message: `${t("validate.select")} ${t(
                        "vendorManagementPage.label.district",
                      )}.`,
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="communeId"
                  label={t("vendorManagementPage.label.commune")}
                  placeholder={t("vendorManagementPage.placeholder.commune") as string}
                  options={dataPX}
                  type="select"
                  disabled={!districtValue}
                  showRequiredIcon
                  rules={[
                    {
                      required: true,
                      message: `${t("validate.select")} ${t(
                        "vendorManagementPage.label.commune",
                      )}.`,
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="address"
                  label={t("vendorManagementPage.label.address")}
                  placeholder={t("vendorManagementPage.placeholder.address") as string}
                  showRequiredIcon
                  maxLength={128}
                  rules={[
                    {
                      whiteSpace: true,
                      required: true,
                      message: `${t("validate.input")} ${t("vendorManagementPage.label.address")}.`,
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="status"
                  label={t("vendorManagementPage.label.status")}
                  placeholder={t("vendorManagementPage.placeholder.status") as string}
                  options={COMMON_PAGE_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
                  type="select"
                  showRequiredIcon
                  rules={[
                    {
                      required: true,
                      message: `${t("validate.select")} ${t("vendorManagementPage.label.status")}.`,
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="coordinates"
                  label={
                    <>
                      {t("vendorManagementPage.label.coordinates")}{" "}
                      <InfoIcon
                        className="info-mark"
                        onClick={() => setOpenTour(true)}
                        ref={refTour}
                      />
                    </>
                  }
                  placeholder={t("vendorManagementPage.placeholder.coordinates") as string}
                  showRequiredIcon
                  rules={[
                    {
                      whiteSpace: true,
                      required: true,
                      message: `${t("validate.input")} ${t(
                        "vendorManagementPage.label.coordinates",
                      )}.`,
                    },
                    {
                      pattern: REGEX_COORDINATES,
                      message: t("vendorManagementPage.validate.coordinates"),
                    },
                  ]}
                />
                {REGEX_COORDINATES.test(coordinatesValue) ? (
                  <a
                    style={{ width: "fit-content", display: "block" }}
                    href={`https://www.google.com/maps?q=${coordinatesValue?.split(", ")?.[0]},${
                      coordinatesValue?.split(", ")?.[1]
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CommonButton btnType="default" size={"small"}>
                      {t("common.button.detail")}
                    </CommonButton>
                  </a>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Space
              className="form-btn-container mgt-10 mgbt-15"
              style={{ justifyContent: "center " }}
            >
              <Link to={componentPath}>
                <CommonButton btnType="default" size={"small"}>
                  {t("common.button.back")}
                </CommonButton>
              </Link>
              <CommonButton btnType="primary" size={"small"} htmlType="submit" loading={isLoading}>
                {t("common.button.declareVendor")}
              </CommonButton>
            </Space>
          </div>
        </div>
      </CommonForm>

      <CommonTour open={openTour} onClose={() => setOpenTour(false)} steps={tourSteps} />
    </div>
  );
}

export default VendorCreate;
