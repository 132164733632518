import { Col, Form, Row, Space, TourProps, notification } from "antd";
import { useWatch } from "antd/es/form/Form";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import CommonButton from "../../../components/Common/Button";
import CommonForm from "../../../components/Common/Form";
import CommonFormItem from "../../../components/Common/FormItem";
import CommonTour from "../../../components/Common/Tour";
import { ReactComponent as InfoIcon } from "../../../resources/images/information-circle.svg";
import step1Image from "../../../resources/images/tour_vendor/step1.png";
import step2Image from "../../../resources/images/tour_vendor/step2.png";
import step3Image from "../../../resources/images/tour_vendor/step3.png";
import commonServices from "../../../services/common.service";
import vendorServices from "../../../services/vendorManagement.service";
import {
  COMMON_PAGE_STATUS,
  REGEX_COORDINATES,
  REGEX_ONLY_NUMBER,
  ROLE_LIST,
  SEV_CODE,
} from "../../../utils/constans";
import { SIDEBAR_ITEM_HREF } from "../../../utils/constans/sidebar";
import { ICommonSelectDataType, IPlaceSelectType } from "../../../utils/types";
import { IVendorManagementDetailData } from "../../../utils/types/vendorManagement";
import VendorTabs from "./VendorTabs";
import { useSelector } from "react-redux";
import { isHavePermission } from "../../../utils/utilFunctions";

function VendorDetail() {
  const componentPath = SIDEBAR_ITEM_HREF.vendor_management;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [detailData, setDetailData] = useState<IVendorManagementDetailData>();
  const [dataTT, setDataTT] = useState<ICommonSelectDataType[]>();
  const [dataQH, setDataQH] = useState<ICommonSelectDataType[]>();
  const [dataPX, setDataPX] = useState<ICommonSelectDataType[]>();
  const [openTour, setOpenTour] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const refTour = useRef(null);

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanEdit = isHavePermission(ROLE_LIST.MANAGER_VENDOR_EDIT, profile);
  const isCanViewDetail = isHavePermission(ROLE_LIST.MANAGER_VENDOR_VIEW_DETAIL_INFO, profile);

  const tourSteps: TourProps["steps"] = [
    {
      title: t("vendorManagementPage.tour.titleStep1"),
      description: t("vendorManagementPage.tour.descriptionStep1"),
      cover: <img alt="step1.png" src={step1Image} />,
      target: () => refTour.current,
    },
    {
      title: t("vendorManagementPage.tour.titleStep2"),
      description: t("vendorManagementPage.tour.descriptionStep2"),
      cover: <img alt="step2.png" src={step2Image} />,
      target: () => refTour.current,
    },
    {
      title: t("vendorManagementPage.tour.titleStep3"),
      description: t("vendorManagementPage.tour.descriptionStep3"),
      cover: <img alt="step3.png" src={step3Image} />,
      target: () => refTour.current,
    },
  ];

  const provinceValue = useWatch("provinceId", form);
  const districtValue = useWatch("districtId", form);
  const coordinatesValue = useWatch("coordinates", form);

  useEffect(() => {
    getData();
    getDataTT();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);
  useEffect(() => {
    if (provinceValue) {
      getDataQH();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceValue]);
  useEffect(() => {
    if (districtValue) {
      getDataPX();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtValue]);

  const getDataTT = async (reload?: boolean) => {
    const resp = await commonServices.getPalces({ type: "TT" });
    const data = resp?.data;
    if (resp?.status === 200) {
      setDataTT(
        data?.data?.map((item: IPlaceSelectType) => ({ value: item?.id, label: item?.name })),
      );
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
  };
  const getDataQH = async (reload?: boolean) => {
    const resp = await commonServices.getPalces({ parentId: provinceValue, type: "QH" });
    const data = resp?.data;
    if (resp?.status === 200) {
      setDataQH(
        data?.data?.map((item: IPlaceSelectType) => ({ value: item?.id, label: item?.name })),
      );
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
  };
  const getDataPX = async (reload?: boolean) => {
    const resp = await commonServices.getPalces({ parentId: districtValue, type: "PX" });
    const data = resp?.data;
    if (resp?.status === 200) {
      setDataPX(
        data?.data?.map((item: IPlaceSelectType) => ({ value: item?.id, label: item?.name })),
      );
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
  };

  const getData = async () => {
    setIsLoading(true);
    if (params?.id) {
      const resp = await vendorServices.detailVendorManagement(parseInt(params?.id));
      const data = resp?.data;
      if (resp?.status === 200) {
        setDetailData(data?.data);
        form.setFieldsValue({
          companyName: data?.data?.companyName,
          address: data?.data?.address,
          productType: data?.data?.productType,
          status: data?.data?.status,
          taxCode: data?.data?.taxCode,
          communeId: data?.data?.communeId,
          districtId: data?.data?.districtId,
          provinceId: data?.data?.provinceId,
          coordinates:
            typeof data?.data?.lat === "number" && typeof data?.data?.lon === "number"
              ? [data?.data?.lat, data?.data?.lon].join(", ")
              : "",
        });
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
    }
    setIsLoading(false);
  };

  const onFinish = async (values: any) => {
    setIsLoadingUpdate(true);
    const dataSubmit = {
      ...detailData,
      ...values,
      lat: parseFloat(coordinatesValue?.split(", ")?.[0]),
      lon: parseFloat(coordinatesValue?.split(", ")?.[1]),
    };
    delete dataSubmit?.coordinates;
    const resp = await vendorServices.updateVendorManagement(detailData?.id as number, dataSubmit);
    if (resp?.status === 200) {
      notification.success({
        message: t("vendorManagementPage.message.editSuccess"),
      });
      setIsEditing(false);
      navigate(componentPath);
    } else {
      notification.error({
        message: t("commonError.oopsSystem"),
      });
    }
    setIsLoadingUpdate(false);
  };

  return (
    <div className="box-wrapper">
      {(isCanViewDetail || isCanEdit) && (
        <CommonForm form={form} onFinish={onFinish} layout="vertical" isLoading={isLoading}>
          <div className="avic-search-box">
            <div className="advance-search-box">
              <div className="form-title">{t("vendorManagementPage.editTitle")}</div>
              <div>
                <div>
                  {t("vendorManagementPage.label.modifiedBy")}:{" "}
                  {detailData?.modifiedDateStr || "--"}
                </div>
                <div>
                  {t("vendorManagementPage.label.modifiedDate")}: {detailData?.modifiedBy || "--"}
                </div>
              </div>
              <div className="vendor-box">
                <Row gutter={20}>
                  <Col span={12}>
                    <CommonFormItem
                      name="companyName"
                      label={t("vendorManagementPage.label.companyName")}
                      placeholder={t("vendorManagementPage.placeholder.companyName") as string}
                      showRequiredIcon={
                        !(detailData?.vendorCode === SEV_CODE || !isCanEdit || !isEditing)
                      }
                      disabled={detailData?.vendorCode === SEV_CODE || !isCanEdit || !isEditing}
                      maxLength={128}
                      rules={[
                        {
                          whiteSpace: true,
                          required: true,
                          message: `${t("validate.input")} ${t(
                            "vendorManagementPage.label.companyName",
                          )}.`,
                        },
                      ]}
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="productType"
                      label={t("vendorManagementPage.label.productType")}
                      placeholder={t("vendorManagementPage.placeholder.productType") as string}
                      maxLength={128}
                      disabled={detailData?.vendorCode === SEV_CODE || !isCanEdit || !isEditing}
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="taxCode"
                      label={t("vendorManagementPage.label.taxCode")}
                      placeholder={t("vendorManagementPage.placeholder.taxCode") as string}
                      showRequiredIcon={
                        !(detailData?.vendorCode === SEV_CODE || !isCanEdit || !isEditing)
                      }
                      disabled={detailData?.vendorCode === SEV_CODE || !isCanEdit || !isEditing}
                      rules={[
                        {
                          whiteSpace: true,
                          required: true,
                          message: `${t("validate.input")} ${t(
                            "vendorManagementPage.label.taxCode",
                          )}.`,
                        },
                        {
                          pattern: REGEX_ONLY_NUMBER,
                          message: `${t("vendorManagementPage.label.taxCode")} ${t(
                            "validate.onlyNumber",
                          )}.`,
                        },
                      ]}
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="provinceId"
                      label={t("vendorManagementPage.label.province")}
                      placeholder={t("vendorManagementPage.placeholder.province") as string}
                      options={dataTT}
                      type="select"
                      onChange={() => {
                        form.setFieldsValue({
                          districtId: undefined,
                          communeId: undefined,
                        });
                      }}
                      showRequiredIcon={
                        !(detailData?.vendorCode === SEV_CODE || !isCanEdit || !isEditing)
                      }
                      disabled={detailData?.vendorCode === SEV_CODE || !isCanEdit || !isEditing}
                      rules={[
                        {
                          required: true,
                          message: `${t("validate.select")} ${t(
                            "vendorManagementPage.label.province",
                          )}.`,
                        },
                      ]}
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="districtId"
                      label={t("vendorManagementPage.label.district")}
                      placeholder={t("vendorManagementPage.placeholder.district") as string}
                      options={dataQH}
                      type="select"
                      showRequiredIcon={
                        !(
                          !provinceValue ||
                          detailData?.vendorCode === SEV_CODE ||
                          !isCanEdit ||
                          !isEditing
                        )
                      }
                      disabled={
                        !provinceValue ||
                        detailData?.vendorCode === SEV_CODE ||
                        !isCanEdit ||
                        !isEditing
                      }
                      onChange={() => {
                        form.setFieldsValue({
                          communeId: undefined,
                        });
                      }}
                      rules={[
                        {
                          required: true,
                          message: `${t("validate.select")} ${t(
                            "vendorManagementPage.label.district",
                          )}.`,
                        },
                      ]}
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="communeId"
                      label={t("vendorManagementPage.label.commune")}
                      placeholder={t("vendorManagementPage.placeholder.commune") as string}
                      options={dataPX}
                      type="select"
                      showRequiredIcon={
                        !(
                          !districtValue ||
                          detailData?.vendorCode === SEV_CODE ||
                          !isCanEdit ||
                          !isEditing
                        )
                      }
                      disabled={
                        !districtValue ||
                        detailData?.vendorCode === SEV_CODE ||
                        !isCanEdit ||
                        !isEditing
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("validate.select")} ${t(
                            "vendorManagementPage.label.commune",
                          )}.`,
                        },
                      ]}
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="address"
                      label={t("vendorManagementPage.label.address")}
                      placeholder={t("vendorManagementPage.placeholder.address") as string}
                      showRequiredIcon={
                        !(detailData?.vendorCode === SEV_CODE || !isCanEdit || !isEditing)
                      }
                      disabled={detailData?.vendorCode === SEV_CODE || !isCanEdit || !isEditing}
                      maxLength={128}
                      rules={[
                        {
                          whiteSpace: true,
                          required: true,
                          message: `${t("validate.input")} ${t(
                            "vendorManagementPage.label.address",
                          )}.`,
                        },
                      ]}
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="status"
                      label={t("vendorManagementPage.label.status")}
                      placeholder={t("vendorManagementPage.placeholder.status") as string}
                      options={COMMON_PAGE_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
                      type="select"
                      showRequiredIcon={
                        !(detailData?.vendorCode === SEV_CODE || !isCanEdit || !isEditing)
                      }
                      disabled={detailData?.vendorCode === SEV_CODE || !isCanEdit || !isEditing}
                      rules={[
                        {
                          required: true,
                          message: `${t("validate.select")} ${t(
                            "vendorManagementPage.label.status",
                          )}.`,
                        },
                      ]}
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="coordinates"
                      label={
                        <>
                          {t("vendorManagementPage.label.coordinates")}{" "}
                          <InfoIcon
                            className="info-mark"
                            onClick={() => setOpenTour(true)}
                            ref={refTour}
                          />
                        </>
                      }
                      placeholder={t("vendorManagementPage.placeholder.coordinates") as string}
                      showRequiredIcon={
                        !(detailData?.vendorCode === SEV_CODE || !isCanEdit || !isEditing)
                      }
                      disabled={detailData?.vendorCode === SEV_CODE || !isCanEdit || !isEditing}
                      rules={[
                        {
                          whiteSpace: true,
                          required: true,
                          message: `${t("validate.input")} ${t(
                            "vendorManagementPage.label.coordinates",
                          )}.`,
                        },
                        {
                          pattern: REGEX_COORDINATES,
                          message: t("vendorManagementPage.validate.coordinates"),
                        },
                      ]}
                    />
                    {REGEX_COORDINATES.test(coordinatesValue) ? (
                      <a
                        style={{ width: "fit-content", display: "block" }}
                        href={`https://www.google.com/maps?q=${
                          coordinatesValue?.split(", ")?.[0]
                        },${coordinatesValue?.split(", ")?.[1]}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <CommonButton btnType="default" size={"small"}>
                          {t("common.button.detail")}
                        </CommonButton>
                      </a>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Space className="form-btn-container mgt-10" style={{ justifyContent: "center" }}>
                  {isEditing ? (
                    <CommonButton
                      onClick={() => setIsEditing(false)}
                      size={"large"}
                      btnType="default"
                    >
                      {t("common.button.cancel")}
                    </CommonButton>
                  ) : (
                    <Link to={componentPath}>
                      <CommonButton btnType="default" size={"large"}>
                        {t("common.button.exit")}
                      </CommonButton>
                    </Link>
                  )}
                  {detailData?.vendorCode !== SEV_CODE && isCanEdit ? (
                    isEditing ? (
                      <CommonButton
                        btnType="primary"
                        size={"large"}
                        loading={isLoadingUpdate}
                        onClick={() => form.submit()}
                      >
                        {t("common.button.update")}
                      </CommonButton>
                    ) : (
                      <CommonButton
                        btnType="primary"
                        size={"large"}
                        onClick={() => setIsEditing(true)}
                      >
                        {t("common.button.edit")}
                      </CommonButton>
                    )
                  ) : (
                    <></>
                  )}
                </Space>
              </div>
            </div>
          </div>
        </CommonForm>
      )}
      <VendorTabs detailVendor={detailData} />

      <CommonTour open={openTour} onClose={() => setOpenTour(false)} steps={tourSteps} />
    </div>
  );
}

export default VendorDetail;
