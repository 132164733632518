import { useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import SystemLayout from "./components/Layout";
import AccountManagement from "./containers/AccountManagement";
import AccountAdd from "./containers/AccountManagement/AccountAdd";
import DashBoard from "./containers/Dashboard";
import DownloadApp from "./containers/DownloadApp";
import GroupManagement from "./containers/GroupManagement";
import GroupAddForm from "./containers/GroupManagement/GroupAddForm";
import GroupDetail from "./containers/GroupManagement/detail";
import JourneyManagement from "./containers/JourneyManagement";
import JourneyAddForm from "./containers/JourneyManagement/JourneyAddForm";
import VehicleInfoDetail from "./containers/JourneyManagement/detail";
import LocationCategory from "./containers/LocationCategory";
import LocationHistory from "./containers/LocationCategory/LocationHistory";
import LockManagement from "./containers/LockManagement";
import LockDetail from "./containers/LockManagement/detail";
import Login from "./containers/Login";
import Operate from "./containers/Operate";
import Page404 from "./containers/Page404";
import Profile from "./containers/Profile";
import VehicleCategory from "./containers/VehicleTypeCategory";
import VendorManagement from "./containers/VendorManagement";
import VendorCreate from "./containers/VendorManagement/VendorCreate";
import VendorDetail from "./containers/VendorManagement/detail";
import { ROLE_LIST, SEV_CODE } from "./utils/constans";
import { SIDEBAR_ITEM_HREF } from "./utils/constans/sidebar";
import LocalStore from "./utils/localStorage";
import { isHaveAnyPermission, isHavePermission } from "./utils/utilFunctions";

const MyRoutes = () => {
  const accessToken = LocalStore.getInstance().read("accessToken");
  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanViewListJourney = isHavePermission(ROLE_LIST.QLHT_VIEW, profile);
  const isCanCreateJourney = isHavePermission(ROLE_LIST.QLHT_CREATE, profile);
  const isCanViewDetailJourney = isHaveAnyPermission(
    [
      ROLE_LIST.QLHT_EDIT,
      ROLE_LIST.QLHT_EDIT_POSITION,
      ROLE_LIST.QLHT_VIEW_DETAIL_CAR,
      ROLE_LIST.QLHT_VIEW_DETAIL_INFO_DELI,
      ROLE_LIST.QLHT_VIEW_DETAIL_ACTION_AND_VIO,
    ],
    profile,
  );
  const isCanViewListLock = isHavePermission(ROLE_LIST.MANAGER_LOCK_VIEW_LIST, profile);
  const isCanViewDetailLock = isHaveAnyPermission(
    [
      ROLE_LIST.MANAGER_LOCK_DETAIL_INFO,
      ROLE_LIST.MANAGER_LOCK_DETAIL_HISTORY_USE,
      ROLE_LIST.MANAGER_LOCK_DETAIL_ACC_VENDOR_USE,
    ],
    profile,
  );
  const isCanViewListVendor = isHavePermission(ROLE_LIST.MANAGER_VENDOR_VIEW_LIST, profile);
  const isCanCreateVendor = isHavePermission(ROLE_LIST.MANAGER_VENDOR_ADD, profile);
  const isCanViewDetailVendor = isHaveAnyPermission(
    [
      ROLE_LIST.MANAGER_VENDOR_VIEW_DETAIL_INFO,
      ROLE_LIST.MANAGER_VENDOR_VIEW_DETAIL_INFO_STAFF,
      ROLE_LIST.MANAGER_VENDOR_VIEW_DETAIL_LOCK_MANAGER,
      ROLE_LIST.MANAGER_VENDOR_EDIT,
    ],
    profile,
  );
  const isCanViewListAccount = isHavePermission(ROLE_LIST.MANAGER_ACCOUNT_VIEW_LIST, profile);
  const isCanCreateAccount = isHavePermission(ROLE_LIST.MANAGER_ACCOUNT_CREATE, profile);
  const isCanViewDetailAccount = isHaveAnyPermission(
    [ROLE_LIST.MANAGER_ACCOUNT_EDIT, ROLE_LIST.MANAGER_ACCOUNT_VIEW_DETAIL],
    profile,
  );
  const isCanViewListGroup = isHavePermission(ROLE_LIST.MANAGER_GROUP_VIEW_LIST, profile);
  const isCanCreateGroup = isHavePermission(ROLE_LIST.MANAGER_GROUP_VIEW_LIST, profile);
  const isCanViewDetailGroup = isHaveAnyPermission(
    [
      ROLE_LIST.MANAGER_GROUP_VIEW_DETAIL_INFO,
      ROLE_LIST.MANAGER_GROUP_VIEW_DETAIL_ACCOUNT,
      ROLE_LIST.MANAGER_GROUP_EDIT,
    ],
    profile,
  );
  const isCanViewListLocation = isHavePermission(ROLE_LIST.MANAGER_POSITION_VIEW_LIST, profile);
  const isCanViewListLocationHistory = isHavePermission(
    ROLE_LIST.MANAGER_POSITION_VIEW_HISTORY,
    profile,
  );
  const isCanViewListVehicle = isHavePermission(ROLE_LIST.MANAGER_CAR_VIEW_LIST, profile);

  return (
    <Router>
      <Routes>
        {accessToken ? (
          <>
            <Route
              path={SIDEBAR_ITEM_HREF.home}
              element={
                <SystemLayout>
                  <DashBoard />
                </SystemLayout>
              }
            />
            {/* Vận hành */}
            {isCanViewListJourney && (
              <Route
                path={SIDEBAR_ITEM_HREF.operate}
                element={
                  <SystemLayout>
                    <Operate />
                  </SystemLayout>
                }
              />
            )}
            {/* Quản lý hành trình */}
            {isCanViewListJourney && (
              <Route
                path={SIDEBAR_ITEM_HREF.journey_management}
                element={
                  <SystemLayout>
                    <JourneyManagement />
                  </SystemLayout>
                }
              />
            )}
            {isCanCreateJourney && (
              <Route
                path={`${SIDEBAR_ITEM_HREF.journey_management}/add`}
                element={
                  <SystemLayout>
                    <JourneyAddForm />
                  </SystemLayout>
                }
              />
            )}
            {isCanViewDetailJourney && (
              <Route
                path={`${SIDEBAR_ITEM_HREF.journey_management}/detail/:id`}
                element={
                  <SystemLayout>
                    <VehicleInfoDetail />
                  </SystemLayout>
                }
              />
            )}
            {/* Quản lý khóa */}
            {isCanViewListLock && (
              <Route
                path={SIDEBAR_ITEM_HREF.lock_management}
                element={
                  <SystemLayout>
                    <LockManagement />
                  </SystemLayout>
                }
              />
            )}
            {isCanViewDetailLock && (
              <Route
                path={`${SIDEBAR_ITEM_HREF.lock_management}/detail/:id`}
                element={
                  <SystemLayout>
                    <LockDetail />
                  </SystemLayout>
                }
              />
            )}
            {/* Quản lý vendor */}
            {profile?.source === SEV_CODE && isCanViewListVendor && (
              <Route
                path={SIDEBAR_ITEM_HREF.vendor_management}
                element={
                  <SystemLayout>
                    <VendorManagement />
                  </SystemLayout>
                }
              />
            )}
            {profile?.source === SEV_CODE && isCanCreateVendor && (
              <Route
                path={`${SIDEBAR_ITEM_HREF.vendor_management}/add`}
                element={
                  <SystemLayout>
                    <VendorCreate />
                  </SystemLayout>
                }
              />
            )}
            {profile?.source === SEV_CODE && isCanViewDetailVendor && (
              <Route
                path={`${SIDEBAR_ITEM_HREF.vendor_management}/detail/:id`}
                element={
                  <SystemLayout>
                    <VendorDetail />
                  </SystemLayout>
                }
              />
            )}
            {/* quản lý tài khoản */}
            {isCanViewListAccount && (
              <Route
                path={SIDEBAR_ITEM_HREF.account_management}
                element={
                  <SystemLayout>
                    <AccountManagement />
                  </SystemLayout>
                }
              />
            )}
            {profile?.source === SEV_CODE && isCanCreateAccount && (
              <Route
                path={`${SIDEBAR_ITEM_HREF.account_management}/add`}
                element={
                  <SystemLayout>
                    <AccountAdd />
                  </SystemLayout>
                }
              />
            )}
            {isCanViewDetailAccount && (
              <Route
                path={`${SIDEBAR_ITEM_HREF.account_management}/detail/:id`}
                element={
                  <SystemLayout>
                    <AccountAdd />
                  </SystemLayout>
                }
              />
            )}
            {/* Quản lý nhóm quyền */}
            {profile?.source === SEV_CODE && isCanViewListGroup && (
              <Route
                path={SIDEBAR_ITEM_HREF.group_management}
                element={
                  <SystemLayout>
                    <GroupManagement />
                  </SystemLayout>
                }
              />
            )}
            {profile?.source === SEV_CODE && isCanCreateGroup && (
              <Route
                path={`${SIDEBAR_ITEM_HREF.group_management}/add`}
                element={
                  <SystemLayout>
                    <GroupAddForm />
                  </SystemLayout>
                }
              />
            )}
            {profile?.source === SEV_CODE && isCanViewDetailGroup && (
              <Route
                path={`${SIDEBAR_ITEM_HREF.group_management}/detail/:id`}
                element={
                  <SystemLayout>
                    <GroupDetail />
                  </SystemLayout>
                }
              />
            )}

            {/* Danh mục vị trí */}
            {profile?.source === SEV_CODE && isCanViewListLocation && (
              <Route
                path={SIDEBAR_ITEM_HREF.location_category}
                element={
                  <SystemLayout>
                    <LocationCategory />
                  </SystemLayout>
                }
              />
            )}
            {profile?.source === SEV_CODE && isCanViewListLocationHistory && (
              <Route
                path={`${SIDEBAR_ITEM_HREF.location_category}/history`}
                element={
                  <SystemLayout>
                    <LocationHistory />
                  </SystemLayout>
                }
              />
            )}
            {/* Danh mục loại xe */}
            {profile?.source === SEV_CODE && isCanViewListVehicle && (
              <Route
                path={SIDEBAR_ITEM_HREF.vehicle_category}
                element={
                  <SystemLayout>
                    <VehicleCategory />
                  </SystemLayout>
                }
              />
            )}
            {/* Thông tin tài khoản */}
            <Route
              path={SIDEBAR_ITEM_HREF.profile}
              element={
                <SystemLayout>
                  <Profile />
                </SystemLayout>
              }
            />
          </>
        ) : (
          <Route path={SIDEBAR_ITEM_HREF.home} element={<Login />} />
        )}
        <Route path={SIDEBAR_ITEM_HREF.apk} element={<DownloadApp />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
};
export default MyRoutes;
