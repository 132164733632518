import { Col, Form, Row, Space, TableColumnsType, TablePaginationConfig, notification } from "antd";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";
import { saveAs } from "file-saver";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CommonButton from "../../../../components/Common/Button";
import CommonForm from "../../../../components/Common/Form";
import CommonFormItem from "../../../../components/Common/FormItem";
import CommonTable from "../../../../components/Common/Table";
import CommonTag from "../../../../components/Common/Tag";
import accountsServices from "../../../../services/accountManagement.service";
import permissionsServices from "../../../../services/groupManagement.service";
import {
  COMMON_PAGE_STATUS,
  DATE_TIME_FORMAT_SECOND,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  ROLE_LIST,
} from "../../../../utils/constans";
import { SIDEBAR_ITEM_HREF } from "../../../../utils/constans/sidebar";
import { useQuery } from "../../../../utils/customHooks";
import {
  ICommonResponsePaging,
  ICommonSelectDataType,
  IUseQueryResponse,
} from "../../../../utils/types";
import { IAccountDetailData } from "../../../../utils/types/accountManagement";
import { IGroupDetailData } from "../../../../utils/types/groupManagement";
import { IVendorTabsProps } from "../../../../utils/types/vendorManagement";
import { buildQueryString, isHavePermission } from "../../../../utils/utilFunctions";
import { useSelector } from "react-redux";

function StaffTab({ detailVendor }: IVendorTabsProps) {
  const componentPath = SIDEBAR_ITEM_HREF.vendor_management;
  const [form] = Form.useForm();
  const vendorId = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listData, setListData] = useState<ICommonResponsePaging<IAccountDetailData>>();
  const navigate = useNavigate();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const {
    tabKey: tabKeyQuery,
    page: pageQuery,
    pageSize: pageSizeQuery,
    sortBy: sortByQuery,
    sortType: sortTypeQuery,
    search: searchQuery,
  } = params;
  const page = pageQuery ? parseFloat(pageQuery) : DEFAULT_PAGE_NUMBER;
  const pageSize = pageSizeQuery ? parseFloat(pageSizeQuery) : DEFAULT_PAGE_SIZE;
  const [groupsSelectData, setGroupSelectData] = useState<ICommonSelectDataType[]>([]);
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanExport = isHavePermission(ROLE_LIST.MANAGER_VENDOR_EXCEL_USER, profile);

  const getData = useCallback(
    async (reload?: boolean) => {
      setIsLoading(true);
      const paramsSearch = {
        page: reload ? 0 : page - 1,
        size: pageSize,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: JSON.stringify({ ...searchQueryData, source: detailVendor?.vendorCode }),
      };
      const resp = await accountsServices.getAccount(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        setListData(data?.data);
      } else {
        setListData(undefined);
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailVendor?.vendorCode, page, pageSize, sortByQuery, sortTypeQuery, t, search],
  );
  const getDataSelect = async () => {
    const paramsSearch = {
      page: 0,
      size: 1000,
      search: JSON.stringify({ status: 0 }),
    };
    //select nhóm quyền
    const respPermission = await permissionsServices.getPermission(paramsSearch);
    const dataPermission = respPermission?.data;
    if (respPermission?.status === 200) {
      setGroupSelectData(
        dataPermission?.data?.content?.map((item: IGroupDetailData) => ({
          value: item?.id,
          label: item?.groupName,
        })),
      );
    } else {
      setGroupSelectData([]);
    }
  };

  useEffect(() => {
    if (tabKeyQuery !== "staff-tab") {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKeyQuery]);

  useEffect(() => {
    if ((!tabKeyQuery || tabKeyQuery === "staff-tab") && detailVendor?.vendorCode) {
      getData();
      getDataSelect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  const onFinish = (values: any) => {
    onSearch(values);
  };

  const onClear = (clearParams = {}) => {
    onSearch({ ...clearParams });
    setTimeout(() => {
      form.resetFields();
    }, 100);
  };

  const onSearch = (data: any = {}) => {
    const dataSearch = { ...data, groups: data?.groups?.join(",") };
    let queryString = buildQueryString({
      page: DEFAULT_PAGE_NUMBER,
      tabKey: tabKeyQuery,
      search: JSON.stringify(dataSearch),
    });
    // detect if query not change => call api
    if (queryString !== search) {
      navigate(`${componentPath}/detail/${vendorId?.id}${queryString}`);
    } else {
      getData();
    }
  };

  const onPageChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
    extra: TableCurrentDataSource<IAccountDetailData>,
  ) => {
    let queryString = buildQueryString({
      ...params,
      page: pageSize === pagination?.pageSize ? pagination?.current : DEFAULT_PAGE_NUMBER,
      pageSize: pagination?.pageSize,
      sortBy: sorter?.order ? sorter?.field : "",
      sortType: sorter?.order ? (sorter?.order === "ascend" ? "asc" : "desc") : "",
    });
    navigate(`${componentPath}/detail/${parseInt(vendorId?.id as string)}${queryString || ""}`);
  };

  const columns: TableColumnsType<IAccountDetailData> = [
    {
      title: t("accountManagementPage.columns.STT"),
      dataIndex: "index",
      key: "index",
      width: "7%",
      align: "center",
      render: (value: any, record: IAccountDetailData, index: number) =>
        (page - 1) * pageSize + index + 1,
    },
    {
      title: t("accountManagementPage.columns.username"),
      dataIndex: "username",
      key: "username",
      render: (value: any, record: IAccountDetailData, index: number) => {
        return value || "--";
      },
      sorter: true,
    },
    {
      title: t("accountManagementPage.columns.name"),
      dataIndex: "name",
      key: "name",
      render: (value: any, record: IAccountDetailData, index: number) => {
        return value || "--";
      },
      sorter: true,
    },
    {
      title: t("accountManagementPage.columns.groups"),
      dataIndex: "groups",
      key: "groups",
      render: (value: any, record: IAccountDetailData, index: number) => {
        return value?.length
          ? value?.map((item: IGroupDetailData) => item?.groupName).join(",  ")
          : "--";
      },
    },
    {
      title: t("accountManagementPage.columns.createdDate"),
      dataIndex: "createdDate",
      key: "createdDate",
      render: (value: any, record: IAccountDetailData, index: number) => {
        return value ? moment(value).format(DATE_TIME_FORMAT_SECOND) : "--";
      },
      sorter: true,
    },
    {
      title: t("accountManagementPage.columns.status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (value: any, record: IAccountDetailData, index: number) => {
        const curStatus = COMMON_PAGE_STATUS.find((x: ICommonSelectDataType) => x.value === value);
        return curStatus ? (
          <CommonTag tagType={curStatus?.type}>{t(curStatus?.label)}</CommonTag>
        ) : (
          "--"
        );
      },
    },
  ];

  const handleExport = async () => {
    if (!isLoadingExport) {
      setIsLoadingExport(true);
      const paramsSearch = {
        page: 0,
        size: 10000,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: JSON.stringify({
          ...searchQueryData,
          source: detailVendor?.vendorCode,
          exportType: 3,
        }),
      };
      const resp = await accountsServices.exportAccount(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        const fileName = `StaffList_Data_Export_${moment().format(
          "YYYYMMDD",
        )}_${moment().unix()}.xlsx`;
        saveAs(data, fileName);
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoadingExport(false);
    }
  };

  return (
    <div className="vendor-info-tab">
      <div className="info-left">
        <CommonForm
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            username: searchQueryData?.username,
            name: searchQueryData?.name,
            groups: searchQueryData?.groups?.split(","),
            status: searchQueryData?.status,
          }}
        >
          <Row gutter={30}>
            <Col span={24}>
              <CommonFormItem
                name="username"
                label={t("accountManagementPage.label.username")}
                placeholder={t("accountManagementPage.placeholder.username") as string}
              />
            </Col>
            <Col span={24}>
              <CommonFormItem
                name="name"
                label={t("accountManagementPage.label.name")}
                placeholder={t("accountManagementPage.placeholder.name") as string}
              />
            </Col>
            <Col span={24}>
              <CommonFormItem
                name="groups"
                label={t("accountManagementPage.label.groups")}
                placeholder={t("accountManagementPage.placeholder.groups") as string}
                options={groupsSelectData}
                type="select"
                mode="multiple"
              />
            </Col>
            <Col span={24}>
              <CommonFormItem
                name="status"
                label={t("accountManagementPage.label.status")}
                placeholder={t("accountManagementPage.placeholder.status") as string}
                options={COMMON_PAGE_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
                type="select"
              />
            </Col>
          </Row>

          <Space className="form-btn-container">
            <CommonButton btnType="default" size={"small"} onClick={() => onClear({})}>
              {t("common.button.deleteCondition")}
            </CommonButton>
            <CommonButton btnType="primary" size={"small"} htmlType="submit">
              {t("common.button.search")}
            </CommonButton>
          </Space>
        </CommonForm>
      </div>
      <div className="info-right">
        {isCanExport && (
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "0.5rem" }}>
            <CommonButton
              size={"small"}
              btnType="success"
              onClick={handleExport}
              loading={isLoadingExport}
              htmlType="button"
            >
              {t("common.button.exportExcel")}
            </CommonButton>
          </div>
        )}
        <CommonTable
          isLoading={isLoading}
          rowKey={"id"}
          onChange={onPageChange}
          dataSource={listData?.content || []}
          columns={columns}
          data={listData}
          scroll={{ y: "20.833vw" }}
          defaultSorter={{
            order: sortTypeQuery,
            field: sortByQuery,
          }}
        />
      </div>
    </div>
  );
}

export default StaffTab;
