import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Col, Form, Row, Space, TablePaginationConfig, notification } from "antd";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonInput from "../../components/Common/Input";
import CommonTable from "../../components/Common/Table";
import { ReactComponent as Search } from "../../resources/images/search-1.svg";
import locationServices from "../../services/locationCategory.service";
import {
  DATE_TIME_FORMAT_SECOND,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  ROLE_LIST,
} from "../../utils/constans";
import { LOCATION_BUILDINGS } from "../../utils/constans/locationCategory";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import { useQuery } from "../../utils/customHooks";
import { ICommonResponsePaging, IUseQueryResponse } from "../../utils/types";
import { buildQueryString, isHavePermission } from "../../utils/utilFunctions";
import { saveAs } from "file-saver";

function LocationHistory() {
  const componentPath = `${SIDEBAR_ITEM_HREF.location_category}/history`;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const {
    advance: advanceQuery,
    page: pageQuery,
    pageSize: pageSizeQuery,
    sortBy: sortByQuery,
    sortType: sortTypeQuery,
    search: searchQuery,
  } = params;
  const page = pageQuery ? parseFloat(pageQuery) : DEFAULT_PAGE_NUMBER;
  const pageSize = pageSizeQuery ? parseFloat(pageSizeQuery) : DEFAULT_PAGE_SIZE;
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};
  const [listData, setListData] = useState<ICommonResponsePaging<any>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [advance, setAdvance] = useState(advanceQuery === "true" || false);
  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanExportHistory = isHavePermission(ROLE_LIST.MANAGER_POSITION_EXPORT_HISTORY, profile);

  useEffect(() => {
    setAdvance(advanceQuery === "true");
  }, [advanceQuery]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getData = async (reload?: boolean) => {
    setIsLoading(true);
    const paramsSearch = {
      page: reload ? 0 : page - 1,
      size: pageSize,
      sortBy: sortByQuery,
      sortType: sortTypeQuery,
      search: searchQuery,
    };
    const resp = await locationServices.getHistoryPosition(paramsSearch);
    const data = resp?.data;
    if (resp?.status === 200) {
      setListData(data?.data);
    } else {
      setListData(undefined);
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  };

  const onPageChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
    extra: TableCurrentDataSource<any>,
  ) => {
    let queryString = buildQueryString({
      ...params,
      page: pageSize === pagination?.pageSize ? pagination?.current : DEFAULT_PAGE_NUMBER,
      pageSize: pagination?.pageSize,
      sortBy: sorter?.order ? sorter?.field : "",
      sortType: sorter?.order ? (sorter?.order === "ascend" ? "asc" : "desc") : "",
    });
    navigate(`${componentPath}${queryString || ""}`);
  };

  const columns = [
    {
      title: t("locationCategoryPage.columns.STT"),
      dataIndex: "index",
      key: "index",
      width: "5%",
      align: "center",
      render: (value: any, record: any, index: number) => (page - 1) * pageSize + index + 1,
    },
    {
      title: t("locationCategoryPage.columns.name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (value: any, record: any) => {
        return value || "--";
      },
    },
    {
      title: t("locationCategoryPage.columns.address"),
      dataIndex: "address",
      key: "address",
      render: (value: any, record: any) => {
        return value || "--";
      },
      sorter: true,
    },
    {
      title: t("locationCategoryPage.columns.plateNumber"),
      dataIndex: "plateNumber",
      key: "plateNumber",
      render: (value: any, record: any) => {
        return value || "--";
      },
      sorter: true,
    },
    {
      title: t("locationCategoryPage.columns.username"),
      dataIndex: "username",
      key: "username",
      sorter: true,
      render: (value: any, record: any) => {
        return value || "--";
      },
    },

    {
      title: t("locationCategoryPage.columns.dateIn"),
      dataIndex: "dateIn",
      key: "dateIn",
      render: (value: any, record: any) => {
        return value ? moment(value).format(DATE_TIME_FORMAT_SECOND) : "--";
      },
      sorter: true,
    },
    {
      title: t("locationCategoryPage.columns.dateOut"),
      dataIndex: "dateOut",
      key: "dateOut",
      render: (value: any, record: any) => {
        return value ? moment(value).format(DATE_TIME_FORMAT_SECOND) : "--";
      },
      sorter: true,
    },
  ];

  // xuất excel
  const handleExport = async () => {
    if (!isLoadingExport) {
      setIsLoadingExport(true);
      const paramsSearch = {
        page: 0,
        size: 10000,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: searchQuery,
      };
      const resp = await locationServices.exportHistoryPosition(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        const fileName = `LocationHistory_Data_Export_${moment().format(
          "YYYYMMDD",
        )}_${moment().unix()}.xlsx`;
        saveAs(data, fileName);
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoadingExport(false);
    }
  };

  const onFinish = (values: any) => {
    onSearch(values);
  };

  const onClear = (clearParams = {}) => {
    onSearch({ ...clearParams }, true);
    setTimeout(() => {
      form.resetFields();
    }, 100);
  };

  const onSearch = (data: any = {}, isClear?: boolean) => {
    const dataSearch = { ...data };
    if (data?.dateIn?.length) {
      dataSearch["dateInFromLong"] = moment(data?.dateIn?.[0]).startOf("day").valueOf();
      dataSearch["dateInToLong"] = moment(data?.dateIn?.[1]).endOf("day").valueOf();
    }
    if (data?.dateOut?.length) {
      dataSearch["dateOutFromLong"] = moment(data?.dateOut?.[0]).startOf("day").valueOf();
      dataSearch["dateOutToLong"] = moment(data?.dateOut?.[1]).endOf("day").valueOf();
    }
    delete dataSearch.advance;
    delete dataSearch?.dateIn;
    delete dataSearch?.dateOut;
    let query = {
      advance,
      page: DEFAULT_PAGE_NUMBER,
      search: JSON.stringify(dataSearch),
    };
    if (isClear) {
      query.advance = data.advance;
    }
    const queryString = buildQueryString(query);
    // detect if query not change => call api
    if (queryString !== search) {
      navigate(`${componentPath}${queryString}`);
    } else {
      getData();
    }
  };

  return (
    <div className="content-wrapper">
      <div className="avic-search-box">
        {!advance ? (
          <div className="normal-search-box">
            <CommonForm
              form={form}
              onFinish={onFinish}
              layout="horizontal"
              initialValues={{
                keyword: searchQueryData?.keyword,
              }}
            >
              <CommonFormItem name="keyword">
                <CommonInput
                  placeholder={t("locationCategoryPage.placeholder.advancedSearch") as string}
                  prefix={
                    <div
                      onClick={() => {
                        onClear({ advance: true });
                      }}
                      className="open-advance-search-btn"
                    >
                      {t("locationCategoryPage.label.advancedSearch")} <CaretDownOutlined />
                    </div>
                  }
                  addonAfter={
                    <div onClick={() => form.submit()} className="btn-normal-search">
                      <Search />
                    </div>
                  }
                />
              </CommonFormItem>
            </CommonForm>
          </div>
        ) : (
          <div className="advance-search-box">
            <div
              className="close-advance-search-btn cursor-pointer"
              onClick={() => {
                onClear({ advance: false });
              }}
            >
              <div>{t("locationCategoryPage.searchTitle")}</div>
              <CaretUpOutlined className="cursor-pointer" />
            </div>

            <CommonForm
              form={form}
              onFinish={onFinish}
              layout="vertical"
              initialValues={{
                name: searchQueryData?.name,
                address: searchQueryData?.address,
                plateNumber: searchQueryData?.plateNumber,
                user: searchQueryData?.user,
                dateIn:
                  searchQueryData?.dateInFromLong && searchQueryData?.dateInToLong
                    ? [
                        moment(searchQueryData?.dateInFromLong),
                        moment(searchQueryData?.dateInToLong),
                      ]
                    : [],
                dateOut:
                  searchQueryData?.dateOutFromLong && searchQueryData?.dateOutToLong
                    ? [
                        moment(searchQueryData?.dateOutFromLong),
                        moment(searchQueryData?.dateOutToLong),
                      ]
                    : [],
              }}
            >
              <Row gutter={30}>
                <Col span={12}>
                  <CommonFormItem
                    name="name"
                    label={t("locationCategoryPage.label.name")}
                    placeholder={t("locationCategoryPage.placeholder.name") as string}
                  />
                </Col>

                <Col span={12}>
                  <CommonFormItem
                    name="address"
                    label={t("locationCategoryPage.label.address")}
                    placeholder={t("locationCategoryPage.placeholder.address") as string}
                    options={LOCATION_BUILDINGS.map((x) => ({ ...x, label: t(x.label) }))}
                    type="select"
                  />
                </Col>
                <Col span={12}>
                  <CommonFormItem
                    name="username"
                    label={t("locationCategoryPage.label.username")}
                    placeholder={t("locationCategoryPage.placeholder.username") as string}
                  />
                </Col>
                <Col span={12}>
                  <CommonFormItem
                    name="dateIn"
                    type="rangePicker"
                    label={t("locationCategoryPage.label.dateIn")}
                    placeholder={[t("common.fromDate"), t("common.toDate")]}
                  />
                </Col>
                <Col span={12}>
                  <CommonFormItem
                    name="dateOut"
                    type="rangePicker"
                    label={t("locationCategoryPage.label.dateOut")}
                    placeholder={[t("common.fromDate"), t("common.toDate")]}
                  />
                </Col>
              </Row>
              <Space className="form-btn-container">
                <CommonButton
                  btnType="default"
                  size={"small"}
                  onClick={() => onClear({ advance: true })}
                >
                  {t("common.button.deleteCondition")}
                </CommonButton>
                <CommonButton btnType="primary" size={"small"} htmlType="submit">
                  {t("common.button.search")}
                </CommonButton>
              </Space>
            </CommonForm>
          </div>
        )}
      </div>

      <div className="avic-table-top">
        <div className="avic-table-top-title">{t("locationCategoryPage.historyTitle")}</div>
        <Space className="avic-table-top-right">
          {isCanExportHistory && (
            <CommonButton
              loading={isLoadingExport}
              btnType="success"
              onClick={handleExport}
              size={"small"}
            >
              {t("common.button.exportExcel")}
            </CommonButton>
          )}
        </Space>
      </div>

      <CommonTable
        isLoading={isLoading}
        rowKey={"id"}
        dataSource={listData?.content || []}
        columns={columns}
        data={listData}
        onChange={onPageChange}
        defaultSorter={{
          order: sortTypeQuery,
          field: sortByQuery,
        }}
      />
    </div>
  );
}

export default LocationHistory;
