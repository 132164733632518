import { Col, Form, Input, Row, Space, TreeDataNode, notification } from "antd";
import { useWatch } from "antd/es/form/Form";
import { Key, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonSpin from "../../components/Common/Spin";
import CommonTree from "../../components/Common/Tree";
import accountsServices from "../../services/accountManagement.service";
import permissionServices from "../../services/groupManagement.service";
import locationServices from "../../services/locationCategory.service";
import vendorServices from "../../services/vendorManagement.service";
import {
  COMMON_PAGE_STATUS,
  REGEX_EMAIL,
  REGEX_PHONE_NUMBER,
  ROLE_LIST,
  SEV_CODE,
} from "../../utils/constans";
import { LOCATION_VALUES } from "../../utils/constans/locationCategory";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import { ICommonSelectDataType } from "../../utils/types";
import { IAccountDetailData } from "../../utils/types/accountManagement";
import { IAuthorityDetailData, IGroupDetailData } from "../../utils/types/groupManagement";
import { ILocationDetailData } from "../../utils/types/locationCategory";
import { IVendorManagementDetailData } from "../../utils/types/vendorManagement";
import { isHavePermission, modifyArrTreePermission } from "../../utils/utilFunctions";
import ChangePasswordModal from "../Login/ChangePassword";
import { GROUP_TYPE_VALUES } from "../../utils/constans/groupManagement";

function AccountAdd() {
  const componentPath = SIDEBAR_ITEM_HREF.account_management;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [checkedLeftKeys, setCheckedLeftKeys] = useState<Key[]>([]);
  const [leftTreeData, setLeftTreeData] = useState<TreeDataNode[]>([]);
  const [rightTreeData, setRightTreeData] = useState<TreeDataNode[]>([]);
  const [checkedRightKeys, setCheckedRightKeys] = useState<Key[]>([]);
  const [permissionSecurityId, setPermissionSecurityId] = useState<number>(0);
  const [permissionSecurityGateInId, setPermissionSecurityGateInId] = useState<number>(0);
  const [permissionSecurityGateOutId, setPermissionSecurityGateOutId] = useState<number>(0);
  const [permissionSecurityVendorId, setPermissionSecurityVendorId] = useState<number>(0);
  const [selectDataPermission, setSelectDataPermission] = useState<IGroupDetailData[]>([]);
  const [positionSelectData, setPositionSelectData] = useState<ILocationDetailData[]>([]);
  const [vendorSelectData, setVendorSelectData] = useState<ICommonSelectDataType[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [currentAccount, setCurrentAccount] = useState<IAccountDetailData | undefined>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isSEV = profile?.source === SEV_CODE;
  const isCanCreate = isHavePermission(ROLE_LIST.MANAGER_ACCOUNT_CREATE, profile);
  const isCanEdit = isHavePermission(ROLE_LIST.MANAGER_ACCOUNT_EDIT, profile);
  const isCanResetPass = isHavePermission(ROLE_LIST.MANAGER_ACCOUNT_RESET_PASS, profile);

  const groupsId = useWatch("groups", form);
  const sourceValue = useWatch("source", form);

  const getDataTree = async () => {
    if (isSEV) {
      setIsLoading(true);
      const resp = await permissionServices.getFullAuthority();
      const data = resp?.data;
      if (resp?.status === 200) {
        const dataRight = data?.data?.filter(
          (item: IAuthorityDetailData) =>
            !["SECURITY", "SECURITY_GATEIN", "SECURITY_GATEOUT", "SECURITY_VENDOR"]?.includes(
              item?.authKey,
            ),
        );
        const permissionSecurity = data?.data?.find(
          (item: IAuthorityDetailData) => item?.authKey === "SECURITY",
        );
        const permissionSecurityGateIn = data?.data?.find(
          (item: IAuthorityDetailData) => item?.authKey === "SECURITY_GATEIN",
        );
        const permissionSecurityGateOut = data?.data?.find(
          (item: IAuthorityDetailData) => item?.authKey === "SECURITY_GATEOUT",
        );
        const permissionSecurityVendor = data?.data?.find(
          (item: IAuthorityDetailData) => item?.authKey === "SECURITY_VENDOR",
        );
        setPermissionSecurityId(permissionSecurity?.id || 0);
        setPermissionSecurityGateInId(permissionSecurityGateIn?.id || 0);
        setPermissionSecurityGateOutId(permissionSecurityGateOut?.id || 0);
        setPermissionSecurityVendorId(permissionSecurityVendor?.id || 0);
        const newRightTreeData = modifyArrTreePermission(dataRight);
        setExpandedKeys(newRightTreeData?.map((item) => item?.key));
        setLeftTreeData(newRightTreeData);
        setRightTreeData([
          {
            title: permissionSecurity?.description,
            key: permissionSecurity?.id as Key,
          },
          {
            title: permissionSecurityGateIn?.description,
            key: permissionSecurityGateIn?.id as Key,
          },
          {
            title: permissionSecurityGateOut?.description,
            key: permissionSecurityGateOut?.id as Key,
          },
          {
            title: permissionSecurityVendor?.description,
            key: permissionSecurityVendor?.id as Key,
          },
        ]);
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoading(false);
    }
  };

  const getDataSelect = async () => {
    const paramsSearch = {
      page: 0,
      size: 1000,
    };

    if (isSEV) {
      //select vị trí
      const respPosition = await locationServices.getPosition(paramsSearch);
      const dataPosition = respPosition?.data;
      if (respPosition?.status === 200) {
        setPositionSelectData(dataPosition?.data?.content);
      } else {
        setPositionSelectData([]);
      }

      //select công ty quản lý
      const respVendor = await vendorServices.getListVendorManagement(paramsSearch);
      const dataVendor = respVendor?.data;
      if (respVendor?.status === 200) {
        setVendorSelectData(
          dataVendor?.data?.content?.map((item: IVendorManagementDetailData) => ({
            value: item?.vendorCode,
            label: item?.companyName,
          })),
        );
      } else {
        setVendorSelectData([]);
      }
    }

    // select nhóm quyền
    const resp = await permissionServices.getPermission(paramsSearch);
    const data = resp?.data;
    if (resp?.status === 200) {
      setSelectDataPermission(data?.data?.content);
    } else {
      setSelectDataPermission([]);
    }
  };

  const getDetailAcount = async () => {
    setIsLoadingForm(true);
    if (params?.id) {
      const resp = await accountsServices.getAccountById(parseInt(params?.id));
      const data = resp?.data;
      if (resp?.status === 200) {
        setCurrentAccount(data?.data);
        form.setFieldsValue({
          name: data?.data?.name,
          username: data?.data?.username,
          password: data?.data?.password,
          source: data?.data?.source,
          email: data?.data?.email,
          phone: data?.data?.phone,
          groups: data?.data?.groups?.map((item: IGroupDetailData) => item?.id) || [],
          status: data?.data?.status,
        });
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
    }
    setIsLoadingForm(false);
  };

  useEffect(() => {
    getDataSelect();
    getDataTree();
    getDetailAcount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // xử lý tree quyền khi chọn nhóm quyền
  useEffect(() => {
    let newCheckedtKeys: number[] = [];
    let positions: number[] = [];
    let gateIns: number[] = [];
    let gateOuts: number[] = [];
    groupsId?.map((groupsIdItem: number) => {
      const curPermission = selectDataPermission?.find(
        (item: IGroupDetailData) => item.id === groupsIdItem,
      );
      curPermission?.authorities?.map((item: IAuthorityDetailData) => {
        newCheckedtKeys.push(item.id);
        switch (item.id) {
          // lấy id kho cho select
          case permissionSecurityId:
            positions.push(curPermission?.categoryPosition?.id);
            break;
          // lấy id cổng vào cho select
          case permissionSecurityGateInId:
            gateIns.push(curPermission?.categoryPosition?.id);
            break;
          // lấy id cổng ra cho select
          case permissionSecurityGateOutId:
            gateOuts.push(curPermission?.categoryPosition?.id);
            break;
          default:
            break;
        }
        return item;
      });
      return groupsIdItem;
    });

    form.setFieldValue("positions", Array.from(new Set(positions)));
    form.setFieldValue("gateIns", Array.from(new Set(gateIns)));
    form.setFieldValue("gateOuts", Array.from(new Set(gateOuts)));

    newCheckedtKeys = Array.from(new Set(newCheckedtKeys));
    setCheckedLeftKeys(
      newCheckedtKeys?.filter(
        (item) =>
          ![
            permissionSecurityId,
            permissionSecurityGateInId,
            permissionSecurityGateOutId,
            permissionSecurityVendorId,
          ].includes(item),
      ),
    );
    setCheckedRightKeys(
      newCheckedtKeys?.filter((item) =>
        [
          permissionSecurityId,
          permissionSecurityGateInId,
          permissionSecurityGateOutId,
          permissionSecurityVendorId,
        ].includes(item),
      ),
    );
  }, [
    form,
    groupsId,
    permissionSecurityGateInId,
    permissionSecurityGateOutId,
    permissionSecurityId,
    permissionSecurityVendorId,
    selectDataPermission,
  ]);

  const onFinish = (values: any) => {
    onSubmit(values);
  };

  const onSubmit = async (values: any) => {
    setIsSubmitLoading(true);

    const body = {
      name: values?.name,
      username: values?.username,
      password: values?.password,
      email: values?.email,
      source: values?.source,
      phone: values?.phone,
      groups: values?.groups?.map((item: any) => ({ id: item })) || [],
      status: values?.status,
    };

    const resp = currentAccount?.id
      ? await accountsServices.updateAccount(currentAccount?.id, body)
      : await accountsServices.createAccount(body);

    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: currentAccount?.id
          ? t("accountManagementPage.message.editSuccess")
          : t("accountManagementPage.message.createSuccess"),
      });
      setIsEditing(false);
      navigate(componentPath);
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsSubmitLoading(false);
  };

  return (
    <>
      <CommonForm
        isLoading={isLoadingForm}
        key="form"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          name: currentAccount?.name,
          username: currentAccount?.username,
          email: currentAccount?.email,
          phone: currentAccount?.phone,
          source: currentAccount?.source,
          groups: currentAccount?.groups?.map((item: IAccountDetailData) => item.id),
          status: currentAccount?.status,
        }}
      >
        <div className="account-wrapper">
          <div className="account-title">
            {currentAccount?.id
              ? t("accountManagementPage.editTitle")
              : t("accountManagementPage.addTitle")}
          </div>
          {isSEV ? (
            <div className="account-info">
              <div className="avic-search-box account-left-info">
                <div className="advance-search-box">
                  <div className="detail-page-box">
                    <div className="box-title">{t("accountManagementPage.infoBoxTitle")}</div>
                    <Row gutter={20}>
                      <Col span={24}>
                        <CommonFormItem
                          name="username"
                          label={t("accountManagementPage.label.username")}
                          placeholder={t("accountManagementPage.placeholder.username") as string}
                          showRequiredIcon={!currentAccount?.id}
                          disabled={!!currentAccount?.id}
                          rules={[
                            {
                              whitespace: true,
                              required: true,
                              message: `${t("validate.input")} ${t(
                                "accountManagementPage.label.username",
                              )}.`,
                            },
                          ]}
                        />
                      </Col>
                      {!currentAccount?.id ? (
                        <Col span={24}>
                          <CommonFormItem
                            name="password"
                            label={t("accountManagementPage.label.password")}
                            showRequiredIcon
                            rules={[
                              {
                                whitespace: true,
                                required: true,
                                message: `${t("validate.input")} ${t(
                                  "accountManagementPage.label.password",
                                )}.`,
                              },
                            ]}
                          >
                            <Input.Password
                              placeholder={
                                t("accountManagementPage.placeholder.password") as string
                              }
                              allowClear
                            />
                          </CommonFormItem>
                        </Col>
                      ) : (
                        <></>
                      )}

                      <Col span={24}>
                        <CommonFormItem
                          name="name"
                          label={t("accountManagementPage.label.name")}
                          placeholder={t("accountManagementPage.placeholder.name") as string}
                          maxLength={128}
                          showRequiredIcon={!(!!currentAccount?.id && (!isCanEdit || !isEditing))}
                          disabled={!!currentAccount?.id && (!isCanEdit || !isEditing)}
                          rules={[
                            {
                              whitespace: true,
                              required: true,
                              message: `${t("validate.input")} ${t(
                                "accountManagementPage.label.name",
                              )}.`,
                            },
                          ]}
                        />
                      </Col>
                      <Col span={24}>
                        <CommonFormItem
                          name="email"
                          label={t("accountManagementPage.label.email")}
                          placeholder={t("accountManagementPage.placeholder.email") as string}
                          showRequiredIcon={!(!!currentAccount?.id && (!isCanEdit || !isEditing))}
                          disabled={!!currentAccount?.id && (!isCanEdit || !isEditing)}
                          rules={[
                            {
                              whitespace: true,
                              required: true,
                              message: `${t("validate.input")} ${t(
                                "accountManagementPage.label.email",
                              )}.`,
                            },
                            {
                              pattern: REGEX_EMAIL,
                              message: t("validate.emailFormat") as string,
                            },
                          ]}
                        />
                      </Col>
                      <Col span={24}>
                        <CommonFormItem
                          name="phone"
                          label={t("accountManagementPage.label.phoneNumber")}
                          placeholder={t("accountManagementPage.placeholder.phoneNumber") as string}
                          showRequiredIcon={!(!!currentAccount?.id && (!isCanEdit || !isEditing))}
                          disabled={!!currentAccount?.id && (!isCanEdit || !isEditing)}
                          rules={[
                            {
                              whitespace: true,
                              required: true,
                              message: `${t("validate.input")} ${t(
                                "accountManagementPage.label.phoneNumber",
                              )}.`,
                            },
                            {
                              pattern: REGEX_PHONE_NUMBER,
                              message: t("validate.phoneNumberFormat"),
                            },
                          ]}
                        />
                      </Col>
                      <Col span={24}>
                        <CommonFormItem
                          name="source"
                          label={t("accountManagementPage.label.source")}
                          placeholder={t("accountManagementPage.placeholder.source") as string}
                          options={vendorSelectData}
                          type="select"
                          onChange={() => {
                            form.setFieldValue("groups", []);
                          }}
                          showRequiredIcon={!(!!currentAccount?.id && (!isCanEdit || !isEditing))}
                          disabled={!!currentAccount?.id && (!isCanEdit || !isEditing)}
                          rules={[
                            {
                              required: true,
                              message: `${t("validate.select")} ${t(
                                "accountManagementPage.label.source",
                              )}.`,
                            },
                          ]}
                        />
                      </Col>
                      <Col span={24}>
                        <CommonFormItem
                          name="status"
                          label={t("accountManagementPage.label.status")}
                          placeholder={t("accountManagementPage.placeholder.status") as string}
                          options={COMMON_PAGE_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
                          type="select"
                          showRequiredIcon={!(!!currentAccount?.id && (!isCanEdit || !isEditing))}
                          disabled={!!currentAccount?.id && (!isCanEdit || !isEditing)}
                          rules={[
                            {
                              required: true,
                              message: `${t("validate.select")} ${t(
                                "accountManagementPage.label.status",
                              )}.`,
                            },
                          ]}
                        />
                      </Col>
                    </Row>
                    {currentAccount?.id && isCanResetPass ? (
                      <CommonButton
                        onClick={() => setVisibleModal(true)}
                        btnType="default"
                        size={"small"}
                        className="mgt-20"
                      >
                        {t("common.button.resetPass")}
                      </CommonButton>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="avic-search-box account-right-info">
                <div className="advance-search-box">
                  <div className="detail-page-box">
                    <div className="box-title">{t("accountManagementPage.permissionBoxTitle")}</div>
                    <Row gutter={30}>
                      <Col span={24}>
                        <CommonFormItem
                          name="groups"
                          label={t("accountManagementPage.label.groups")}
                          placeholder={t("accountManagementPage.placeholder.groups") as string}
                          rules={[
                            {
                              required: true,
                              message: `${t("validate.select")} ${t(
                                "accountManagementPage.label.groups",
                              )}.`,
                            },
                          ]}
                          showRequiredIcon={!(!!currentAccount?.id && (!isCanEdit || !isEditing))}
                          disabled={!!currentAccount?.id && (!isCanEdit || !isEditing)}
                          mode="multiple"
                          type="select"
                          options={selectDataPermission
                            ?.filter(
                              (x) =>
                                (!groupsId?.[0] ||
                                  x.type ===
                                    selectDataPermission?.find((y) => y.id === groupsId?.[0])
                                      ?.type) &&
                                (!sourceValue || sourceValue === SEV_CODE
                                  ? [GROUP_TYPE_VALUES.WEB_SEV, GROUP_TYPE_VALUES.APP_SEV].includes(
                                      x.type,
                                    )
                                  : [
                                      GROUP_TYPE_VALUES.WEB_VENDOR,
                                      GROUP_TYPE_VALUES.APP_VENDOR,
                                    ].includes(x.type)),
                            )
                            ?.map((item) => ({
                              value: item.id,
                              label: item.groupName,
                            }))}
                        />
                      </Col>

                      <Col span={12}>
                        <div className="account-sub-title">
                          {t("groupManagementPage.permissionWebTitle")}
                        </div>
                        <CommonSpin isLoading={isLoading && !currentAccount?.id}>
                          <CommonTree
                            key="leftTree"
                            checkable
                            showLine={true}
                            checkedKeys={checkedLeftKeys}
                            treeData={leftTreeData}
                            expandedKeys={expandedKeys}
                            disabled
                          />
                        </CommonSpin>
                      </Col>
                      <Col span={12} className="pdl-20">
                        <div className="account-sub-title">
                          {t("groupManagementPage.permissionAppTitle")}
                        </div>
                        <CommonSpin isLoading={isLoading && !currentAccount?.id}>
                          <CommonTree
                            key="rigthTree"
                            checkable
                            showLine={true}
                            checkedKeys={checkedRightKeys}
                            disabled
                            treeData={rightTreeData}
                          />
                        </CommonSpin>
                        <Row className="mgt-10">
                          {checkedRightKeys?.includes(permissionSecurityId) ? (
                            <Col span={24}>
                              <CommonFormItem
                                name="positions"
                                label={t("groupManagementPage.label.categoryPosition")}
                                placeholder={
                                  t("groupManagementPage.placeholder.categoryPosition") as string
                                }
                                options={positionSelectData
                                  ?.filter(
                                    (item: ILocationDetailData) =>
                                      item.type === LOCATION_VALUES.WAREHOUSE,
                                  )
                                  ?.map((item: ILocationDetailData) => ({
                                    value: item.id,
                                    label: item.name + " - " + item.address,
                                  }))}
                                type="select"
                                mode="multiple"
                                disabled
                              />
                            </Col>
                          ) : (
                            <></>
                          )}
                          {checkedRightKeys?.includes(permissionSecurityGateInId) ? (
                            <Col span={24}>
                              <CommonFormItem
                                name="gateIns"
                                label={t("groupManagementPage.label.categoryPositionGateIn")}
                                placeholder={
                                  t(
                                    "groupManagementPage.placeholder.categoryPositionGateIn",
                                  ) as string
                                }
                                options={positionSelectData
                                  ?.filter(
                                    (item: ILocationDetailData) =>
                                      item.type === LOCATION_VALUES.GATE_IN,
                                  )
                                  ?.map((item: ILocationDetailData) => ({
                                    value: item.id,
                                    label: item.name + " - " + item.address,
                                  }))}
                                type="select"
                                mode="multiple"
                                disabled
                              />
                            </Col>
                          ) : (
                            <></>
                          )}
                          {checkedRightKeys?.includes(permissionSecurityGateOutId) ? (
                            <Col span={24}>
                              <CommonFormItem
                                name="gateOuts"
                                label={t("groupManagementPage.label.categoryPositionGateOut")}
                                placeholder={
                                  t(
                                    "groupManagementPage.placeholder.categoryPositionGateOut",
                                  ) as string
                                }
                                options={positionSelectData
                                  ?.filter(
                                    (item: ILocationDetailData) =>
                                      item.type === LOCATION_VALUES.GATE_OUT,
                                  )
                                  ?.map((item: ILocationDetailData) => ({
                                    value: item.id,
                                    label: item.name + " - " + item.address,
                                  }))}
                                type="select"
                                mode="multiple"
                                disabled
                              />
                            </Col>
                          ) : (
                            <></>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="avic-search-box" style={{ padding: "0 0.833vw" }}>
              <div className="advance-search-box">
                <div className="detail-page-box">
                  <Row gutter={20}>
                    <Col span={12}>
                      <CommonFormItem
                        name="username"
                        label={t("accountManagementPage.label.username")}
                        placeholder={t("accountManagementPage.placeholder.username") as string}
                        disabled
                      />
                    </Col>
                    <Col span={12}>
                      <CommonFormItem
                        name="name"
                        label={t("accountManagementPage.label.name")}
                        placeholder={t("accountManagementPage.placeholder.name") as string}
                        disabled
                      />
                    </Col>
                    <Col span={12}>
                      <CommonFormItem
                        name="email"
                        label={t("accountManagementPage.label.email")}
                        placeholder={t("accountManagementPage.placeholder.email") as string}
                        disabled
                      />
                    </Col>
                    <Col span={12}>
                      <CommonFormItem
                        name="phone"
                        label={t("accountManagementPage.label.phoneNumber")}
                        placeholder={t("accountManagementPage.placeholder.phoneNumber") as string}
                        disabled
                      />
                    </Col>
                    <Col span={12}>
                      <CommonFormItem
                        name="groups"
                        label={t("accountManagementPage.label.groups")}
                        placeholder={t("accountManagementPage.placeholder.groups") as string}
                        disabled
                        mode="multiple"
                        type="select"
                        options={selectDataPermission?.map((item: IGroupDetailData) => ({
                          value: item.id,
                          label: item.groupName,
                        }))}
                      />
                    </Col>
                    <Col span={12}>
                      <CommonFormItem
                        name="status"
                        label={t("accountManagementPage.label.status")}
                        placeholder={t("accountManagementPage.placeholder.status") as string}
                        disabled
                        options={COMMON_PAGE_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
                        type="select"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          )}
          <Space className="form-btn-container account-btn-container" align="center">
            {isEditing && currentAccount?.id ? (
              <CommonButton
                htmlType="button"
                onClick={() => setIsEditing(false)}
                size={"large"}
                btnType="default"
              >
                {t("common.button.cancel")}
              </CommonButton>
            ) : (
              <Link to={componentPath}>
                <CommonButton htmlType="button" btnType="default" size={"large"}>
                  {t("common.button.exit")}
                </CommonButton>
              </Link>
            )}
            {isSEV ? (
              <>
                {!!currentAccount?.id && isCanEdit ? (
                  isEditing ? (
                    <CommonButton
                      btnType="primary"
                      size={"large"}
                      loading={isSubmitLoading}
                      onClick={() => form.submit()}
                    >
                      {t("common.button.update")}
                    </CommonButton>
                  ) : (
                    <CommonButton
                      btnType="primary"
                      size={"large"}
                      onClick={() => setIsEditing(true)}
                    >
                      {t("common.button.edit")}
                    </CommonButton>
                  )
                ) : (
                  <></>
                )}
                {!currentAccount?.id && isCanCreate ? (
                  <CommonButton
                    btnType="primary"
                    htmlType="submit"
                    size={"large"}
                    loading={isSubmitLoading}
                  >
                    {t("common.button.addNew")}
                  </CommonButton>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Space>
        </div>
      </CommonForm>

      {visibleModal ? (
        <ChangePasswordModal
          userId={currentAccount?.id}
          handleOk={() => setVisibleModal(false)}
          openModal={visibleModal}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default AccountAdd;
